// Function to create a Mux asset and get the upload URL
import { logerror, loginfo } from './contextualLogger';
import { postJson } from './api';
import axios from 'axios';

async function createMuxAsset(passthrough) {
  // Make an API request to your server, which then talks to Mux
  const data = await postJson('/api/mux/create-mux-asset', passthrough);
  return data.uploadUrl; // Assuming the server responds with the upload URL
}

export async function uploadVideoToMux(blob, passthrough, config = {}) {
  const uploadUrl = await createMuxAsset(passthrough);

  // Use the Fetch API to upload the blob to Mux
  const response = await axios.put(uploadUrl, blob, {
    headers: {
      'Content-Type': 'video/webm',
    },
    ...config,
  });

  let passthroughStringified = '';

  try {
    passthroughStringified = JSON.stringify(passthrough);
  } catch (error) {
    passthroughStringified = 'Passthrough data invalid';
  }
  if (response.status === 200) {
    console.log('Video uploaded successfully.');
    loginfo({
      message: `Local recording uploaded successfully ${passthroughStringified}`,
    });
  } else {
    console.error('Failed to upload video to Mux:', response.statusText);
    logerror({
      message: `Direct Local recording to Mux failed ${passthroughStringified} ${response.statusText}`,
    });
  }
}

export async function startLocalRecording() {
  const { readable, writable } = new TransformStream({
    transform: (chunk, ctrl) =>
      chunk.arrayBuffer().then((b) => ctrl.enqueue(new Uint8Array(b))),
  });

  window.writer = writable.getWriter();

  let chunks = []; // Store chunks here

  await readable
    .pipeThrough(
      new TransformStream({
        transform(chunk, controller) {
          chunks.push(chunk);
          controller.enqueue(chunk);
        },
      })
    )
    .pipeTo(
      new WritableStream({
        async close() {
          loginfo({ message: 'Stream finished. Uploading to MUX' });
          const blob = new Blob(chunks, { type: 'video/webm' }); // Create a blob from chunks
          try {
            await uploadVideoToMux(blob);
            loginfo({ message: 'Uploading to MUX completed' });
          } catch (error) {
            logerror({ message: 'Error when uploading to MUX' });
          }
        },
      })
    );
}

export async function onLocalRecordingData(info) {
  try {
    await window.writer.write(info.data);

    if (info.finished) {
      // TODO pass userId information
      loginfo({ message: 'Closing stream recording of userId' });
      window.writer.close();
    }
  } catch (e) {
    console.error(e);
  }
}
