import { useEffect, useState } from 'react';

/**
 * Hook for an ever-increasing index (starting at 0) which increments by one every `intervalMs` as long as
 * `active` is set. If active is false and then changed to true, the interval is continued
 * after `intervalMs`.
 *
 * Can be used with modulus to rotate through an array.
 */
export const useIntervalIndex = (intervalMs, active, startIndex = 0) => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    let timeoutId;

    if (active) {
      timeoutId = setTimeout(() => {
        setIndex(index + 1);
      }, intervalMs);
    } else {
      setIndex(startIndex);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [intervalMs, active, setIndex, index, startIndex]);

  return index;
};
