import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, ModalTitle, ModalWindow } from '../Modal';
import { Switch } from '../common/Switch';
import { useChangeAuthoringMode } from './settings/useChangeAuthoringMode';
import { RichTextEditor, RichTextToolbar } from '../richText';
import { positions } from '../richText/toolbar/ToolbarAutoPosition';
import { useMeetingInformation } from '../../hooks/useMeetingInformation';
import {
  fromRawContentState,
  getCustomStylesFromState,
  toRawContentState,
} from '../richText/helpers';
import { EditorState } from 'draft-js';
import { debounce } from 'lodash';
import { useChangeRsvpSettings } from './settings/useChangeRSVPSettings';
import { SeriesVisibility } from '../../helper/visibility';
import { rsvpFormFields } from '../../helper/constants';
import { useChangeAutoRecordMeeting } from './settings/useChangeAutoRecordMeeting';
import { strategyDecorator } from '../richText/editor/helper';
import { useChangeJoinWithoutMedia } from './settings/useChangeJoinWithoutMedia';
import { useChangeGuestsMutedOnStart } from './settings/useChangeGuestsMutedOnStart';
import { useChangeModeratorCanScreenshare } from './settings/useChangeModeratorCanScreenshare';
import { useChangeEmailMandatory } from './settings/useChangeEmailMandatory';
import { PosterUploader } from './settings/PosterUploader';
import { EventPresenters, presenterTypes } from './settings/EventPresenters';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';
import { generateTextApi } from '../../helper/api';
import { getTextFromRichText } from 'zync-common/helper/richText';
import { LoadingSpinner } from '../LoadingSpinner';
import { useChangeIncludeEventQuestions } from './settings/useChangeIncludeEventQuestions';
import { EventQuestions } from './settings/EventQuestions';
import { usePosterSlide } from './settings/usePosterSlide';
import { ScenePreview } from '../authoring/Menu/ScenePreview';
import { Button } from '../common/Button';
import { useChangeTimelineOn } from './settings/useChangeTimelineOn';
import { useChangeDoNotUseAsUpcomingEpisode } from './settings/useChangeDoNotUseAsUpcomingEpisode';

export const Section = ({ children, title }) => {
  return (
    <section className="border border-[#EBEAED] relative">
      <h2 className="bg-purple/5 px-4 py-2 text-base font-semibold">{title}</h2>
      <div className="px-8 py-4 flex flex-col gap-2">{children}</div>
    </section>
  );
};

const switchOptions = ['Yes', 'No'];

export const SettingsSwitch = ({
  defaultChecked,
  label,
  onChange,
  disabled,
}) => {
  return (
    <div
      className={`flex gap-5 items-center ${disabled && 'pointer-events-none'}`}
    >
      <span className="block font-medium text-sm">{label}</span>
      <Switch
        booleanText={switchOptions}
        defaultChecked={defaultChecked}
        onChangeValue={onChange}
        disabled={disabled}
      />
    </div>
  );
};

const PROMPT_VARIATIONS = [
  '',
  'Include bullet points in the description.',
  'Include 3 specific topics that will be discussed',
];

const buildGenerateMeetingDescriptionPrompt = (
  title,
  speaker,
  host,
  promptVariationNum
) => {
  let prompt = `Give me a 150 word description of a Webinar event with the title: ${title}.`;
  if (host) {
    prompt += `The host will be ${host.fullName} who is a ${host.jobTitle}.`;
  }
  if (speaker) {
    prompt += `The speaker will be ${speaker.fullName} who is a ${speaker.jobTitle}.`;
  }
  prompt += PROMPT_VARIATIONS[promptVariationNum];
  return prompt;
};

const SettingsDescription = ({ series, localDispatch }) => {
  const isInitialized = useRef(false);
  const magicHatIconUrl = getStaticAssetUrl('magic-hat.png');
  const [promptVariationNum, setPromptVariationNum] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const textEditorInitialValues = {
    color: '#000000',
    fontSize: '24px',
    fontFamily: 'Poppins',
  };

  const {
    description,
    meetingSeriesId,
    title: meetingTitle,
    settings: { eventPresenters },
  } = series;

  const [editorState, setEditorState] = useState(() => {
    const state = fromRawContentState(description);

    return EditorState.moveFocusToEnd(EditorState.createWithContent(state));
  });

  const [lastEditValue, setLastEditValue] = useState(() =>
    toRawContentState(editorState.getCurrentContent())
  );

  const { updateMeetingDescription } = useMeetingInformation({
    description,
    series,
    localDispatch,
    meetingSeriesId,
    meetingTitle,
  });

  useEffect(() => {
    if (isInitialized.current) {
      return;
    }

    isInitialized.current = true;

    const newEditorState = EditorState.createWithContent(
      fromRawContentState(description),
      strategyDecorator
    );

    getCustomStylesFromState(newEditorState, 'color');

    setEditorState(newEditorState);
  }, [description]);

  const debouncedHandleChange = debounce(
    (editorState) => {
      const content = editorState.getCurrentContent();
      const text = toRawContentState(content);
      if (lastEditValue !== text) {
        setLastEditValue(text);
        updateMeetingDescription(text);
      }
    },
    600,
    { maxWait: 3000 }
  );

  const handleChange = useCallback(
    (editorState) => {
      setEditorState(editorState);
      debouncedHandleChange(editorState);
    },
    [debouncedHandleChange]
  );

  const handleGenerateMeetingDescription = async () => {
    setIsLoading(true);
    const speaker = eventPresenters.find(
      (eventPresenter) => eventPresenter.type === presenterTypes.SPEAKER
    );
    const host = eventPresenters.find(
      (eventPresenter) => eventPresenter.type === presenterTypes.HOST
    );
    const meetingTitleText = getTextFromRichText(meetingTitle);

    const prompt = buildGenerateMeetingDescriptionPrompt(
      meetingTitleText,
      speaker,
      host,
      promptVariationNum
    );
    const { error, text } = await generateTextApi(prompt);
    if (error) {
      setIsLoading(false);
      return;
    }
    setPromptVariationNum((prevState) =>
      prevState + 1 === PROMPT_VARIATIONS.length ? 0 : prevState + 1
    );
    const state = fromRawContentState(text);
    handleChange(EditorState.createWithContent(state));
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2">
        <div className="font-medium text-sm">Description</div>
        <button
          className="flex gap-1"
          onClick={handleGenerateMeetingDescription}
          disabled={isLoading}
        >
          {isLoading ? (
            <LoadingSpinner width="20px" />
          ) : (
            <img
              src={magicHatIconUrl}
              alt="magic hat"
              className="object-contain h-5"
            />
          )}
          <div className="font-medium text-sm underline">
            AI Generate Description
          </div>
        </button>
      </div>
      <div className="flex flex-col border border-solid border-gray rounded">
        <div className="border-b border-solid border-[#EBEAED] flex justify-start">
          <RichTextToolbar
            editorState={editorState}
            onChange={handleChange}
            initialValues={textEditorInitialValues}
            bestPosition={positions.TOP}
            enableHyperlinks
          />
        </div>

        <div className="h-[18vh] overflow-auto">
          <style>
            {`
              .public-DraftStyleDefault-ul {
                margin: 0; 
              } 
            `}
          </style>
          <RichTextEditor editorState={editorState} onChange={handleChange} />
        </div>
      </div>
    </div>
  );
};

export const MeetingSettingsModal = ({
  handleClose,
  series,
  localDispatch,
  areEventPosterSettingsEnabled = false,
  areEventPageSettingsEnabled = false,
  areEventTopicsSettingsEnabled = false,
  areRSVPSettingsEnabled = false,
  areOtherSettingsEnabled = false,
  areUpcomingEpisodeSettingsEnabled = false,
}) => {
  const { authoringModeEnabled, handleChangeAuthoringMode } =
    useChangeAuthoringMode(series, localDispatch);

  const { enabledFieldsRsvp, handleChangeRsvpField, isUpdating } =
    useChangeRsvpSettings(series, localDispatch);

  const { isAutoRecordMeetingEnabled, handleChangeAutoRecord } =
    useChangeAutoRecordMeeting(series, localDispatch);

  const { isJoinWithoutMediaEnabled, handleChangeJoinWithoutMedia } =
    useChangeJoinWithoutMedia(series, localDispatch);
  const { areGuestsMutedOnStart, handleChangeGuestsMutedOnStart } =
    useChangeGuestsMutedOnStart(series, localDispatch);

  const {
    moderatorsCanScreenShareOnly,
    handleChangeModeratorCanScreenshareOnly,
  } = useChangeModeratorCanScreenshare(series, localDispatch);

  const { isEmailMandatory, handleChangeIsEmailMandatory } =
    useChangeEmailMandatory(series, localDispatch);

  const { areEventQuestionsIncluded, handleChangeIncludeEventQuestions } =
    useChangeIncludeEventQuestions(series, localDispatch);

  const { doNotUseAsUpcomingEpisode, handleChangeDoNotUseAsUpcomingEpisode } =
    useChangeDoNotUseAsUpcomingEpisode(series, localDispatch);

  const { posterSlide, handleUploadPoster, isLoading } = usePosterSlide(
    series,
    localDispatch
  );

  const { isTimelineOn, handleChangeTimelineOn } = useChangeTimelineOn(
    series,
    localDispatch
  );

  const posterUrl = series.settings.eventPosterImage;

  return (
    <ModalWindow
      size={Modal.size.xl}
      onCancel={handleClose}
      onOutsideClick={handleClose}
      overflow="overflow-visible"
    >
      <ModalTitle>Settings</ModalTitle>
      <div className="px-6 my-6 md:px-8 md:my-8 h-[60vh] overflow-auto">
        <div className="flex flex-col gap-8">
          {areEventPosterSettingsEnabled && posterSlide && (
            <Section title="Event Poster">
              {posterUrl && <img src={posterUrl} alt="" key={posterUrl} />}
              <div className="fixed left-[-9999px] top-[-9999px]">
                <ScenePreview
                  scene={posterSlide}
                  brandKit={series?.workspace?.brandKit}
                  htmlId="poster-id"
                  height={900}
                  width={1600}
                />
              </div>
              <div className="my-4" />
              <div className="flex gap-4">
                {posterUrl ? (
                  <a
                    download="scene_poster.png"
                    href={posterUrl}
                    className="text-sm text-white px-3 py-2 bg-purple border border-white rounded-md"
                  >
                    Download Poster
                  </a>
                ) : null}
                <Button
                  color={Button.colors.WHITE}
                  onClick={handleUploadPoster}
                  state={isLoading && Button.states.LOADING}
                  padding={Button.padding.NONE}
                  background={Button.background.NONE}
                >
                  <span className="text-sm text-purple px-3 py-2 bg-white rounded-md">
                    {posterUrl ? 'Regenerate' : 'Generate'} Poster
                  </span>
                </Button>
              </div>
            </Section>
          )}
          {areEventPageSettingsEnabled && (
            <Section title="Event Page Settings">
              <div className="flex flex-col gap-8">
                <SettingsDescription
                  series={series}
                  localDispatch={localDispatch}
                />
                <PosterUploader series={series} localDispatch={localDispatch} />
                <EventPresenters
                  series={series}
                  localDispatch={localDispatch}
                />
              </div>
            </Section>
          )}
          {areEventTopicsSettingsEnabled && (
            <Section title="Topics / Questions">
              <div className="flex flex-col gap-6">
                <SettingsSwitch
                  defaultChecked={areEventQuestionsIncluded}
                  label="Include Q&A section"
                  onChange={handleChangeIncludeEventQuestions}
                />
                {areEventQuestionsIncluded && (
                  <EventQuestions
                    series={series}
                    localDispatch={localDispatch}
                  />
                )}
              </div>
            </Section>
          )}
          {areRSVPSettingsEnabled &&
            series.visibility === SeriesVisibility.RSVP && (
              <Section title="RSVP Settings">
                <h2 className="py-2 mb-4 text-base font-semibold">
                  Fields for RSVP
                </h2>
                <div className="flex flex-wrap gap-x-12 gap-y-2">
                  <SettingsSwitch
                    defaultChecked={enabledFieldsRsvp.includes(
                      rsvpFormFields.JOB_TITLE
                    )}
                    label="Job Title"
                    onChange={handleChangeRsvpField(rsvpFormFields.JOB_TITLE)}
                    disabled={isUpdating}
                  />
                  <SettingsSwitch
                    defaultChecked={enabledFieldsRsvp.includes(
                      rsvpFormFields.ORGANIZATION
                    )}
                    label="Company Name"
                    onChange={handleChangeRsvpField(
                      rsvpFormFields.ORGANIZATION
                    )}
                    disabled={isUpdating}
                  />
                  <SettingsSwitch
                    defaultChecked={enabledFieldsRsvp.includes(
                      rsvpFormFields.PHONE
                    )}
                    label="Phone"
                    onChange={handleChangeRsvpField(rsvpFormFields.PHONE)}
                    disabled={isUpdating}
                  />
                </div>
              </Section>
            )}
          {areOtherSettingsEnabled && (
            <Section title="Other Settings">
              <div className="space-y-6">
                <SettingsSwitch
                  defaultChecked={isAutoRecordMeetingEnabled}
                  label="Automatically record this meeting"
                  onChange={handleChangeAutoRecord}
                />
                <SettingsSwitch
                  defaultChecked={authoringModeEnabled}
                  label="Allow Advanced Design"
                  onChange={handleChangeAuthoringMode}
                />
                <SettingsSwitch
                  defaultChecked={isJoinWithoutMediaEnabled}
                  label="Allow users to join without any media"
                  onChange={handleChangeJoinWithoutMedia}
                />
                {/*  <SettingsSwitch label="Show Zync branding in event page" />*/}
                <SettingsSwitch
                  defaultChecked={areGuestsMutedOnStart}
                  label="Mute guests on event start"
                  onChange={handleChangeGuestsMutedOnStart}
                />
                <SettingsSwitch
                  defaultChecked={moderatorsCanScreenShareOnly}
                  label="Only moderators can screenshare"
                  onChange={handleChangeModeratorCanScreenshareOnly}
                />
                <SettingsSwitch
                  defaultChecked={isEmailMandatory}
                  label="Include Email Field when joining meeting (the field will be mandatory)"
                  onChange={handleChangeIsEmailMandatory}
                />
                <SettingsSwitch
                  defaultChecked={isTimelineOn}
                  label="Timeline on"
                  onChange={handleChangeTimelineOn}
                />
              </div>
            </Section>
          )}
          {areUpcomingEpisodeSettingsEnabled && (
            <Section title="Upcoming Episode Settings">
              <SettingsSwitch
                label="Do not show this episode as Upcoming Episode in the Show Page"
                defaultChecked={doNotUseAsUpcomingEpisode}
                onChange={handleChangeDoNotUseAsUpcomingEpisode}
              />
            </Section>
          )}
        </div>
      </div>
    </ModalWindow>
  );
};
