import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/common/Button';
import { ConfirmationModal } from '../../components/common/Modal';
import { WarningCircleIcon } from '../../components/icons/WarningCircleIcon';
import { TextInput } from '../../components/Input';
import {
  publishWorkspaceApi,
  removeWorkspaceMemberApi,
  uploadCustomShowpagePicture,
  uploadIntroOutroVideo,
  uploadPosterImage,
} from '../../helper/api';
import { WarningTitle } from './Common';
import { useHistory } from 'react-router-dom';
import { refreshUser } from '../../auth';
import {
  fileNameWithoutWorkspaceIdAndDate,
  WorkspaceBrandKitForm,
} from '../../components/WorkspaceBrandKitForm';
import { ToastOutlet } from '../../components/ToastOutlet';
import { notifyUser } from '../../components/authoring/hooks';
import { fromError, logerror } from '../../helper/contextualLogger';
import { PreviewSlide } from '../../components/PreviewSlide';
//import classNames from '../../helper/classNames';
//import { CheckBox } from '../../components/common/CheckBox';
//import { HostImageUploader } from '../../components/HostImageUploader';
import { ShowBackdrops } from '../../components/ShowBackdrops';
import { TextArea } from '../../components/common/TextArea';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from '../../components/icons/CopyIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownSelect } from '../../components/common/Dropdown';
import classNames from '../../helper/classNames';
import {
  //CAPTIONS_CONFIG_SETTINGS,
  HOST_IMAGE_SETTINGS,
  INTRO_SETTINGS,
  LAYOUT_CONFIG_SETTINGS,
  MUSIC_CONFIG_SETTINGS,
  OUTRO_SETTINGS,
  PROCESSOR_BASE_URL,
  THUMBNAIL_CONFIG_SETTINGS,
} from '../../helper/constants';
import { AttachmentIcon } from '../../components/icons/AttachmentIcon';
import { VideoUploader } from '../../components/VideoUploader';
import { ImageUploader } from '../../components/ImageUploader';
import { useShowPage } from '../ShowPage/useShowPage';
import axios from 'axios';
import { delay } from '../../helper/exponentialFetchRetry';
import { showPosterSettings } from 'zync-common/types';
import { PLANS } from 'zync-common/zyncCustomerPlans';

const maxCharacters = (string, chars) => {
  let newSplit = [];

  if (!string) {
    return;
  }

  const wasLonger = string.length > chars;

  string
    .split('')
    .reverse()
    .slice(0, chars)
    .forEach((char) => {
      newSplit.unshift(char);
    });

  if (wasLonger) {
    newSplit.unshift('...');
  }

  return newSplit.join('');
};
export const getHostedByText = (hostedByText, hostFullName) => {
  return hostedByText
    ? `Hosted by ${hostedByText}`
    : `Hosted by ${hostFullName}`;
};

const VideoFileName = ({ videoUrl, onDelete }) => {
  return (
    <div className="flex gap-2 items-center text-blue-gray h-6 w-full">
      {videoUrl ? (
        <>
          <AttachmentIcon className="stroke-blue-gray" />
          <span className="text-xs">
            {maxCharacters(fileNameWithoutWorkspaceIdAndDate(videoUrl), 18)}
          </span>
          <button
            onClick={onDelete}
            className="text-red border border-red rounded-full h-4 w-4 flex items-center justify-center"
          >
            &times;
          </button>
        </>
      ) : null}
    </div>
  );
};
export const getHostedBy = (hostedBy, hosts, hostedByText, creatorUserName) => {
  if (hostedByText) return hostedByText;

  if (hosts && hosts.length > 0) {
    return hosts[0].fullName;
  }

  if (hostedBy) return hostedBy;

  return creatorUserName;
};

const VideoUploadContainer = ({
  videoUrl,
  onVideoDelete,
  onVideoSubmit,
  label,
  enforcedAspectRatio,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <VideoFileName videoUrl={videoUrl} onDelete={onVideoDelete} />
      <VideoUploader
        accept="video/mp4,video/x-m4v,video/*"
        disabled={false}
        label={
          <span className="flex items-center gap-2 text-xs whitespace-nowrap py-1">
            <AttachmentIcon className="stroke-purple" /> {label}
          </span>
        }
        onImageSubmit={async (buffer, type, fileNameWithTimestamp) => {
          setIsLoading(true);
          await onVideoSubmit(buffer, type, fileNameWithTimestamp);
          setIsLoading(false);
        }}
        buttonColor={Button.colors.PURPLE_LITE}
        buttonPadding={Button.padding.SMALL}
        buttonSize={Button.sizes.FULL}
        loading={isLoading}
        buttonState={isLoading ? Button.states.LOADING : Button.states.DEFAULT}
        enforcedAspectRatio={enforcedAspectRatio}
        onImageDimensionsExceeded={() => {
          notifyUser(
            'Please use valid aspect ratio of a video. 16:9 for landscape and 9:16 for portrait'
          );
        }}
      />
    </>
  );
};

const getConfigTemplateId = (configValue, fallbackValue) => {
  if (!configValue || configValue === 'default') {
    return fallbackValue;
  }

  return configValue;
};

const WorkspaceSettings = ({
  workspace,
  initialWorkspace,
  isWorkspaceAdmin,
  setWorkspace,
  onWorkspaceUpdated,
}) => {
  const { user } = useSelector((st) => st.auth, shallowEqual);
  const { userId } = user || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const { show = {}, brandKit = {}, settings, workspaceId } = workspace || {};
  const [seoKeyword, setSeoKeyword] = useState('');
  const {
    seoKeywords,
    introMode,
    outroMode,
    outroText,
    introCustomVideoUrlLandscape,
    introCustomVideoUrlPortrait,
    outroCustomVideoUrlLandscape,
    outroCustomVideoUrlPortrait,
    showPosterMode,
    customShowPosterUrl,
    //captionsConfig,
    layoutConfig,
    musicConfig,
    thumbnailConfig,
  } = settings || {};
  const { showPageData, refreshShowPageData } = useShowPage();
  const { show: showFromShowPageData } = showPageData || {};
  const { posterImageUrl } = showFromShowPageData || {};
  const { name: showName, audienceDescription, showDescription } = show || {};
  const [isShowPosterLoading, setIsShowPosterLoading] = useState(false);
  const [showDescriptionInput, setShowDescriptionInput] =
    useState(showDescription);
  const [audienceDescriptionInput, setAudienceDescriptionInput] =
    useState(audienceDescription);
  const { backdrop_image, customShowPageImageMode } = brandKit;

  const [isSubmittingFile, setIsSubmittingFile] = useState(false);

  const [isLeaveWorkspaceModalOpen, setIsLeaveWorkspaceModalOpen] =
    useState(false);

  const updateWorkspace = useCallback(
    (field, value) => {
      if (!workspace) return;

      setWorkspace((prevWorkspace) => ({ ...prevWorkspace, [field]: value }));
    },
    [workspace, setWorkspace]
  );

  const updateName = useCallback(
    (event, value) => {
      updateWorkspace('name', value);
    },
    [updateWorkspace]
  );

  const updateShow = useCallback(
    (key, value) => {
      updateWorkspace('show', {
        ...show,
        [key]: value,
      });
    },
    [show, updateWorkspace]
  );

  const updateSettings = useCallback(
    (key, value) => {
      updateWorkspace('settings', {
        ...settings,
        [key]: value,
      });
    },
    [settings, updateWorkspace]
  );

  const updateBrandkit = useCallback(
    (key, value) => {
      updateWorkspace('brandKit', {
        ...brandKit,
        [key]: value,
      });
    },
    [brandKit, updateWorkspace]
  );

  const removeFromWorkspace = useCallback(async () => {
    try {
      await removeWorkspaceMemberApi(workspace.workspaceId, user.userId);
      await refreshUser(dispatch, user.userId);
      history.push('/portal');
    } catch (e) {}
  }, [dispatch, user.userId, workspace, history]);

  const isLastWorkspaceAdmin =
    isWorkspaceAdmin &&
    workspace?.members?.filter((member) => member.isAdmin).length === 1;

  const isWorkspaceBillingOwner =
    userId === workspace?.billingOwnerUserId && workspace?.stripeSubscriptionId;

  const [, setIsLoading] = useState(false);

  const handlePublishWebsite = useCallback(async () => {
    setIsLoading(true);

    try {
      publishWorkspaceApi(workspace.workspaceId);

      notifyUser(
        'The website has been published. The Show and Highlights pages should be updated in a few minutes'
      );
    } catch (error) {
      logerror({
        ...fromError(error),
        message: 'There was a problem saving workspace settings',
        workspaceId: workspace.workspaceId,
        userId,
      });
      notifyUser(
        'We could not save your settings. Please try again or check your internet connection.'
      );
    } finally {
      setIsLoading(false);
    }
  }, [userId, workspace.workspaceId]);

  const handleUploadIntroOutroVideo =
    (videoSettingsProperty) => async (buffer, type, name) => {
      try {
        const uploadResult = await uploadIntroOutroVideo(
          workspace.workspaceId + '/' + name,
          buffer,
          type
        );

        if (uploadResult) {
          const newUrl =
            'https://storage.googleapis.com/zync-media/' +
            uploadResult?.result?.metadata?.name;

          return updateSettings(videoSettingsProperty, newUrl);
        }
      } catch (error) {
        logerror(fromError(error));
      }
      //setIsSubmittingFile(false);
    };

  const handleDeleteIntroOutroVideo = (videoSettingsProperty) => async () => {
    updateSettings(videoSettingsProperty, '');
  };

  const handleSubmitCustomShowpagePicture = useCallback(
    async (buffer, type, name) => {
      setIsSubmittingFile(true);

      try {
        const uploadResult = await uploadCustomShowpagePicture(
          workspaceId + '/showpage-pictures/' + name,
          buffer,
          type
        );
        if (uploadResult) {
          const newUrl =
            'https://storage.googleapis.com/zync-media/' +
            uploadResult?.result?.metadata?.name;

          updateBrandkit('customShowPageImageUrl', newUrl);
        }
      } catch (error) {
        logerror(fromError(error));
      }
      setIsSubmittingFile(false);
    },
    [updateBrandkit, workspaceId]
  );

  const handleRegenerateShowPoster = async () => {
    setIsShowPosterLoading(true);

    const showPosterTaskDetails = {
      workspaceId,
      processorId: 'SHOW_POSTER',
    };

    const showPosterBody = {
      taskDetails: showPosterTaskDetails,
      forceRun: true,
    };

    axios.post(`${PROCESSOR_BASE_URL}/cloudtask`, showPosterBody);

    await delay(20_000);

    const showPageTaskDetails = {
      workspaceId,
      processorId: 'SHOW_PAGE',
    };

    const showPageBody = {
      taskDetails: showPageTaskDetails,
      forceRun: true,
      useOptionals: true,
    };

    axios.post(`${PROCESSOR_BASE_URL}/cloudtask`, showPageBody);

    await delay(10_000);

    await refreshShowPageData();

    setIsShowPosterLoading(false);
  };

  const handleUploadShowPoster = async (buffer, type, fileName) => {
    setIsShowPosterLoading(true);
    try {
      const uploadResult = await uploadPosterImage(fileName, buffer, type);
      if (uploadResult) {
        const url =
          'https://storage.googleapis.com/zync-media/' +
          uploadResult?.result?.metadata?.name;
        updateSettings('customShowPosterUrl', url);
      }
    } catch (error) {
      logerror(fromError(error));
    }
    setIsShowPosterLoading(false);
  };

  const hostedByText = getHostedByText(
    show?.hostedByText,
    workspace?.creatorUserName
  );

  return (
    <>
      <div className="max-w-[900px] mx-auto pb-24">
        <div className="bg-white border border-[#EBEAED] rounded-lg mb-8">
          <h3 className="text-blue-dark font-semibold bg-purple/5 px-4 py-3">
            Show Settings
          </h3>
          <div className="px-4">
            <div className="my-8">
              <TextInput
                label="Show Name"
                defaultValue={showName}
                maxLength={50}
                onBlur={(event, value) => {
                  updateShow('name', value);
                }}
              />
            </div>
            <div className="my-8">
              <TextArea
                label="Show Description"
                value={showDescriptionInput}
                onBlur={(event) => {
                  updateShow('showDescription', showDescriptionInput);
                }}
                maxLength={1000}
                onChange={(value) => setShowDescriptionInput(value)}
              />
            </div>
            <div className="my-8">
              <TextArea
                label="Audience Description"
                value={audienceDescriptionInput}
                onBlur={() => {
                  updateShow('audienceDescription', audienceDescriptionInput);
                }}
                maxLength={1000}
                onChange={(value) => setAudienceDescriptionInput(value)}
              />
            </div>
            {/*<div className="my-8">
              <div className="text-blue-dark font-medium text-sm mb-2">
                Channels
              </div>
              <div className="flex items-center flex-wrap gap-2">
                {[
                  'linkedIn',
                  'twitter',
                  'facebook',
                  'youtube',
                  'newsletter',
                  'website',
                  'instagram',
                  'tiktok',
                ].map((channelName) => (
                  <button
                    key={channelName}
                    onClick={() =>
                      updateSettings(
                        'channels',
                        channels.includes(channelName)
                          ? channels.filter(
                              (channel) => channel !== channelName
                            )
                          : channels.concat(channelName)
                      )
                    }
                    className={classNames(
                      'cursor-pointer capitalize text-white text-sm p-2 font-bold rounded-full min-w-fit my-2 duration-300',
                      channels.includes(channelName)
                        ? 'bg-blue'
                        : 'bg-blue-light bg-opacity-60'
                    )}
                  >
                    {channelName}
                  </button>
                ))}
              </div>
                    </div>*/}
          </div>
        </div>
        <div className="bg-white border border-[#EBEAED] rounded-lg mb-8">
          <WorkspaceBrandKitForm
            setWorkspace={setWorkspace}
            workspace={workspace}
            initialWorkspace={initialWorkspace}
          />
        </div>
        <div className="bg-white border border-[#EBEAED] rounded-lg mb-8">
          <h3 className="text-blue-dark font-semibold bg-purple/5 px-4 py-3">
            SEO Settings
          </h3>
          <div className="px-4">
            <div className="my-8">
              <TextInput
                label="Add at least 5 SEO Keywords"
                defaultValue={seoKeyword}
                onChange={(event) => setSeoKeyword(event.target.value)}
                onEnter={() => {
                  const trimmedSeoKeyWord = seoKeyword.trim();
                  if (trimmedSeoKeyWord.length === 0) return;
                  if (
                    seoKeywords.some(
                      (word) =>
                        word.toLowerCase() === trimmedSeoKeyWord.toLowerCase()
                    )
                  ) {
                    return;
                  }
                  if (seoKeywords.length >= 20) return;
                  updateSettings('seoKeywords', [...seoKeywords, seoKeyword]);
                  setSeoKeyword('');
                }}
                maxLength={300}
              />
              {seoKeywords.length > 0 && (
                <>
                  <div className="text-blue-dark font-medium text-sm mb-2 font-inter">
                    Keywords
                  </div>
                  <div className="flex gap-2 flex-wrap">
                    {seoKeywords.map((word) => (
                      <div
                        className="bg-purple text-white p-1 text-sm rounded flex gap-2 items-center w-fit"
                        key={word}
                      >
                        <span>{word}</span>
                        <button
                          type="button"
                          className="flex items-center"
                          onClick={() => {
                            updateSettings(
                              'seoKeywords',
                              seoKeywords.filter((w) => w !== word)
                            );
                          }}
                        >
                          <FontAwesomeIcon icon="times" />
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white border border-[#EBEAED] rounded-lg mb-8">
          <h3 className="text-blue-dark font-semibold bg-purple/5 px-4 py-3">
            Scene Backdrop Settings
          </h3>
          <div className="flex gap-4 p-5">
            <ShowBackdrops
              workspaceId={workspace.workspaceId}
              selectedKey={backdrop_image}
              onSelect={(value) => updateBrandkit('backdrop_image', value)}
            />
            <div className="shrink-0">
              <PreviewSlide
                brandKit={brandKit}
                sceneTemplateId="scene_interview_question_preview"
                width={500}
                height={(500 * 9) / 16}
              />
            </div>
          </div>
        </div>

        <div className="bg-white border border-[#EBEAED] rounded-lg mb-8">
          <h3 className="text-blue-dark font-semibold bg-purple/5 px-4 py-3">
            Show Host
          </h3>
          <div className="p-5">
            {/**
             {!showPageImageDisabled && (
             <HostImageUploader
             workspaceId={workspace.workspaceId}
             showPageImageUrl={brandKit?.showPageImageUrl}
             onUploadComplete={async (imageUrl) =>
             await updateBrandkit('showPageImageUrl', imageUrl)
             }
             />
             )}
             **/}
          </div>
          <div className="mb-4 flex gap-4 px-5">
            <div className="flex-[1_0_0%]">
              <div className="mb-4 flex flex-col gap-4">
                <div>
                  <p className="text-blue-dark font-medium text-sm mb-2">
                    Pick Show Page Picture
                  </p>
                  <DropdownSelect
                    options={Object.values(HOST_IMAGE_SETTINGS).map((o) => ({
                      key: o,
                      title: o,
                    }))}
                    selectedKey={customShowPageImageMode}
                    angleColor={'fill-blue-gray'}
                    onSelect={(value) => {
                      updateBrandkit('customShowPageImageMode', value);
                    }}
                    borderColor={DropdownSelect.borderColor.GRAY}
                    borderSize={DropdownSelect.borderSize.ONE}
                    backgroundColor={DropdownSelect.backgroundColor.WHITE}
                    fontSize={DropdownSelect.fontSize.SMALL}
                    fontColor={DropdownSelect.fontColor.BLUE_GRAY}
                  />
                </div>
                {brandKit.customShowPageImageMode ===
                HOST_IMAGE_SETTINGS.custom ? (
                  <ImageUploader
                    disabled={isSubmittingFile}
                    loading={isSubmittingFile}
                    label={
                      <div className="flex gap-2 items-center justify-center">
                        <AttachmentIcon className="stroke-purple" />
                        Upload Custom Picture
                      </div>
                    }
                    buttonColor={Button.colors.PURPLE_LITE}
                    onImageSubmit={handleSubmitCustomShowpagePicture}
                    appendTimeStampToFile={true}
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                  />
                ) : null}
              </div>
              <p className="text-blue-dark font-medium text-sm mb-2">
                Pick Primary Host
              </p>
              <DropdownSelect
                options={(workspace?.members || [])
                  .filter((member) => !member.hidden && member.userName)
                  .map((member) => ({
                    key: member._id,
                    title: member.userName,
                  }))}
                angleColor="fill-purple"
                selectedKey={
                  (workspace?.members || []).find(
                    (member) => member.userId === workspace.creatorUserId
                  )?._id
                }
                onSelect={async (creatorId) => {
                  const user = (workspace?.members || []).find(
                    (member) => member._id === creatorId
                  );

                  updateWorkspace('creator', creatorId);
                  updateWorkspace('creatorUserId', user?.userId);
                  updateWorkspace('creatorUserName', user?.userName);
                  updateWorkspace('creatorAvatarUrl', user?.avatarUrl);
                }}
                ellipsis={false}
                isAsync
                borderColor={DropdownSelect.borderColor.GRAY}
                borderSize={DropdownSelect.borderSize.ONE}
                backgroundColor={DropdownSelect.backgroundColor.WHITE}
                fontSize={DropdownSelect.fontSize.SMALL}
                fontColor={DropdownSelect.fontColor.BLUE_GRAY}
              />
              <div className="flex-[1_0_0%]">
                <TextInput
                  label="Update Hosted By Name"
                  defaultValue={show?.hostedByText}
                  maxLength={50}
                  onBlur={(event, value) => {
                    updateShow('hostedByText', value);
                  }}
                />
              </div>
            </div>
            <div className="shrink-0 relative">
              <div
                className={classNames(
                  'relative',
                  customShowPageImageMode === HOST_IMAGE_SETTINGS.none
                    ? ''
                    : 'invisible'
                )}
              >
                <PreviewSlide
                  brandKit={brandKit}
                  sceneTemplateId="show_page_top_section"
                  width={500}
                  height={(500 * 9) / 16}
                  show={show}
                  hostedBy={hostedByText}
                />
              </div>
              <div
                className={classNames(
                  'absolute top-0 left-0',
                  customShowPageImageMode !== HOST_IMAGE_SETTINGS.default
                    ? 'invisible'
                    : ''
                )}
              >
                <PreviewSlide
                  brandKit={brandKit}
                  sceneTemplateId="show_page_top_section_avatar"
                  width={500}
                  height={(500 * 9) / 16}
                  show={show}
                  avatarUrl={
                    workspace?.members?.find(
                      (member) => member.userId === workspace.creatorUserId
                    )?.avatarUrl
                  }
                  hostedBy={hostedByText}
                />
              </div>
              <div
                className={classNames(
                  'absolute top-0 left-0',
                  customShowPageImageMode !== HOST_IMAGE_SETTINGS.custom
                    ? 'invisible'
                    : ''
                )}
              >
                <PreviewSlide
                  brandKit={brandKit}
                  sceneTemplateId="show_page_top_section_avatar"
                  width={500}
                  height={(500 * 9) / 16}
                  show={show}
                  avatarUrl={brandKit.customShowPageImageUrl}
                  hostedBy={hostedByText}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white border border-[#EBEAED] rounded-lg mb-8">
          <h3 className="text-blue-dark font-semibold bg-purple/5 px-4 py-3">
            Show Poster
          </h3>
          <div className="mb-4 flex gap-4 px-5 pt-5">
            <div className="flex-[1_0_0%] flex flex-col gap-2">
              <DropdownSelect
                options={Object.values(showPosterSettings).map((o) => ({
                  key: o,
                  title: o,
                }))}
                selectedKey={showPosterMode}
                angleColor={'fill-blue-gray'}
                onSelect={(value) => {
                  updateSettings('showPosterMode', value);
                }}
                borderColor={DropdownSelect.borderColor.GRAY}
                borderSize={DropdownSelect.borderSize.ONE}
                backgroundColor={DropdownSelect.backgroundColor.WHITE}
                fontSize={DropdownSelect.fontSize.SMALL}
                fontColor={DropdownSelect.fontColor.BLUE_GRAY}
              />
              {showPosterMode === showPosterSettings.default && posterImageUrl && (
                <Button
                  color={Button.colors.PURPLE}
                  size={Button.sizes.FIT}
                  state={
                    isShowPosterLoading
                      ? Button.states.LOADING
                      : Button.states.DEFAULT
                  }
                  onClick={handleRegenerateShowPoster}
                >
                  <span className="text-xs font-medium">
                    Regenerate Show Poster
                  </span>
                </Button>
              )}
              {showPosterMode === showPosterSettings.custom && (
                <ImageUploader
                  enforcedAspectRatio={1 / 1}
                  label={
                    <span className="flex items-center gap-2 text-xs whitespace-nowrap py-1">
                      <AttachmentIcon className="stroke-white" /> Upload Show
                      Poster
                    </span>
                  }
                  buttonColor={'PURPLE'}
                  loading={isShowPosterLoading}
                  onImageDimensionsExceeded={() => {
                    notifyUser(
                      'Please use valid aspect ratio of 1:1 for the show poster.'
                    );
                  }}
                  buttonPadding={Button.padding.SMALL}
                  onImageSubmit={handleUploadShowPoster}
                />
              )}
            </div>
            <div className="max-w-[300px]">
              {showPosterMode === showPosterSettings.default &&
                posterImageUrl && (
                  <img
                    src={posterImageUrl}
                    alt="show poster"
                    className="rounded"
                  />
                )}
              {showPosterMode === showPosterSettings.custom &&
                customShowPosterUrl && (
                  <img
                    src={customShowPosterUrl}
                    alt="show poster"
                    className="rounded"
                  />
                )}
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="bg-white border border-[#EBEAED] rounded-lg mb-8 w-full flex-[1_0_0%] max-w-[50%]">
            <h3 className="text-blue-dark font-semibold bg-purple/5 px-4 py-3">
              Intro Video
            </h3>
            <div className="flex flex-col gap-5 p-5">
              <DropdownSelect
                options={Object.values(INTRO_SETTINGS).map((o) => ({
                  key: o,
                  title: o,
                }))}
                selectedKey={introMode}
                angleColor={'fill-blue-gray'}
                onSelect={(value) => {
                  updateSettings('introMode', value);
                }}
                borderColor={DropdownSelect.borderColor.GRAY}
                borderSize={DropdownSelect.borderSize.ONE}
                backgroundColor={DropdownSelect.backgroundColor.WHITE}
                fontSize={DropdownSelect.fontSize.SMALL}
                fontColor={DropdownSelect.fontColor.BLUE_GRAY}
              />
              {introMode === INTRO_SETTINGS.custom && (
                <div className="flex gap-4 -mt-4">
                  <div className="flex-[1_0_0%] max-w-[50%]">
                    <VideoUploadContainer
                      videoUrl={introCustomVideoUrlLandscape}
                      onVideoDelete={handleDeleteIntroOutroVideo(
                        'introCustomVideoUrlLandscape'
                      )}
                      onVideoSubmit={handleUploadIntroOutroVideo(
                        'introCustomVideoUrlLandscape'
                      )}
                      label="Upload Landscape Video"
                      enforcedAspectRatio={16 / 9} // standard landscape video aspect ratio
                    />
                  </div>{' '}
                  <div className="flex-[1_0_0%] max-w-[50%]">
                    <VideoUploadContainer
                      videoUrl={introCustomVideoUrlPortrait}
                      onVideoDelete={handleDeleteIntroOutroVideo(
                        'introCustomVideoUrlPortrait'
                      )}
                      onVideoSubmit={handleUploadIntroOutroVideo(
                        'introCustomVideoUrlPortrait'
                      )}
                      label="Upload Portrait Video"
                      enforcedAspectRatio={9 / 16} // standard portrait video aspect ratio
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="bg-white border border-[#EBEAED] rounded-lg mb-8 w-full flex-[1_0_0%] max-w-[50%]">
            <h3 className="text-blue-dark font-semibold bg-purple/5 px-4 py-3">
              Outro Video
            </h3>
            <div className="flex flex-col gap-5 p-5">
              <DropdownSelect
                options={Object.values(OUTRO_SETTINGS).map((o) => ({
                  key: o,
                  title: o,
                }))}
                selectedKey={outroMode}
                angleColor={'fill-blue-gray'}
                onSelect={(value) => {
                  updateSettings('outroMode', value);
                }}
                borderColor={DropdownSelect.borderColor.GRAY}
                borderSize={DropdownSelect.borderSize.ONE}
                backgroundColor={DropdownSelect.backgroundColor.WHITE}
                fontSize={DropdownSelect.fontSize.SMALL}
                fontColor={DropdownSelect.fontColor.BLUE_GRAY}
              />
              {outroMode === OUTRO_SETTINGS.default && (
                <TextInput
                  defaultValue={outroText}
                  onBlur={(event, value) => {
                    updateSettings('outroText', value);
                  }}
                  maxLength={50}
                />
              )}
              {outroMode === OUTRO_SETTINGS.custom && (
                <div className="flex gap-4 -mt-4">
                  <div className="flex-[1_0_0%] max-w-[50%]">
                    <VideoUploadContainer
                      videoUrl={outroCustomVideoUrlLandscape}
                      onVideoDelete={handleDeleteIntroOutroVideo(
                        'outroCustomVideoUrlLandscape'
                      )}
                      onVideoSubmit={handleUploadIntroOutroVideo(
                        'outroCustomVideoUrlLandscape'
                      )}
                      label="Upload Landscape Video"
                      enforcedAspectRatio={16 / 9} // standard landscape video aspect ratio
                    />
                  </div>
                  <div className="flex-[1_0_0%] max-w-[50%]">
                    <VideoUploadContainer
                      videoUrl={outroCustomVideoUrlPortrait}
                      onVideoDelete={handleDeleteIntroOutroVideo(
                        'outroCustomVideoUrlPortrait'
                      )}
                      onVideoSubmit={handleUploadIntroOutroVideo(
                        'outroCustomVideoUrlPortrait'
                      )}
                      label="Upload Portrait Video"
                      enforcedAspectRatio={9 / 16} // standard portrait video aspect ratio
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-white border border-[#EBEAED] rounded-lg mb-8">
          <h3 className="text-blue-dark font-semibold bg-purple/5 px-4 py-3">
            Publish Website
          </h3>
          <div className="py-6 mx-4">
            <p className="mb-4">
              Click here to update your Show Page and Highlights page
            </p>
            <Button color={Button.colors.PURPLE} onClick={handlePublishWebsite}>
              <span className="text-sm">Publish Website</span>
            </Button>
          </div>
        </div>
        {isWorkspaceAdmin && (
          <>
            <div className="bg-white border border-[#EBEAED] rounded-lg mb-8">
              <h3 className="text-blue-dark font-semibold bg-purple/5 px-4 py-3">
                Workspace Settings
              </h3>
              <div className="py-6 mx-4">
                <div className="text-blue-dark font-medium text-sm mb-2 font-inter flex gap-1">
                  Workspace Name {`[Workspace Id: ${workspace.workspaceId}`}
                  <CopyToClipboard text={workspace.workspaceId}>
                    <button>
                      <CopyIcon />
                    </button>
                  </CopyToClipboard>
                  {`]`}
                </div>
                <TextInput
                  defaultValue={workspace.name}
                  onBlur={updateName}
                  onEnter={updateName}
                />
              </div>
            </div>
          </>
        )}
        {workspace.workspacePlan === PLANS.admin.id ? (
          <div className="bg-white border border-[#EBEAED] rounded-lg mb-8">
            <h3 className="text-blue-dark font-semibold bg-purple/5 px-4 py-3">
              Solo Episode
            </h3>
            <div className="py-6 mx-4 flex gap-3 flex-col">
              {/*<div className="text-blue-dark font-medium text-sm mb-2 font-inter flex gap-1">
                Captions config
              </div>
              <div className="flex gap-4 items-start">
                <DropdownSelect
                  options={CAPTIONS_CONFIG_SETTINGS}
                  selectedKey={captionsConfig}
                  angleColor={'fill-blue-gray'}
                  onSelect={(value) => {
                    updateSettings('captionsConfig', value);
                  }}
                  borderColor={DropdownSelect.borderColor.GRAY}
                  borderSize={DropdownSelect.borderSize.ONE}
                  backgroundColor={DropdownSelect.backgroundColor.WHITE}
                  fontSize={DropdownSelect.fontSize.SMALL}
                  fontColor={DropdownSelect.fontColor.BLUE_GRAY}
                />
                <PreviewSlide
                  brandKit={brandKit}
                  sceneTemplateId={getConfigTemplateId(
                    captionsConfig,
                    THUMBNAIL_CONFIG_SETTINGS
                  )}
                  width={500}
                  height={(500 * 9) / 16}
                  show={show}
                  avatarUrl={brandKit.customShowPageImageUrl}
                  hostedBy={hostedByText}
                />
              </div>*/}
              <div>
                <div className="text-blue-dark font-medium text-sm mb-2 font-inter flex gap-1">
                  Layout Config
                </div>
                <div className="flex gap-4 items-start">
                  <DropdownSelect
                    options={LAYOUT_CONFIG_SETTINGS}
                    selectedKey={layoutConfig}
                    angleColor={'fill-blue-gray'}
                    onSelect={(value) => {
                      updateSettings('layoutConfig', value);
                    }}
                    borderColor={DropdownSelect.borderColor.GRAY}
                    borderSize={DropdownSelect.borderSize.ONE}
                    backgroundColor={DropdownSelect.backgroundColor.WHITE}
                    fontSize={DropdownSelect.fontSize.SMALL}
                    fontColor={DropdownSelect.fontColor.BLUE_GRAY}
                  />
                  <PreviewSlide
                    brandKit={brandKit}
                    sceneTemplateId={getConfigTemplateId(
                      layoutConfig,
                      'scene_layout_1'
                    )}
                    width={500}
                    height={(500 * 9) / 16}
                    show={show}
                    avatarUrl={brandKit.customShowPageImageUrl}
                    hostedBy={hostedByText}
                  />
                </div>
              </div>
              <div>
                <div className="text-blue-dark font-medium text-sm mb-2 font-inter flex gap-1">
                  Music Config
                </div>
                <div>
                  <DropdownSelect
                    options={MUSIC_CONFIG_SETTINGS}
                    selectedKey={musicConfig}
                    angleColor={'fill-blue-gray'}
                    onSelect={(value) => {
                      updateSettings('musicConfig', value);
                    }}
                    borderColor={DropdownSelect.borderColor.GRAY}
                    borderSize={DropdownSelect.borderSize.ONE}
                    backgroundColor={DropdownSelect.backgroundColor.WHITE}
                    fontSize={DropdownSelect.fontSize.SMALL}
                    fontColor={DropdownSelect.fontColor.BLUE_GRAY}
                  />
                </div>
              </div>
              <div>
                <div className="text-blue-dark font-medium text-sm mb-2 font-inter flex gap-1">
                  Thumbnail Config
                </div>
                <div className="flex gap-4 items-start">
                  <DropdownSelect
                    options={THUMBNAIL_CONFIG_SETTINGS}
                    selectedKey={thumbnailConfig}
                    angleColor={'fill-blue-gray'}
                    onSelect={(value) => {
                      updateSettings('thumbnailConfig', value);
                    }}
                    borderColor={DropdownSelect.borderColor.GRAY}
                    borderSize={DropdownSelect.borderSize.ONE}
                    backgroundColor={DropdownSelect.backgroundColor.WHITE}
                    fontSize={DropdownSelect.fontSize.SMALL}
                    fontColor={DropdownSelect.fontColor.BLUE_GRAY}
                  />
                  <PreviewSlide
                    brandKit={brandKit}
                    sceneTemplateId={getConfigTemplateId(
                      thumbnailConfig,
                      'thumbnail_solo_segment_1'
                    )}
                    width={500}
                    height={(500 * 9) / 16}
                    show={show}
                    avatarUrl={brandKit.customShowPageImageUrl}
                    hostedBy={hostedByText}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="bg-white">
          <div className="bg-red bg-opacity-5 border border-red rounded-md p-3">
            <WarningTitle>
              <WarningCircleIcon className="fill-red" />
              <span className="text-red text-sm font-medium">Caution</span>
            </WarningTitle>
            <p className="text-sm text-blue-gray my-3.5">
              Please exercise caution while performing actions in this space
            </p>
            <Button
              color={Button.colors.RED}
              size={Button.sizes.FULL}
              onClick={() => setIsLeaveWorkspaceModalOpen(true)}
              state={
                isLastWorkspaceAdmin
                  ? Button.states.DISABLED
                  : Button.states.DEFAULT
              }
            >
              <span className="text-base font-medium">Leave Workspace</span>
            </Button>
            {isLastWorkspaceAdmin && !isWorkspaceBillingOwner && (
              <p className="text-red text-sm font-medium my-2">
                You cannot leave workspace as the last admin.
              </p>
            )}
            {isWorkspaceBillingOwner && (
              <p className="text-red text-sm font-medium my-2">
                You cannot leave workspace with active subscription. You need to
                cancel the subscription first.
              </p>
            )}
          </div>
        </div>
        <ConfirmationModal
          open={isLeaveWorkspaceModalOpen}
          onClose={() => setIsLeaveWorkspaceModalOpen(false)}
          onConfirm={removeFromWorkspace}
          title={
            <h4 className="font-semibold text-xl -ml-2 leading-0">
              Leave Workspace?
            </h4>
          }
          text={
            <p className="-mt-2 mb-3">
              Are you sure you want to leave this workspace?
            </p>
          }
          width="400px"
          showCloseButton={false}
          modalActions={
            <div className="flex space-x-2">
              <Button
                color={Button.colors.GRAY}
                onClick={() => setIsLeaveWorkspaceModalOpen(false)}
                size={Button.sizes.FULL}
              >
                <span className="text-sm font-medium">Cancel</span>
              </Button>
              <Button
                color={Button.colors.RED}
                onClick={removeFromWorkspace}
                size={Button.sizes.FULL}
              >
                <span className="text-sm font-medium">Confirm</span>
              </Button>
            </div>
          }
        />
        <ToastOutlet />
      </div>
    </>
  );
};

export default WorkspaceSettings;
