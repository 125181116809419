import { SocialMediaPost } from './SocialMediaPost';
import { useEffect, useMemo } from 'react';
import { InstagramPost } from './InstagramPost';
import { Link, useParams } from 'react-router-dom';
import { instagramIcon } from './PublishMenus';
import { scrollToElement } from './YoutubePosts';

export const InstagramPosts = ({
  teaserEditedPortrait,
  shorts,
  workspace,
  posterUrl,
  saveHighlightSocialMediaPost,
  saveShortsSocialMediaPost,
  shared,
  meetingSeriesId,
  isSoloEpisode,
  highlights,
}) => {
  const { section } = useParams();
  const { brandKit } = workspace || {};

  const { playbackUrl: teaserPlaybackUrl, files: teaserFiles } =
    teaserEditedPortrait || {};

  useEffect(() => {
    if (!section) return;
    scrollToElement(section);
  }, [section]);

  const instagramShorts = useMemo(
    () =>
      shorts
        .filter((s) => s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_PORTRAIT)
        .map((s, index) => ({
          highlightId: s.highlightId,
          title: s.shortTextData?.thumbnailTitle || `Shorts ${index + 1}`,
          postTitle: s.shortTextData?.instagramPost || '',
          playbackUrl:
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_PORTRAIT.playbackUrl,
          downloadUrl:
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_PORTRAIT.playbackUrl,
          uploadedToYoutube: false,
          aspectRatio:
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_PORTRAIT.aspectRatio,
        })),
    [shorts]
  );

  const instagramHighlights = useMemo(
    () =>
      isSoloEpisode
        ? highlights
            .filter((h) => {
              if (!h?.HIGHLIGHT_EDITED_PORTRAIT) return false;
              if (h?.duration) {
                return h?.duration < 60;
              }

              if (h?.startTime && h?.endTime) {
                return h?.endTime - h?.startTime < 60;
              }

              return false;
            })
            .map((h) => ({
              highlightId: h.highlightId,
              title: h.title,
              thumbnailUrl: h?.HIGHLIGHT_PORTRAIT_THUMBNAIL?.imageUrl,
              playbackUrl: h?.HIGHLIGHT_EDITED_PORTRAIT?.playbackUrl,
              postTitle:
                h?.HIGHLIGHT_SOCIAL_MEDIA_POSTS?.socialMediaPosts?.instagram
                  ?.text ||
                h.HIGHLIGHT_SOCIAL_MEDIA_POSTS.socialMediaPosts.youtube.text,
              downloadUrl: h?.HIGHLIGHT_EDITED_PORTRAIT?.downloadUrl,
              aspectRatio: 'portrait',
            }))
        : [],
    [isSoloEpisode, highlights]
  );

  return (
    <>
      <Link
        to={
          shared
            ? `/e/${meetingSeriesId}/contentkit`
            : `/e/${meetingSeriesId}/details/publish`
        }
        className="self-start pl-64"
      >
        <div className="font-medium text-sm">
          <span className="text-[#97A0AF]">
            Content Kit /{'  '}
            <span className="text-blue-dark">Instagram Highlights</span>
          </span>
        </div>
      </Link>
      <div className="flex flex-col gap-5 justify-center items-center w-full mx-auto pl-64 pr-52 relative">
        <img
          src={instagramIcon}
          alt="instagram logo"
          className="object-contain w-[24px] self-start pt-5"
        />
        <div className="flex flex-col gap-5">
          <div className="text-blue-dark font-semibold text-xl" id="reels">
            Instagram Reels
          </div>
          <div className="flex gap-[30px] flex-wrap">
            {teaserPlaybackUrl ? (
              <SocialMediaPost
                playbackUrl={teaserPlaybackUrl}
                title="Teaser Video"
                startCollapsed={false}
                files={teaserFiles}
                socialMediaType="instagram"
                aspectRatio="portrait"
              />
            ) : null}
            {instagramShorts.length > 0 &&
              instagramShorts.map((s) => (
                <InstagramPost
                  playbackUrl={s.playbackUrl}
                  thumbnailUrl={null}
                  title={s.title}
                  postTitle={s.postTitle}
                  posterUrl={posterUrl}
                  aspectRatio={s.aspectRatio}
                  brandKit={brandKit}
                  key={s.highlightId}
                  files={[]}
                  downloadUrl={s.downloadUrl}
                  videoUrlOverride={s.downloadUrl}
                  saveShortsSocialMediaPost={saveShortsSocialMediaPost}
                  highlightId={s.highlightId}
                  shared={shared}
                />
              ))}
            {instagramHighlights.map((h) => (
              <InstagramPost
                playbackUrl={h.playbackUrl}
                thumbnailUrl={h.thumbnailUrl}
                title={h.title}
                postTitle={h.postTitle}
                posterUrl={posterUrl}
                aspectRatio={h.aspectRatio}
                brandKit={brandKit}
                key={h.highlightId}
                files={[]}
                downloadUrl={h.downloadUrl}
                videoUrlOverride={h.thumbnailUrl ? null : h.downloadUrl}
                saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
                highlightId={h.highlightId}
                shared={shared}
              />
            ))}
          </div>
        </div>
        <div className="absolute inset-0 bg-[#F107560D] -z-[9999]" />
      </div>
    </>
  );
};
