import React from 'react';

import { Navbar, PreviousPage } from './NavbarComponents';
import { NavbarUserDropdownMenu } from './NavbarUserDropdownMenu';

export const DefaultNavbar = ({
  currentPage,
  previousRoute,
  isAuthenticated,
}) => {
  return (
    <Navbar>
      <div className="w-full flex justify-between items-center">
        <PreviousPage
          currentPage={currentPage}
          previousRoute={previousRoute ?? '/'}
        />
        <ul>
          {isAuthenticated && (
            <li className="hidden md:block">
              <NavbarUserDropdownMenu />
            </li>
          )}
        </ul>
      </div>
    </Navbar>
  );
};
