import React, { useEffect } from 'react';
import { useSelectDefaultWorkspace } from '../../hooks/useSelectDefaultWorkspace';
import { Tabs } from '../settings';
import mixpanel from 'mixpanel-browser';
import { DefaultNavbar } from '../Navbar/DefaultNavbar';
import { pageTitles } from '../Navbar/NavbarComponents';
import { FuturisticBackground } from '../../components/FuturisticBackground';
import { useSelector } from 'react-redux';
import { PersonProfileIcon } from '../../components/icons/PersonProfileIcon';
import { usePosterSlide } from '../../components/series/settings/usePosterSlide';
import { ScenePreview } from '../../components/authoring/Menu/ScenePreview';
import { UserProfileForm } from './UserProfileForm';

const PROFILE = { Icon: PersonProfileIcon, label: 'Profile' };

const TABS = [PROFILE];

const UserProfile = ({ location }) => {
  const user = useSelector((state) => state.auth.user);

  const pathChunks = location.pathname.split('/');

  const selectedTab = TABS.find(
    (t) => t.label.toLowerCase() === pathChunks[pathChunks.length - 1]
  );

  useEffect(() => {
    mixpanel.track('User Profile - Page Visit');
  }, []);

  /** used for side effect for selecting default workspace if there is not workspace selected yet */
  const { currentWorkspaceFromUrl } = useSelectDefaultWorkspace();

  const { posterSlideWithPlaceholderValues } = usePosterSlide(
    undefined,
    undefined,
    undefined,
    'user_profile_preview'
  );

  return (
    <>
      <DefaultNavbar
        currentPage={pageTitles.workspaceDetails}
        isAuthenticated={true}
      />
      <FuturisticBackground
        topGradientOpacity={FuturisticBackground.topGradientOpacity.LOW}
        showDecorations={false}
      />
      <div className="pt-16 relative">
        <h1 className="text-xl font-semibold text-center mb-2.5">
          {user?.userName || 'Loading...'}
        </h1>
        <h4 className="text-sm text-center text-blue-gray font-medium mb-7">
          Adjust User Profile Settings here.
        </h4>
        {selectedTab && (
          <>
            <Tabs tabs={TABS} initialTabSelected={selectedTab} />
            <div className="mt-12">
              {selectedTab === PROFILE && (
                <div className="max-w-[800px] mx-auto bg-white">
                  <h2>Setup Profile</h2>
                  <div className="flex gap-8">
                    <UserProfileForm />
                    <div className="basis-1/2">
                      {posterSlideWithPlaceholderValues && (
                        <ScenePreview
                          scene={posterSlideWithPlaceholderValues}
                          width={(200 / 9) * 16}
                          height={200}
                          brandKit={currentWorkspaceFromUrl?.brandKit}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserProfile;
