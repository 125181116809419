import { useClickOutside } from '../../../../helper/useClickOutside';
import React, { useCallback, useEffect } from 'react';
import { DropdownSelect } from '../../../../components/common/Dropdown';
import { TextArea } from '../../../../components/common/TextArea';
import isJSON from 'validator/es/lib/isJSON';

const MAX_DISCUSSION_POINT_CHARACTERS = 200;

export const SoloSegmentUpdate = ({
  posterSlideTemplate,
  setPosterSlideTemplate,
  setUpdate,
  onConfirm,
  onCancel,
  updateOnClose,
  series,
  hostNotesComponent,
}) => {
  const eventPresenters = series?.settings?.eventPresenters || [];

  const presenters = eventPresenters
    .slice()
    .filter((ep) => ep.type === 'speaker')
    .sort((a, b) => (a.type === b.type ? 0 : a.type > b.type ? -1 : 1));

  const speakerVideo = posterSlideTemplate.blocks?.findLast(
    (block) =>
      block.blockId === 'singlevideo' && block.settings.role === 'speaker'
  );

  const questionBlock = posterSlideTemplate.blocks.find(
    (block) => block.blockId === 'singleQuestion'
  );

  const tooltipPaneBlock = posterSlideTemplate.blocks.find(
    (block) => block.blockId === 'tooltipPane'
  );

  const discussionTopicHeadlineBlock = posterSlideTemplate.blocks.find(
    (block) => block.blockId === 'textbox'
  );

  const discussionTopicHeadlineText =
    discussionTopicHeadlineBlock?.settings?.text;

  useEffect(() => {
    if (!discussionTopicHeadlineBlock) {
      return;
    }

    if (isJSON(discussionTopicHeadlineText)) {
      discussionTopicHeadlineBlock.settings.text = 'Discussion Topic Headline';
    }
  }, [discussionTopicHeadlineBlock, discussionTopicHeadlineText]);

  const handleConfirm = useCallback(
    (shouldOpenNewlyCreated) => {
      onConfirm(
        posterSlideTemplate,
        `${questionBlock.settings.questionText}`,
        shouldOpenNewlyCreated
      );
    },
    [questionBlock, onConfirm, posterSlideTemplate]
  );

  const handleMainSpeakerBlockChange = (userId) => {
    setPosterSlideTemplate((template) => {
      if (speakerVideo) {
        speakerVideo.settings.userId = userId;
      }

      return template;
    });

    setUpdate((x) => x + 1);
  };

  useClickOutside({ current: document.querySelector('.modal') }, () => {
    updateOnClose && handleConfirm(false);
    onCancel();
  });

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <div className="flex flex-col gap-3">
          {false && (
            <div>
              <h4 className="text-sm font-medium text-blue-dark mb-1">
                Choose a Presenter
              </h4>
              <DropdownSelect
                selectedKey={speakerVideo?.settings.userId}
                options={presenters.map((ep) => ({
                  key: ep.emailAddress,
                  title: ep.fullName,
                }))}
                onSelect={(value) => {
                  handleMainSpeakerBlockChange(value);
                  handleConfirm();
                }}
              />
            </div>
          )}
          <div>
            <TextArea
              value={questionBlock.settings.questionText}
              onChange={(value) => {
                setPosterSlideTemplate((template) => {
                  template.slideName = `${value}`;
                  questionBlock.settings.questionText = value;
                  return template;
                });

                setUpdate((x) => x + 1);
              }}
              className="w-full mb-4 p-2"
              rows={4}
              placeholder="Enter the discussion point"
              onBlur={handleConfirm}
              maxLength={MAX_DISCUSSION_POINT_CHARACTERS}
              label="Question prompt"
            />
          </div>
        </div>

        {tooltipPaneBlock ? (
          <div>
            <TextArea
              value={tooltipPaneBlock.settings.content || ''}
              onChange={(value) => {
                setPosterSlideTemplate((template) => {
                  tooltipPaneBlock.settings.content = value;
                  return template;
                });

                setUpdate((x) => x + 1);
              }}
              className="w-full mb-4 p-2 h-60"
              rows={8}
              placeholder="Enter talking points"
              onBlur={handleConfirm}
              maxLength={1000}
              label="Talking Points / Video Script"
            />
          </div>
        ) : null}

        {discussionTopicHeadlineBlock ? (
          <TextArea
            value={discussionTopicHeadlineBlock.settings.text}
            onChange={(value) => {
              setPosterSlideTemplate((template) => {
                discussionTopicHeadlineBlock.settings.text = value;
                return template;
              });
              setUpdate((x) => x + 1);
            }}
            className="w-full mb-4 p-2 h-[40px]"
            rows={1}
            placeholder="Enter headline"
            onBlur={handleConfirm}
            maxLength={MAX_DISCUSSION_POINT_CHARACTERS}
            label="Headline (optional)"
          />
        ) : null}
      </div>
      <div>{hostNotesComponent}</div>
    </div>
  );
};
