export const MEETING_TOPIC = {
  heading: 'Topic',
  topics: [`Topic`],
};

export const ADD_HOST = {
  heading: 'Host',
  topics: ['Add host'],
};
export const ADD_SPEAKER = {
  heading: 'Speakers',
  topics: ['Add speakers'],
};
export const SET_DATE = {
  heading: 'Episode Time',
  topics: ['Set Episode Date And Time'],
};
export const SET_DISCUSSION_PTS = {
  heading: 'Discussion Points',
  topics: ['Add Discussion Points'],
};

export const SET_BLOG_POST = {
  heading: 'Your Content',
  topics: [
    'Your Content',
    'Share your recent LinkedIn Post, Blog or Newsletter article',
  ],
};

export const SET_IDEA = {
  heading: 'Your Idea',
  topics: ['Your Idea', 'Share your idea here'],
};

export const ProgressStates = {
  BEFORE: 'BEFORE',
  CURRENT: 'CURRENT',
  AFTER: 'AFTER',
};
export const checkpointNames = {
  MEETING_TOPIC: 'MEETING_TOPIC',
  ADD_HOST: 'ADD_HOST',
  ADD_SPEAKER: 'ADD_SPEAKER',
  SET_DATE: 'SET_DATE',
  SET_DISCUSSION_PTS: 'SET_DISCUSSION_PTS',
  SET_BLOG_POST: 'SET_BLOG_POST',
  SET_IDEA: 'SET_IDEA',
};
export const checkpoints = {
  [checkpointNames.MEETING_TOPIC]: MEETING_TOPIC,
  [checkpointNames.ADD_HOST]: ADD_HOST,
  [checkpointNames.ADD_SPEAKER]: ADD_SPEAKER,
  [checkpointNames.SET_DATE]: SET_DATE,
  //[checkpointNames.SET_DISCUSSION_PTS]: SET_DISCUSSION_PTS,
};

export const soloSessionCreationKeys = {
  idea: 'idea',
  profile: 'profile',
  blogPost: 'blogPost',
  manual: 'manual',
};
