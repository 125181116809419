import React from 'react';

export const TwitterIcon = ({ width = '16px', height = '17px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12190_59717)">
      <path
        d="M8 16.75C12.4183 16.75 16 13.1683 16 8.75C16 4.33172 12.4183 0.75 8 0.75C3.58172 0.75 0 4.33172 0 8.75C0 13.1683 3.58172 16.75 8 16.75Z"
        fill="#2DAAE1"
      />
      <path
        d="M13.1294 5.56841C12.7521 5.73585 12.3468 5.84905 11.9207 5.89993C12.3556 5.6395 12.6888 5.22681 12.8461 4.73565C12.4329 4.98102 11.9808 5.15376 11.5093 5.24637C11.1256 4.83738 10.5785 4.58203 9.97312 4.58203C8.81121 4.58203 7.86889 5.52397 7.86889 6.68656C7.86889 6.85129 7.88745 7.01199 7.92311 7.16629C6.17401 7.07838 4.62331 6.24054 3.58535 4.96744C3.40411 5.27837 3.30036 5.63988 3.30036 6.02559C3.30036 6.75564 3.67195 7.39977 4.23657 7.77709C3.90232 7.76673 3.57541 7.67651 3.28317 7.51397V7.54056C3.28317 8.56033 4.00849 9.41064 4.97132 9.60402C4.79445 9.65248 4.60883 9.6778 4.41681 9.6778C4.28104 9.6778 4.14931 9.66495 4.02064 9.64075C4.28845 10.4766 5.06569 11.085 5.98635 11.1022C5.26608 11.6668 4.35851 12.0034 3.37279 12.0034C3.20301 12.0034 3.03521 11.9932 2.87048 11.9734C3.80199 12.5707 4.908 12.9194 6.09653 12.9194C9.96739 12.9194 12.0844 9.71252 12.0844 6.9315C12.0844 6.8402 12.0824 6.74958 12.078 6.65929C12.4904 6.36095 12.8465 5.99156 13.1294 5.56841Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12190_59717">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.75)"
        />
      </clipPath>
    </defs>
  </svg>
);
