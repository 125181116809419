import { useUserWorkspaces } from './useUserWorkspaces';
import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useClickOutside } from '../helper/useClickOutside';
import { useSelectDefaultWorkspace } from './useSelectDefaultWorkspace';
import { PLANS } from 'zync-common/zyncCustomerPlans';

export const useWorkspaceSelector = ({ onAfterSelect = () => {} } = {}) => {
  const {
    currentWorkspaceName,
    currentWorkspaceId,
    userWorkspaces,
    switchWorkspace,
    currentWorkspace,
  } = useUserWorkspaces();

  const workspaceMenuRef = useRef(null);

  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showCreateWorkspaceModal, setShowCreateWorkspaceModal] =
    useState(false);

  const handleDropdownMenuClick = () => {
    setIsExpanded((wasExpanded) => !wasExpanded);
  };

  const selectWorkspace = useCallback(
    async (workspace, userWorkspaces) => {
      const { workspaceId } = workspace;
      onAfterSelect();
      await switchWorkspace(workspaceId, userWorkspaces, true);
      setIsExpanded(false);
      const isSoloWorkspace = workspace?.workspacePlan === PLANS.solo.id;
      const urlToRedirect = isSoloWorkspace
        ? '/solo?workspaceId=' + workspaceId
        : '/portal?workspaceId=' + workspaceId;
      history.push(urlToRedirect);
    },
    [onAfterSelect, switchWorkspace, history, setIsExpanded]
  );

  const handleWorkspaceCreated = useCallback(
    (workspace) => {
      setShowCreateWorkspaceModal(false);
      setIsExpanded(false);
      window.location.reload(false);
      onAfterSelect();
    },
    [onAfterSelect, setShowCreateWorkspaceModal, setIsExpanded]
  );

  useClickOutside(workspaceMenuRef, () => {
    setIsExpanded(false);
    onAfterSelect();
  });

  /** used for side effect for selecting default workspace if there is no workspace selected yet */
  useSelectDefaultWorkspace(currentWorkspaceId);

  return {
    currentWorkspaceId,
    isExpanded,
    showCreateWorkspaceModal,
    setShowCreateWorkspaceModal,
    handleDropdownMenuClick,
    handleWorkspaceCreated,
    selectWorkspace,
    currentWorkspaceName,
    userWorkspaces,
    workspaceMenuRef,
    currentWorkspace,
  };
};
