import React, { useEffect, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { usePortal } from '../hooks/usePortal';
import { getHomeUrl, isMobile } from '../helper';
import { LogoSpinnerFullCentered } from '../components/LogoSpinner';
import {
  FuturisticBackground,
  IsometricShapesDecoration,
} from '../components/FuturisticBackground';
import { HomeNavbar } from './Navbar/HomeNavbar';
import { Button } from '../components/common/Button';
import { useSelectDefaultWorkspace } from '../hooks/useSelectDefaultWorkspace';
import { CreateNewEventModal } from './Portal/CreateNewEventModal';
import { isDefaultBrandKit } from 'zync-common/helper/brandKit';
import { DesktopOnlyModal } from '../components/DesktopOnlyModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SettingsIcon } from '../components/icons/SettingsIcon';
import { lightenOrDarkenColor } from '../helper/lightenOrDarkenColor';
import { MovieFilmIcon } from '../components/icons/MovieFilmIcon';
import { Notification, Notifications } from '../components/Notifications';
import { PrivacyPolicyConsentModal } from '../components/PrivacyPolicyConsentModal';
import { episodeTypes } from 'zync-common/types';
import { SoloEpisodeCard } from './Portal/SoloEpisodeCard';
import {
  checkIsCompletedLater,
  checkIsCompletedRecently,
  checkIsIncomplete,
  checkIsSoloEpisode,
  checkIsUpcoming,
  Container,
} from './Portal/shared';
import { NewSoloSessionSelectionModal } from './Portal/NewSoloSessionSelectionModal';
import { logerror } from '../helper/contextualLogger';
import { soloSessionCreationKeys } from './Portal/constants';
import { LoadingSoloEpisodeCard } from './Portal/LoadingSoloEpisodeCard';
import { updateCustomerEngagementStagesApi } from '../helper/api';
import zyncCustomerEngagementStages from 'zync-common/hubspot/zyncCustomerEngagementStages';

export const SoloPortal = () => {
  const {
    //authMode,
    authenticated,
    isWorkspaceOnboardingRequired,
    redirectToLanding,
    seriesList,
    seriesListLoading,
    //setRedirectToLanding,
    //isAdmin,
    brandKit,
    workspaceId,
    workspacePlan,
    currentWorkspaceName,
    isTrial,
    plan,
    show,
    soloSettings,
    workspacePlanValidUntil,
    currentSubscriptionRenewalTime,
    showPrivacyPolicyModal,
    setShowPrivacyPolicyModal,
    creatorUserId,
    fetchNewMeetings,
  } = usePortal();

  const history = useHistory();

  const [isNewManualSoloSessionModalOpen, setIsNewManualSoloSessionModalOpen] =
    useState(false);

  const [
    isNewBlogPostBasedSoloSessionModalOpen,
    setIsNewBlogPostBasedSoloSessionModalOpen,
  ] = useState(false);

  const [
    isNewIdeaBasedSoloSessionModalOpen,
    setIsNewIdeaBasedSoloSessionModalOpen,
  ] = useState(false);

  const [
    isNewProfileBasedSoloSessionModalOpen,
    setIsNewProfileBasedSoloSessionModalOpen,
  ] = useState(false);

  const [
    isNewSoloSessionSelectionModalOpen,
    setIsNewSoloSessionSelectionModalOpen,
  ] = useState(false);

  const [showDesktopOnlyModal, setShowDesktopOnlyModal] = useState(false);

  const getDesktopOnlyModalMessage = () => {
    switch (showDesktopOnlyModal) {
      case 'eventPage': {
        return 'The event page is only accessible from a desktop device.';
      }
      case 'eventCreation':
      default: {
        return 'New event creation can only happen from a desktop device.';
      }
    }
  };

  useSelectDefaultWorkspace(workspaceId);

  const showName = show?.name;
  useEffect(() => {
    document.title = `Zync ${showName || currentWorkspaceName || ''}`;
    return () => {
      document.title = `Zync`;
    };
  }, [showName, currentWorkspaceName]);

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const templateKey = urlSearchParams.get('templateKey');
  const invite = urlSearchParams.get('invite');

  useEffect(() => {
    mixpanel.track('Workspace Portal - Page Visit');
  }, []);

  useEffect(() => {
    if (seriesList.length === 0 || seriesListLoading || !creatorUserId) return;
    const completedSeriesList = seriesList.filter(
      (s) => checkIsCompletedLater(s) || checkIsCompletedRecently(s)
    );
    if (completedSeriesList.length >= 3) {
      updateCustomerEngagementStagesApi(
        creatorUserId,
        zyncCustomerEngagementStages.COMPLETED_3X_SESSIONS.stage
      );
    }
  }, [seriesListLoading, seriesList, creatorUserId]);

  if (isWorkspaceOnboardingRequired) {
    return (
      <Redirect
        to={`/solostudiowelcome?templateKey=${templateKey}${
          invite === 'true' ? '&invite=true' : ''
        }`}
      />
    );
  }

  if (redirectToLanding) {
    return <Redirect to={getHomeUrl()} />;
  }

  const filteredSeriesList = seriesList.reduce(
    (filteredList, episode) => {
      switch (true) {
        case checkIsSoloEpisode(episode): {
          filteredList.soloEpisodes.push(episode);
          break;
        }

        case checkIsCompletedRecently(episode): {
          filteredList.recentlyCompletedEpisodes.push(episode);
          break;
        }

        case checkIsCompletedLater(episode): {
          filteredList.completedEpisodes.push(episode);
          break;
        }

        case checkIsUpcoming(episode): {
          filteredList.upcomingEpisodes.push(episode);
          break;
        }

        case checkIsIncomplete(episode): {
          filteredList.incompleteEpisodes.push(episode);
          break;
        }

        default: {
          console.warn('We could not assign this episode', episode);
          break;
        }
      }

      return filteredList;
    },
    {
      recentlyCompletedEpisodes: [],
      completedEpisodes: [],
      incompleteEpisodes: [],
      upcomingEpisodes: [],
      soloEpisodes: [],
    }
  );

  const { soloEpisodes } = filteredSeriesList;

  const defaultSpeaker = soloSettings?.defaultSpeaker;
  const hasDefaultSpeaker = Boolean(defaultSpeaker);

  return (
    <>
      {showPrivacyPolicyModal && (
        <PrivacyPolicyConsentModal
          setShowPrivacyPolicyModal={setShowPrivacyPolicyModal}
        />
      )}
      {showDesktopOnlyModal && (
        <DesktopOnlyModal
          message={getDesktopOnlyModalMessage()}
          onClose={() => setShowDesktopOnlyModal(false)}
        />
      )}
      <FuturisticBackground
        showDecorations={false}
        showGradient={false}
        showIsometricLinesDecoration={false}
        topGradientOpacity={FuturisticBackground.topGradientOpacity.LOW}
      />
      <HomeNavbar
        isAuthenticated={authenticated}
        workspaceId={workspaceId}
        isTrial={isTrial}
        plan={plan}
        trialUntil={workspacePlanValidUntil}
        workspacePlanValidUntil={workspacePlanValidUntil}
        currentSubscriptionRenewalTime={currentSubscriptionRenewalTime}
      />
      {seriesListLoading && !brandKit?.primaryColor ? (
        <div className="h-[90vh] grid place-content-center">
          <LogoSpinnerFullCentered />
        </div>
      ) : (
        <div>
          <div className="relative">
            <Container>
              {defaultSpeaker && (
                <div
                  className="relative isolate min-h-[250px] flex flex-col justify-center rounded-[25px] overflow-hidden pb-12 shadow-800"
                  style={{
                    backgroundImage: `linear-gradient(${lightenOrDarkenColor(
                      brandKit?.primaryColor,
                      20
                    )} 1px, transparent 1px), linear-gradient(to right, ${lightenOrDarkenColor(
                      brandKit?.primaryColor,
                      20
                    )} 1px, ${brandKit.primaryColor} 1px)`,
                    backgroundSize: '40px 40px',
                    backgroundPositionY: '20px',
                  }}
                >
                  <div className="absolute right-4 top-4 z-10">
                    <Link
                      to={`/workspace/${encodeURIComponent(
                        'solo studio settings'
                      )}?workspaceId=${workspaceId}`}
                    >
                      <span className="sr-only">
                        Click to open solo studio settings
                      </span>
                      <SettingsIcon
                        className="fill-white"
                        style={{
                          fill: brandKit.accentContrast,
                        }}
                      />
                    </Link>
                  </div>
                  <h1
                    className="font-jakarta font-bold z-10 text-[28px] md:text-[48px] leading-[110%] md:max-w-[70%] md:ml-14 text-center md:text-left line-clamp-2 mb-1"
                    style={{
                      color: brandKit.primaryContrast,
                    }}
                  >
                    {defaultSpeaker.fullName}
                  </h1>
                  {currentWorkspaceName && (
                    <h2
                      className="font-inter text-2xl font-bold md:ml-14 max-w-[80%]"
                      style={{
                        color: brandKit.primaryContrast,
                      }}
                    >
                      {currentWorkspaceName}
                    </h2>
                  )}
                  <div
                    className="absolute h-[75%] aspect-square rounded-full -right-10 top-[-10%]"
                    style={{
                      backgroundColor: brandKit.accentColor,
                    }}
                  />
                  <div
                    className="absolute h-[30%] aspect-square rounded-full right-28 top-[60%]"
                    style={{
                      backgroundColor: brandKit.accentColor,
                    }}
                  />
                  <div
                    className="absolute h-[60%] aspect-square rounded-full -right-8 top-[75%]"
                    style={{
                      backgroundColor: brandKit.accentColor,
                    }}
                  />
                  {isMobile ? (
                    <div className="h-[40px] absolute self-center md:self-start bottom-4 flex mx-auto md:ml-20 md:mr-0 bg-white text-[#696F8C] font-medium w-fit rounded-full border border-[#D0D5DD]">
                      <button
                        onClick={() => history.push(`${workspaceId}/videofeed`)}
                        className="flex gap-2 items-center px-2 py-1 whitespace-nowrap text-sm"
                      >
                        <MovieFilmIcon
                          width="30px"
                          height="30px"
                          color="#696F8C"
                        />
                        Highlight Reel
                      </button>
                    </div>
                  ) : (
                    <div className="absolute self-center md:self-start bottom-8 flex mx-auto md:ml-14 md:mr-0 bg-white text-[#696F8C] font-medium w-fit rounded-full border border-[#D0D5DD]">
                      <button
                        onClick={() => history.push(`${workspaceId}/videofeed`)}
                        className="flex gap-2 items-center px-2 py-1"
                      >
                        <MovieFilmIcon
                          width="20px"
                          height="20px"
                          color="#696F8C"
                        />
                        Highlight Reel
                      </button>
                      {/*<div className="h-[32px] w-[1px] bg-[#D0D5DD] mx-2" />
                      <button
                        disabled
                        className="flex gap-2 items-center px-2 py-1 opacity-50"
                      >
                        <DiscussionPointIcon
                          width="20px"
                          height="20px"
                          className="fill-[#696F8C]"
                        />
                        Manage Topics
                      </button>*/}
                    </div>
                  )}
                </div>
              )}
              <Notifications
                render={[
                  {
                    component: (
                      <Notification
                        key={1}
                        title={
                          <Link to="/workspace/settings">
                            SETUP YOUR BRAND KIT
                          </Link>
                        }
                        message={`You will have to setup your brand kit before running your first episode.${
                          isDefaultBrandKit(brandKit)
                            ? ' You are now using default Zync brand kit settings'
                            : ''
                        }`}
                      />
                    ),
                    when: isDefaultBrandKit(brandKit),
                  },
                ]}
              />
              <div className="flex justify-end items-center mb-4 gap-4">
                <div className="flex gap-2">
                  <Button
                    padding={Button.padding.MEDIUM}
                    color={Button.colors.PURPLE}
                    onClick={() => {
                      mixpanel.track('Portal: New Studio Session');
                      setIsNewSoloSessionSelectionModalOpen(true);
                    }}
                  >
                    <span className="text-sm">+ New Studio Session</span>
                  </Button>
                </div>
              </div>
              {seriesListLoading ? (
                <LogoSpinnerFullCentered />
              ) : (
                <div className="mb-4">
                  <div className="bg-purple/10 rounded-tr-xl rounded-tl-xl p-6">
                    <h2 className="text-base font-semibold font-jakarta">
                      Your Studio Sessions
                    </h2>
                  </div>
                  <div className="flex flex-col">
                    {soloEpisodes.map((series, i) =>
                      series.settings.episodeType ===
                      episodeTypes.placeholder ? (
                        <LoadingSoloEpisodeCard key={series.meetingSeriesId} />
                      ) : (
                        <SoloEpisodeCard
                          key={series.meetingSeriesId}
                          series={series}
                          setShowDesktopOnlyModal={setShowDesktopOnlyModal}
                        />
                      )
                    )}
                  </div>
                </div>
              )}
            </Container>
          </div>
        </div>
      )}
      {isNewSoloSessionSelectionModalOpen && (
        <NewSoloSessionSelectionModal
          disabled={!hasDefaultSpeaker}
          onClose={() => setIsNewSoloSessionSelectionModalOpen(false)}
          onConfirm={(soloSessionCreationKey) => {
            switch (soloSessionCreationKey) {
              case soloSessionCreationKeys.idea: {
                mixpanel.track('Portal: New Session - From Idea');
                setIsNewIdeaBasedSoloSessionModalOpen(true);
                break;
              }
              case soloSessionCreationKeys.profile: {
                setIsNewProfileBasedSoloSessionModalOpen(true);
                break;
              }
              case soloSessionCreationKeys.blogPost: {
                mixpanel.track('Portal: New Session - From Article');
                setIsNewBlogPostBasedSoloSessionModalOpen(true);
                break;
              }
              case soloSessionCreationKeys.manual: {
                mixpanel.track('Portal: New Session - From Prompts');
                setIsNewManualSoloSessionModalOpen(true);
                break;
              }
              default: {
                logerror({
                  message: `soloSessionCreationKey ${soloSessionCreationKey} not found. Did you add a new solo session creation key but did not update the onConfirm handler?`,
                });
              }
            }
          }}
        />
      )}
      {isNewManualSoloSessionModalOpen && (
        <CreateNewEventModal
          onClose={() => setIsNewManualSoloSessionModalOpen(false)}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.solo}
        />
      )}
      {isNewBlogPostBasedSoloSessionModalOpen && (
        <CreateNewEventModal
          onClose={() => {
            setIsNewBlogPostBasedSoloSessionModalOpen(false);
            fetchNewMeetings();
          }}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.soloBlogBased}
        />
      )}
      {isNewProfileBasedSoloSessionModalOpen && (
        <CreateNewEventModal
          onClose={() => {
            setIsNewProfileBasedSoloSessionModalOpen(false);
            fetchNewMeetings();
          }}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.soloProfileBased}
        />
      )}
      {isNewIdeaBasedSoloSessionModalOpen && (
        <CreateNewEventModal
          onClose={() => {
            setIsNewIdeaBasedSoloSessionModalOpen(false);
            fetchNewMeetings();
          }}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.soloIdeaBased}
        />
      )}
      <IsometricShapesDecoration className="absolute left-0 w-full object-cover" />
    </>
  );
};
