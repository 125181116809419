const { cloneDeep } = require('lodash');
const { DynamicBlocks } = require('../helper/overrideDynamicValue');

const makeRandomId = (length) => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const buildNewBlockId = (blockId) =>
  [blockId.split('-')[0], makeRandomId(8)].join('-');

const createNewPresenterSlide = (presenterTemplate, presenter) => {
  const newPresenterSlide = cloneDeep(presenterTemplate);

  newPresenterSlide.sceneId = makeRandomId(8);

  newPresenterSlide.slideName = `Speaker Intro: ${
    presenter.name || presenter.fullName
  }`;
  newPresenterSlide.title = `Speaker Intro: ${
    presenter.name || presenter.fullName
  }`;

  newPresenterSlide.slideConfig.slideBlocks =
    newPresenterSlide.slideConfig.slideBlocks.map((block) => ({
      ...block,
      blockInstanceId: buildNewBlockId(block.blockInstanceId || block.blockId),
    }));

  const speakerVideoBlock = newPresenterSlide.slideConfig.slideBlocks.find(
    (block) =>
      block.blockId === 'singlevideo' && block.settings.role === 'speaker'
  );

  if (speakerVideoBlock) {
    speakerVideoBlock.settings.userId = presenter.emailAddress;
  }

  const newBlocks = new DynamicBlocks(
    newPresenterSlide.slideConfig.slideBlocks
  );

  newBlocks.replaceWith('{speaker_1_name}', `{${presenter._id}_name}`);
  newBlocks.replaceWith('{speaker_1_bio}', `{${presenter._id}_bio}`);

  newPresenterSlide.slideConfig.slideBlocks = newBlocks.getAll();

  return newPresenterSlide;
};

module.exports = {
  createNewPresenterSlide,
};
