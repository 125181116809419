import React from 'react';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';

const LOGO_SPINNER_SIZE = {
  sm: 'sm',
  md: 'md',
};

const LOGO_SPINNER_SIZE_CLASSNAMES = {
  sm: 'w-14',
  md: 'w-20',
};

export const LogoSpinner = ({ size = LOGO_SPINNER_SIZE.md }) => {
  return (
    <img
      src={getStaticAssetUrl('logo-spinner.gif')}
      className={LOGO_SPINNER_SIZE_CLASSNAMES[size]}
      alt="logo spinner"
    />
  );
};

export const LogoSpinnerFullCentered = ({ size = LOGO_SPINNER_SIZE.md }) => {
  return (
    <div className="flex justify-center w-full">
      <LogoSpinner size={size} />
    </div>
  );
};

LogoSpinner.size = LOGO_SPINNER_SIZE;
