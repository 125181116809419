import { WelcomeAvatarUploader } from './WelcomeAvatarUploader';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateUserProfileApi } from '../../helper/api';
import { fromError, logerror } from '../../helper/contextualLogger';

export const updateUserProfile = async (userId, userProfile, cb) => {
  try {
    await updateUserProfileApi(userId, userProfile);
    cb(userProfile);
  } catch (error) {
    logerror(fromError(error));
  }
};

export const WelcomeUserProfile = () => {
  const dispatch = useDispatch();

  const user = useSelector((_st) => _st.auth.user, shallowEqual);

  const refreshUser = (updatedProfile) => {
    dispatch({
      type: 'SET_USER',
      user: {
        ...user,
        ...updatedProfile,
      },
    });
  };

  return (
    <div className="flex w-full gap-6 items-center">
      <WelcomeAvatarUploader />
      <div className="flex flex-col gap-6 w-full">
        <input
          type="text"
          defaultValue={user.userName}
          onChange={(event) =>
            updateUserProfile(
              user.userId,
              {
                userName: event.currentTarget.value,
              },
              refreshUser
            )
          }
          className="w-full border border-[#EBEAED] px-3 py-2 md:text-base text-sm"
          maxLength={24}
        />
        <textarea
          rows={2}
          defaultValue={user.bio}
          onChange={(event) =>
            updateUserProfile(
              user.userId,
              {
                bio: event.currentTarget.value,
              },
              refreshUser
            )
          }
          placeholder="Headline e.g. CEO Acme Inc"
          className="w-full border border-[#EBEAED] px-3 py-2 md:text-base text-sm"
          maxLength={50}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
        />
      </div>
    </div>
  );
};
