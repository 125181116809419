import React from 'react';

import MessagePartials from './MessagePartials';

const MeetingEndingShortlyModerator = ({
  billingAdminEmail,
  plan,
  meetingCountdownProperties,
}) => {
  const { timeToEnd, showDynamicCountdown } = meetingCountdownProperties;
  const { name, maxMinutes } = plan;

  return (
    <div>
      <MessagePartials.YourMeetingIsEnding
        planName={name}
        timeToEnd={timeToEnd}
        maxMinutes={maxMinutes}
        showDynamicCountdown={showDynamicCountdown}
      />
      <MessagePartials.ContactYourAdmin billingAdminEmail={billingAdminEmail} />
    </div>
  );
};

export default MeetingEndingShortlyModerator;
