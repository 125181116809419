import { useEffect } from 'react';
import { useUserWorkspaces } from './useUserWorkspaces';
import {
  getWorkspaceIdFromStorage,
  getWorkspaceIdFromUrl,
} from './useTemplateCreation';

const getWorkspaceById = (workspaces, workspaceId) =>
  workspaces.find((w) => w.workspaceId === workspaceId);

/* side-effect hook used for selecting default workspace for user */
export const useSelectDefaultWorkspace = (workspaceId) => {
  const userWorkspacesResult = useUserWorkspaces({
    selectedWorkspaceId: workspaceId,
  });

  const {
    currentWorkspaceId,
    defaultWorkspace,
    isWorkspaceOnboardingRequired,
    switchWorkspace,
    userWorkspaces,
  } = userWorkspacesResult;

  const defaultWorkspaceId = defaultWorkspace?.workspaceId;

  useEffect(() => {
    if (!defaultWorkspace.workspaceId && currentWorkspaceId) {
      const queryParams = new URLSearchParams(window.location.search);
      const workspaceIdQueryParam = queryParams.get('workspaceId');
      const savedWorkspaceId = getWorkspaceIdFromStorage();

      // Select the current workspace from the URL param.
      let workspace = getWorkspaceById(
        userWorkspaces,
        workspaceIdQueryParam || savedWorkspaceId
      );

      if (workspace) {
        localStorage.setItem('defaultWorkspaceId', workspace.workspaceId);
      }
    }
  }, [
    switchWorkspace,
    userWorkspaces,
    defaultWorkspace.workspaceId,
    currentWorkspaceId,
  ]);

  // Set the current workspace if none is set.
  useEffect(() => {
    if (currentWorkspaceId || isWorkspaceOnboardingRequired) return;

    const queryParams = new URLSearchParams(window.location.search);
    const workspaceIdQueryParam = queryParams.get('workspaceId');

    // Select the current workspace from the URL param.
    let workspace = getWorkspaceById(userWorkspaces, workspaceIdQueryParam);

    if (workspace) {
      switchWorkspace(workspace.workspaceId, userWorkspaces, true);
      return;
    }

    // Otherwise, use the default workspace Id.
    workspace = getWorkspaceById(userWorkspaces, defaultWorkspaceId);
    if (workspace) {
      switchWorkspace(workspace.workspaceId, userWorkspaces, true);
      return;
    }

    // Otherwise, look for the last saved workspace
    workspace = getWorkspaceById(userWorkspaces, getWorkspaceIdFromStorage());
    if (workspace) {
      switchWorkspace(workspace.workspaceId, userWorkspaces, true);
      return;
    }

    // Otherwise, return the first workspace.
    if (userWorkspaces.length > 0) {
      switchWorkspace(userWorkspaces[0].workspaceId, userWorkspaces, true);
    }
  }, [
    currentWorkspaceId,
    defaultWorkspaceId,
    isWorkspaceOnboardingRequired,
    switchWorkspace,
    userWorkspaces,
  ]);

  return {
    currentWorkspaceFromUrl: userWorkspaces.find(
      (uw) => uw.workspaceId === getWorkspaceIdFromUrl()
    ),
  };
};
