import moment from 'moment-timezone';
import { timezones } from './timezones';
import { logerror } from './contextualLogger';

export const combineDayAndTime = (daySelected, time, timezone) => {
  const newTime = moment(time, 'h:mm a').format('HH:mm');
  const newDay = moment(daySelected, 'YYYY-MM-DD').format('YYYY-MM-DD');
  return moment.tz(newDay + ' ' + newTime, timezone);
};

export const getTimezoneIdentifier = (timezone) => {
  return (
    timezones.find((t) => t.value === timezone)?.utc[0] ||
    timezones.find((t) => t.utc.includes(timezone))?.utc[0] ||
    undefined
  );
};

export const getDatesInUTC = (daySelected, startTime, endTime, timezone) => {
  const timezoneIdentifier = getTimezoneIdentifier(timezone);
  const startDate = combineDayAndTime(
    daySelected,
    startTime,
    timezoneIdentifier
  ).utc();
  const endDate = combineDayAndTime(
    daySelected,
    endTime,
    timezoneIdentifier
  ).utc();
  const currentDate = moment().utc();

  return {
    startDate,
    endDate,
    currentDate,
  };
};

export const convertUTCToTimezone = (
  time,
  timezone,
  format,
  useString = false,
  useTzIdentifier
) => {
  const timezoneIdentifier = getTimezoneIdentifier(timezone);
  const convertedTime = moment(time).tz(
    !useString && useTzIdentifier ? timezoneIdentifier : timezone
  );

  if (!convertedTime) return 'Invalid date - Invalid date';

  if (format === moment) {
    return convertedTime;
  }

  switch (format) {
    case 'utc':
      return convertedTime.format();
    case 'date':
      return convertedTime.format('YYYY-MM-DD');
    case 'date-name':
      return convertedTime.format('Do, MMMM YYYY');
    case 'date-time':
      return convertedTime.format('MMM D, h:mm A');
    case 'time':
    default:
      return convertedTime.format('h:mm A');
  }
};

export const validateTimes = (currentDate, startDate, endDate) => {
  if (
    moment(endDate).isSameOrBefore(startDate) ||
    startDate.isBefore(currentDate) ||
    endDate.isBefore(currentDate)
  ) {
    return false;
  }

  return true;
};

export const getLocalTimezoneName = () => {
  const currentDate = moment();
  const timezone = moment.tz.guess();
  const abbr = moment.tz.zone(timezone).abbr(currentDate);
  // Some timezones have the same abbreviation so check both the name and abbreviation to match the correct value.
  const foundTimezone = timezones.find(
    (t) => t.abbr === abbr && t.utc.includes(timezone)
  )?.value;

  if (foundTimezone) {
    return foundTimezone;
  } else {
    logerror({
      message: `Error getting timezone. Timezone: ${timezone}. Abbreviation: ${abbr}`,
    });
    return 'Pacific Standard Time';
  }
};

export const getLocalTimezoneAreaName = () => {
  const timezone = moment.tz.guess();
  const zone = moment.tz.zone(timezone)?.name;
  // Some timezones have the same abbreviation so check both the name and abbreviation to match the correct value.

  if (zone) {
    return zone;
  } else {
    logerror({
      message: `Error getting timezone. Timezone: ${timezone}.`,
    });
    return 'Pacific Standard Time';
  }
};

export const getTimezoneAbbreviation = (timezone) => {
  return (
    timezones.find((t) => t.value === timezone)?.abbr ||
    timezones.find((t) => t.utc.includes(timezone))?.abbr ||
    'PST'
  );
};

export const convertISOStringToMS = (ISOString) =>
  new Date(ISOString).getTime();

export const getOneHourFromDate = (date) => {
  const inputDate = moment(date);
  const oneHourFromInput = inputDate.add(1, 'hour');
  return oneHourFromInput.toISOString();
};

export const halfOurFromNow = (date) => {
  const inputDate = moment(date);
  const oneHourFromInput = inputDate.add(30, 'minutes');
  return oneHourFromInput.toISOString();
};
