import React from 'react';
import { TextArea } from '../../components/common/TextArea';

export const AddIdea = ({ newBlogPost, setNewBlogPost }) => {
  return (
    <div className="w-full h-[225px]">
      <TextArea
        placeholder="Type your idea here"
        className="w-full !h-full"
        value={newBlogPost}
        onChange={setNewBlogPost}
      />
    </div>
  );
};
