import React from 'react';

export const SixDotsIcon = ({
  width = '8px',
  height = '14px',
  className = 'fill-gray',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="1.5" cy="2.49414" r="1.5" fill="#97A0AF" />
    <circle cx="6.5" cy="2.49414" r="1.5" fill="#97A0AF" />
    <circle cx="1.5" cy="7.49414" r="1.5" fill="#97A0AF" />
    <circle cx="6.5" cy="7.49414" r="1.5" fill="#97A0AF" />
    <circle cx="1.5" cy="12.4941" r="1.5" fill="#97A0AF" />
    <circle cx="6.5" cy="12.4941" r="1.5" fill="#97A0AF" />
  </svg>
);
