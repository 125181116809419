import { Button } from '../../../components/common/Button';
import {
  Modal,
  ModalBody,
  ModalTitle,
  ModalWindow,
} from '../../../components/Modal';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PlusIcon } from '../../../components/icons/PlusIcon';
import { fetchSceneTemplateApi } from '../../../helper/api';
import { PreviewSlidev2 } from '../../../components/PreviewSlidev2';
import { cloneDeep } from 'lodash';
import { presenterTypes } from '../../../components/series/settings/EventPresenters';
import { useDeepCompareMemo } from 'use-deep-compare';
import {
  getFakeHost,
  getFakeSpeakers,
} from '../../../components/authoring/MainContent';
import { DynamicBlocks } from '../../../blocks/helper/dynamicBlockVariables';
import { RefreshIcon } from '../../../components/icons/RefreshIcon';
import { useElementSize } from '../../../hooks/useElementSize';
import { DiscussionPointUpdate } from './quickScenesComponents/DiscussionPointUpdate';
import { WordCloudUpdate } from './quickScenesComponents/WordCloudUpdate';
import { OpinionPollUpdate } from './quickScenesComponents/OpinionPollUpdate';
import { ImageSceneUpdate } from './quickScenesComponents/ImageSceneUpdate';
import { VideoSceneUpdate } from './quickScenesComponents/VideoSceneUpdate';
import { TextSceneUpdate } from './quickScenesComponents/TextSceneUpdate';
import { SceneOfferUpdate } from './quickScenesComponents/SceneOfferUpdate';
import { NotSupportedPreviewComponent } from './quickScenesComponents/NotSupportedPreviewComponent';
import { HostNotes } from './quickScenesComponents/HostNotes';
import { ThisOrThatUpdate } from './quickScenesComponents/ThisOrThatUpdate';
import { SeriesInfoContext } from '../../../components/LiveMeeting';
import { DiscussionPointIcon } from '../../../components/icons/sceneBuilderIcons/DiscussionPointIcon';
import { OpinionPollIcon } from '../../../components/icons/sceneBuilderIcons/OpinionPollIcon';
import { WordCloudIcon } from '../../../components/icons/sceneBuilderIcons/WordCloudIcon';
import { PresentImageIcon } from '../../../components/icons/sceneBuilderIcons/PresentImageIcon';
import { PresentVideoIcon } from '../../../components/icons/sceneBuilderIcons/PresentVideoIcon';
import { PresentTextIcon } from '../../../components/icons/sceneBuilderIcons/PresentTextIcon';
import { ExclusiveOfferIcon } from '../../../components/icons/sceneBuilderIcons/ExclusiveOfferIcon';
import classNames from '../../../helper/classNames';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { SpeakerIntroIcon } from '../../../components/icons/sceneBuilderIcons/SpeakerIntroIcon';
import { AudienceQAIcon } from '../../../components/icons/sceneBuilderIcons/AudienceQAIcon';
import { ThisOrThatIcon } from '../../../components/icons/sceneBuilderIcons/ThisOrThatIcon';
import { ThankYouIcon } from '../../../components/icons/sceneBuilderIcons/ThankYouIcon';
import { getNoBgSpeakerImage } from 'zync-common/helper/overrideDynamicValue';
import { getUserNoBgImageFromUrl } from '../../UserProfile/AvatarUploader';
import { ChevronLeftIcon } from '../../../components/icons/ChevronLeftIcon';
import { SpeakerIntroUpdate } from './quickScenesComponents/SpeakerIntroUpdate';
import { SoloSegmentUpdate } from './quickScenesComponents/SoloSegment';
import { PLANS } from 'zync-common/zyncCustomerPlans';
import { checkIsSoloEpisode } from '../../Portal/shared';

export const templateKeys = {
  DISCUSSION_POINT: 'scene_discussion_point',
  DISCUSSION_POINT_2: 'scene_discussion_point_2',
  DISCUSSION_POINT_3: 'scene_discussion_point_3',
  DISCUSSION_POINT_NO_SPEAKER: 'scene_discussion_point_no_speaker',
  OPINION_POLL: 'scene_opinionpoll_host',
  WORD_CLOUD: 'scene_wordcloud_host',
  SCENE_TEXT_PRESENTATION: 'scene_text_presentation',
  SCENE_VIDEO_PRESENTATION: 'scene_video_presentation',
  SCENE_IMAGE_PRESENTATION: 'scene_image_presentation',
  SCENE_OFFER: 'scene_offer',
  THIS_OR_THAT_SPEAKER: 'scene_this_or_that',
  THIS_OR_THAT_NO_SPEAKER: 'scene_this_or_that_no_speaker',
  SCENE_SPEAKER_INTRO: 'scene_speaker_intro',
  SCENE_SPEAKER_INTRO_2: 'scene_speaker_intro_2',
  SCENE_TOPIC_INTRO: 'scene_topic_intro',
  SCENE_QA: 'scene_q&a',
  SCENE_QA_NO_SPEAKER: 'scene_q&a_no_speaker',
  SCENE_THANK_YOU: 'scene_thankyou',
  SCENE_THANK_YOU_NO_SPEAKER: 'scene_thankyou_no_speaker',
  SCENE_OPEN_DISCUSSION: 'scene_open_discussion',
  SOLO_SEGMENT_1: 'scene_solo_segment',
  SOLO_SEGMENT_2: 'scene_solo_segment_2',
  SOLO_SEGMENT_3: 'scene_solo_segment_3',
  SOLO_SEGMENT_4: 'scene_solo_segment_4',
  SOLO_SEGMENT_5: 'scene_solo_segment_5',
  SOLO_SEGMENT_6: 'scene_solo_segment_6',
};

const blueGradientBackgroundStyle =
  'linear-gradient(180deg, rgba(6, 109, 232, 0.04) 0%, rgba(6, 109, 232, 0.24) 100%), linear-gradient(0deg, rgba(6, 109, 232, 0.1), rgba(6, 109, 232, 0.1))';
const purpleGradientBackgroundStyle =
  'linear-gradient(180deg, rgba(139, 51, 247, 0.04) 0%, rgba(139, 51, 247, 0.24) 100%), linear-gradient(0deg, rgba(139, 51, 247, 0.1), rgba(139, 51, 247, 0.1))';

export const templateIdToIconMap = {
  [templateKeys.DISCUSSION_POINT_NO_SPEAKER]: DiscussionPointIcon,
  [templateKeys.DISCUSSION_POINT]: DiscussionPointIcon,
  [templateKeys.DISCUSSION_POINT_2]: DiscussionPointIcon,
  [templateKeys.DISCUSSION_POINT_3]: DiscussionPointIcon,
  [templateKeys.OPINION_POLL]: OpinionPollIcon,
  [templateKeys.WORD_CLOUD]: WordCloudIcon,
  [templateKeys.SCENE_IMAGE_PRESENTATION]: PresentImageIcon,
  [templateKeys.SCENE_VIDEO_PRESENTATION]: PresentVideoIcon,
  [templateKeys.SCENE_TEXT_PRESENTATION]: PresentTextIcon,
  [templateKeys.SCENE_OFFER]: ExclusiveOfferIcon,
  [templateKeys.THIS_OR_THAT_NO_SPEAKER]: ThisOrThatIcon,
  [templateKeys.THIS_OR_THAT_SPEAKER]: ThisOrThatIcon,
  [templateKeys.SCENE_SPEAKER_INTRO]: SpeakerIntroIcon,
  [templateKeys.SCENE_SPEAKER_INTRO_2]: SpeakerIntroIcon,
  [templateKeys.SCENE_QA]: AudienceQAIcon,
  [templateKeys.SCENE_QA_NO_SPEAKER]: AudienceQAIcon,
  [templateKeys.SCENE_TOPIC_INTRO]: DiscussionPointIcon,
  [templateKeys.SCENE_THANK_YOU_NO_SPEAKER]: ThankYouIcon,
  [templateKeys.SCENE_THANK_YOU]: ThankYouIcon,
  [templateKeys.SCENE_OPEN_DISCUSSION]: DiscussionPointIcon,
  [templateKeys.SOLO_SEGMENT_1]: DiscussionPointIcon,
  [templateKeys.SOLO_SEGMENT_2]: DiscussionPointIcon,
  [templateKeys.SOLO_SEGMENT_3]: DiscussionPointIcon,
  [templateKeys.SOLO_SEGMENT_4]: DiscussionPointIcon,
  [templateKeys.SOLO_SEGMENT_5]: DiscussionPointIcon,
  [templateKeys.SOLO_SEGMENT_6]: DiscussionPointIcon,
};

export const templateIdToTitleMap = {
  [templateKeys.DISCUSSION_POINT_NO_SPEAKER]: 'Discussion Point',
  [templateKeys.DISCUSSION_POINT]: 'Discussion Point',
  [templateKeys.OPINION_POLL]: 'Opinion Poll',
  [templateKeys.WORD_CLOUD]: 'Word Cloud',
  [templateKeys.SCENE_IMAGE_PRESENTATION]: 'Image Presentation',
  [templateKeys.SCENE_VIDEO_PRESENTATION]: 'Video Presentation',
  [templateKeys.SCENE_TEXT_PRESENTATION]: 'Text Presentation',
  [templateKeys.SCENE_OFFER]: 'Exclusive Offer',
  [templateKeys.THIS_OR_THAT_NO_SPEAKER]: 'This Or That',
  [templateKeys.THIS_OR_THAT_SPEAKER]: 'This Or That',
  [templateKeys.SCENE_SPEAKER_INTRO]: 'Speaker Intro',
  [templateKeys.SCENE_SPEAKER_INTRO_2]: 'Speaker Intro',
  [templateKeys.SCENE_QA]: 'Audience QA',
  [templateKeys.SCENE_QA_NO_SPEAKER]: 'Audience QA',
  [templateKeys.SCENE_TOPIC_INTRO]: 'Scene Topic',
  [templateKeys.SCENE_THANK_YOU_NO_SPEAKER]: 'Thank You',
  [templateKeys.SCENE_THANK_YOU]: 'Thank You',
  [templateKeys.SCENE_OPEN_DISCUSSION]: 'Open Discussion',
};

const noSpeakerScenes = [
  {
    label: 'Discussion Point',
    templateKey: templateKeys.DISCUSSION_POINT_NO_SPEAKER,
    Icon: DiscussionPointIcon,
    background: purpleGradientBackgroundStyle,
  },
  {
    label: 'Topic Intro',
    templateKey: templateKeys.SCENE_TOPIC_INTRO,
    Icon: SpeakerIntroIcon,
    background: purpleGradientBackgroundStyle,
  },
  {
    label: 'This Or That',
    templateKey: templateKeys.THIS_OR_THAT_NO_SPEAKER,
    Icon: ThisOrThatIcon,
    background: purpleGradientBackgroundStyle,
  },
  {
    label: 'Present Image',
    templateKey: templateKeys.SCENE_IMAGE_PRESENTATION,
    Icon: PresentImageIcon,
    background: purpleGradientBackgroundStyle,
  },
  {
    label: 'Present Video',
    templateKey: templateKeys.SCENE_VIDEO_PRESENTATION,
    Icon: PresentVideoIcon,
    background: purpleGradientBackgroundStyle,
  },
  {
    label: 'Present Text',
    templateKey: templateKeys.SCENE_TEXT_PRESENTATION,
    Icon: PresentTextIcon,
    background: purpleGradientBackgroundStyle,
  },
  {
    label: 'Opinion Poll',
    templateKey: templateKeys.OPINION_POLL,
    Icon: OpinionPollIcon,
    background: blueGradientBackgroundStyle,
  },
  {
    label: 'Word Cloud',
    templateKey: templateKeys.WORD_CLOUD,
    Icon: WordCloudIcon,
    background: blueGradientBackgroundStyle,
  },
  {
    label: 'Exclusive Offer',
    templateKey: templateKeys.SCENE_OFFER,
    Icon: ExclusiveOfferIcon,
    background: blueGradientBackgroundStyle,
  },
];

const speakerScenes = [
  {
    label: 'Discussion Point',
    templateKey: templateKeys.DISCUSSION_POINT,
    Icon: DiscussionPointIcon,
    background: purpleGradientBackgroundStyle,
  },
  {
    label: 'Topic Intro',
    templateKey: templateKeys.SCENE_TOPIC_INTRO,
    Icon: DiscussionPointIcon,
    background: purpleGradientBackgroundStyle,
  },
  {
    label: 'Speaker Intro',
    templateKey: templateKeys.SCENE_SPEAKER_INTRO_2,
    Icon: SpeakerIntroIcon,
    background: purpleGradientBackgroundStyle,
  },
  {
    label: 'This Or That',
    templateKey: templateKeys.THIS_OR_THAT_SPEAKER,
    Icon: ThisOrThatIcon,
    background: purpleGradientBackgroundStyle,
  },
  {
    label: 'Present Image',
    templateKey: templateKeys.SCENE_IMAGE_PRESENTATION,
    Icon: PresentImageIcon,
    background: purpleGradientBackgroundStyle,
  },
  {
    label: 'Present Video',
    templateKey: templateKeys.SCENE_VIDEO_PRESENTATION,
    Icon: PresentVideoIcon,
    background: purpleGradientBackgroundStyle,
  },
  {
    label: 'Present Text',
    templateKey: templateKeys.SCENE_TEXT_PRESENTATION,
    Icon: PresentTextIcon,
    background: purpleGradientBackgroundStyle,
  },
  {
    label: 'Opinion Poll',
    templateKey: templateKeys.OPINION_POLL,
    Icon: OpinionPollIcon,
    background: blueGradientBackgroundStyle,
  },
  {
    label: 'Word Cloud',
    templateKey: templateKeys.WORD_CLOUD,
    Icon: WordCloudIcon,
    background: blueGradientBackgroundStyle,
  },
  {
    label: 'Exclusive Offer',
    templateKey: templateKeys.SCENE_OFFER,
    Icon: ExclusiveOfferIcon,
    background: blueGradientBackgroundStyle,
  },
  {
    label: 'Open Discussion',
    templateKey: templateKeys.SCENE_OPEN_DISCUSSION,
    Icon: SpeakerIntroIcon,
    background: purpleGradientBackgroundStyle,
  },
];

const businessPlanScenes = [
  {
    label: 'Solo Segment',
    templateKey: templateKeys.SOLO_SEGMENT_5,
    Icon: SpeakerIntroIcon,
    background: purpleGradientBackgroundStyle,
  },
];

const soloPlanScenes = [
  {
    label: 'Add Prompt',
    templateKey: templateKeys.SOLO_SEGMENT_5,
    Icon: SpeakerIntroIcon,
    background: purpleGradientBackgroundStyle,
  },
];

const adminPlanScenes = [
  {
    label: 'Solo Segment',
    templateKey: templateKeys.SOLO_SEGMENT_5,
    Icon: SpeakerIntroIcon,
    background: purpleGradientBackgroundStyle,
  },
];

const updateComponents = {
  [templateKeys.DISCUSSION_POINT_NO_SPEAKER]: DiscussionPointUpdate,
  [templateKeys.DISCUSSION_POINT]: DiscussionPointUpdate,
  [templateKeys.DISCUSSION_POINT_2]: DiscussionPointUpdate,
  [templateKeys.DISCUSSION_POINT_3]: DiscussionPointUpdate,
  [templateKeys.OPINION_POLL]: OpinionPollUpdate,
  [templateKeys.WORD_CLOUD]: WordCloudUpdate,
  [templateKeys.SCENE_IMAGE_PRESENTATION]: ImageSceneUpdate,
  [templateKeys.SCENE_VIDEO_PRESENTATION]: VideoSceneUpdate,
  [templateKeys.SCENE_TEXT_PRESENTATION]: TextSceneUpdate,
  [templateKeys.SCENE_OFFER]: SceneOfferUpdate,
  [templateKeys.THIS_OR_THAT_NO_SPEAKER]: ThisOrThatUpdate,
  [templateKeys.THIS_OR_THAT_SPEAKER]: ThisOrThatUpdate,
  [templateKeys.SCENE_SPEAKER_INTRO]: SpeakerIntroUpdate,
  [templateKeys.SCENE_SPEAKER_INTRO_2]: SpeakerIntroUpdate,
  [templateKeys.SOLO_SEGMENT_1]: SoloSegmentUpdate,
  [templateKeys.SOLO_SEGMENT_2]: SoloSegmentUpdate,
  [templateKeys.SOLO_SEGMENT_3]: SoloSegmentUpdate,
  [templateKeys.SOLO_SEGMENT_4]: SoloSegmentUpdate,
  [templateKeys.SOLO_SEGMENT_5]: SoloSegmentUpdate,
  [templateKeys.SOLO_SEGMENT_6]: SoloSegmentUpdate,
};

const VariantsSelector = ({
  variants,
  setUpdate,
  isLoading,
  handleUpdateSlideVariant,
  setTimelineItemSelected,
  posterSlideTemplateProps,
  setPosterSlideTemplate,
}) => {
  const getCurrentIndex = () =>
    variants.findIndex(
      (templateKey) => templateKey === posterSlideTemplateProps.sceneTemplateKey
    );

  const getCurrentTemplateKey = (variantIndex) => {
    const templateKey = variants[variantIndex];

    if (templateKey) {
      return templateKey;
    }
  };

  const [isTemplateChanging, setIsTemplateChanging] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(getCurrentIndex());

  const handleSwitchTemplate = async (sceneTemplateId, oldTemplate) => {
    if (isLoading) {
      return;
    }

    const updatedSeries = await handleUpdateSlideVariant(
      sceneTemplateId,
      'What are your top predictions in this area for the next 6 months ?',
      posterSlideTemplateProps.sceneIndex,
      oldTemplate
    );

    const updatedSlide = {
      ...updatedSeries?.autoLaunchConfig?.slides[
        posterSlideTemplateProps.sceneIndex
      ],
      blocks:
        updatedSeries?.autoLaunchConfig?.slides[
          posterSlideTemplateProps.sceneIndex
        ]?.slideConfig?.slideBlocks,
    };

    const newTimelineItem = {
      ...updatedSlide,
      sceneIndex: posterSlideTemplateProps.sceneIndex,
    };

    setTimelineItemSelected(newTimelineItem);
    setPosterSlideTemplate(newTimelineItem);

    setUpdate((x) => x + 1);
  };

  const handleSelectNextTemplate = async () => {
    if (isLoading || isTemplateChanging) {
      return;
    }

    setIsTemplateChanging(true);

    const nextIndex =
      currentIndex + 1 >= variants.length ? 0 : currentIndex + 1;
    setCurrentIndex(nextIndex);

    await handleSwitchTemplate(
      getCurrentTemplateKey(nextIndex),
      posterSlideTemplateProps
    );

    setIsTemplateChanging(false);
  };

  const handleSelectPrevTemplate = async () => {
    if (isLoading || isTemplateChanging) {
      return;
    }

    setIsTemplateChanging(true);

    const prevIndex =
      currentIndex - 1 < 0 ? variants.length - 1 : currentIndex - 1;
    setCurrentIndex(prevIndex);

    await handleSwitchTemplate(
      getCurrentTemplateKey(prevIndex),
      posterSlideTemplateProps
    );

    setIsTemplateChanging(false);
  };

  return (
    <div className="flex gap-2 p-1">
      <div className="flex gap-3 items-center">
        <button
          className="p-2 border border-gray rounded-md"
          onClick={handleSelectPrevTemplate}
        >
          <ChevronLeftIcon />
        </button>
        <span className="inline-block w-[45px] text-center text-[15px] text-blue-gray">
          {currentIndex + 1} of {variants.length}
        </span>
        <button
          className="rotate-180 p-2 border border-gray rounded-md"
          onClick={handleSelectNextTemplate}
        >
          <ChevronLeftIcon />
        </button>
      </div>
    </div>
  );
};
export const CreateSceneModal = ({
  brandKit,
  templateKey,
  title,
  meetingSeriesTitle,
  updateOnClose = true,
  onCancel,
  onConfirm,
  posterSlideTemplateProps = null,
  isLoading,
  series,
  onNoteChange,
  variants = [],
  handleUpdateSlideVariant,
  setTimelineItemSelected,
}) => {
  const [posterSlideTemplate, setPosterSlideTemplate] = useState(null);

  const [update, setUpdate] = useState(0);

  const handleChangeScene = useCallback(() => {
    if (!templateKey) {
      return;
    }

    if (posterSlideTemplateProps) {
      setPosterSlideTemplate(cloneDeep(posterSlideTemplateProps));
      return;
    }

    fetchSceneTemplateApi(templateKey).then((result) => {
      setPosterSlideTemplate(result);
    });
  }, [posterSlideTemplateProps, templateKey]);

  useEffect(() => {
    handleChangeScene();
  }, [handleChangeScene]);

  const UpdateComponent =
    updateComponents[templateKey] || NotSupportedPreviewComponent;

  const containerRef = useRef(null);

  const host =
    series?.settings?.eventPresenters?.find(
      (eventPresenter) => eventPresenter.type === presenterTypes.HOST
    ) || [];

  const speakers =
    series?.settings?.eventPresenters?.filter(
      (eventPresenter) => eventPresenter.type === presenterTypes.SPEAKER
    ) || [];

  const fakeHost = getFakeHost(host);
  const fakeSpeakers = getFakeSpeakers(speakers);

  const fakeUsers = useDeepCompareMemo(() => {
    return [fakeHost, ...fakeSpeakers];
  }, [speakers]);

  const { width, ref: posterSlideRef } = useElementSize();

  const changedBlocks = new DynamicBlocks(posterSlideTemplate?.blocks || []);

  changedBlocks.replaceWith('{event_title}', meetingSeriesTitle);

  const sceneUpdateWidth = width * (2 / 5);
  const scenePreviewWidth = width * (3 / 5);
  const scenePreviewHeight = (scenePreviewWidth * 9) / 16; // 16:9 aspect ratio

  return (
    <ModalWindow
      size={Modal.size.xxl}
      showCancel={false}
      overflow="overflow-auto"
    >
      <div ref={posterSlideRef}>
        <div ref={containerRef}>
          <ModalTitle>{posterSlideTemplate?.slideName || title}</ModalTitle>
          <ModalBody>
            <div className={`flex gap-8 justify-between`}>
              <div
                className={isLoading ? 'opacity-70 pointer-events-none' : ''}
                style={{
                  minWidth: 0,
                  maxWidth: sceneUpdateWidth,
                  width: sceneUpdateWidth,
                }}
              >
                {posterSlideTemplate && (
                  <UpdateComponent
                    posterSlideTemplate={posterSlideTemplate}
                    setPosterSlideTemplate={setPosterSlideTemplate}
                    setUpdate={setUpdate}
                    onConfirm={onConfirm}
                    updateOnClose={updateOnClose}
                    onCancel={onCancel}
                    meetingSeriesTitle={meetingSeriesTitle}
                    series={series}
                    isLoading={isLoading}
                    hostNotesComponent={
                      <HostNotes
                        defaultValue={posterSlideTemplate.hostNote}
                        isLoading={isLoading}
                        onChange={(event) =>
                          onNoteChange(posterSlideTemplate, event.target.value)
                        }
                      />
                    }
                  />
                )}
              </div>
              <div className="shrink-0" style={{ width: scenePreviewWidth }}>
                {posterSlideTemplate && (
                  <div className="border border-gray rounded relative">
                    <SeriesInfoContext.Provider
                      value={{
                        accentColor: brandKit.accentColor,
                        accentContrast: brandKit.accentContrast,
                        logo: brandKit.logoUrl,
                        seriesTitle: series?.title,
                        showName: series?.workspace?.show?.name,
                        primaryContrast: brandKit.primaryContrast,
                        primaryColor: brandKit.primaryColor,
                        episodeType: series?.settings?.episodeType,
                      }}
                    >
                      <PreviewSlidev2
                        key={update}
                        width={scenePreviewWidth}
                        height={scenePreviewHeight}
                        posterSlideTemplate={{
                          ...posterSlideTemplate,
                          blocks: changedBlocks.getAll(),
                        }}
                        brandKit={brandKit}
                        users={fakeUsers}
                        variablesToStaticMap={[
                          ['{host_name}', fakeHost.userName],
                          ['{host_bio}', fakeHost.jobTitle],
                          ['{host_image}', fakeHost.avatarUrl],
                          [
                            '{host_image_no_bg}',
                            getUserNoBgImageFromUrl(fakeHost.avatarUrl),
                          ],
                          ...fakeUsers.map((user) => [
                            `{${user.userId}_name}`.replace(/\+/g, '\\+'),
                            user.fullName,
                          ]),
                          ...fakeUsers.map((user) => [
                            `{${user.userId}_bio}`.replace(/\+/g, '\\+'),
                            user.jobTitle || ' ',
                          ]),
                          ...fakeUsers.map((user) => [
                            `{${user._id}_name}`.replace(/\+/g, '\\+'),
                            user.fullName,
                          ]),
                          ...fakeUsers.map((user) => [
                            `{${user._id}_bio}`.replace(/\+/g, '\\+'),
                            user.jobTitle || ' ',
                          ]),
                          ...fakeSpeakers.map((user, i) => [
                            `{speaker_${i + 1}_bio}`.replace(/\+/g, '\\+'),
                            user.jobTitle || ' ',
                          ]),
                          ...fakeSpeakers.map((user, i) => [
                            `{speaker_${i + 1}_name}`.replace(/\+/g, '\\+'),
                            user.fullName,
                          ]),
                          ...fakeSpeakers.map((user, i) => [
                            `{speaker_${i + 1}_image}`.replace(/\+/g, '\\+'),
                            user.avatarUrl,
                          ]),
                          ...fakeSpeakers.map((user, i) => [
                            `{speaker_${i + 1}_image_no_bg}`.replace(
                              /\+/g,
                              '\\+'
                            ),
                            getNoBgSpeakerImage(user.avatarUrl),
                          ]),
                        ]}
                      />
                      {variants.length ? (
                        <div className="absolute translate-y-2 left-1/2 -translate-x-1/2">
                          <VariantsSelector
                            isLoading={isLoading}
                            setUpdate={setUpdate}
                            posterSlideTemplateProps={{
                              ...posterSlideTemplate,
                              blocks: changedBlocks.getAll(),
                            }}
                            variants={variants}
                            setPosterSlideTemplate={setPosterSlideTemplate}
                            setTimelineItemSelected={setTimelineItemSelected}
                            handleUpdateSlideVariant={handleUpdateSlideVariant}
                          />
                        </div>
                      ) : null}
                    </SeriesInfoContext.Provider>
                  </div>
                )}
                {posterSlideTemplate && (
                  <div className="py-3 flex justify-end gap-2">
                    <div className="pointer-events-none">
                      <Button
                        onClick={onCancel}
                        color={Button.colors.WHITE}
                        padding={Button.padding.NONE}
                      >
                        <span className="text-xxs bg-purple/10 py-0.5 px-2 flex gap-2 items-center rounded-md font-medium">
                          <div
                            className={`${
                              isLoading &&
                              'animate-[rotateRight_1000ms_0ms_infinite_both]'
                            }`}
                          >
                            <RefreshIcon color="#8B33F7" width={14} />
                          </div>{' '}
                          Changes are autosaved
                        </span>
                      </Button>
                    </div>
                    <Button
                      onClick={onCancel}
                      color={Button.colors.PURPLE}
                      padding={Button.padding.SMALL}
                    >
                      <span className="text-xs">Done</span>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    </ModalWindow>
  );
};

const QuickSceneBlock = ({ Scene, variant, scenesLength, isLoading }) => {
  const [isSceneLoading, setIsSceneLoading] = useState(false);

  useEffect(() => {
    setIsSceneLoading(false);
  }, [scenesLength]);

  return (
    <div
      className={classNames(
        'rounded  grid place-content-center',
        variant === 'square' ? 'w-[80px] h-[80px] p-2' : '',
        variant === 'standard' ? 'p-1' : ''
      )}
      onClick={() => (isLoading ? null : setIsSceneLoading(true))}
      style={{ background: Scene.background }}
    >
      <p
        className={classNames(
          'text-xs font-medium flex items-center gap-0.5',
          variant === 'square' ? 'flex-col' : '',
          variant === 'standard' ? '' : ''
        )}
      >
        <div className="flex items-center justify-center h-[25px] w-[25px]">
          {isSceneLoading && <LoadingSpinner width="25px" thickness="2px" />}
          {isSceneLoading ? null : Scene.Icon ? <Scene.Icon /> : <PlusIcon />}
        </div>
        <span className="text-xxs text-blue-dark font-medium leading-[120%]">
          {Scene.label}
        </span>
      </p>
    </div>
  );
};

const getInitialSceneTitle = (sceneTitle, eventPresenters) => {
  if (sceneTitle === 'Speaker Introduction') {
    return `Speaker Intro: ${eventPresenters[0]?.fullName}`;
  }

  if (sceneTitle.includes('Solo Segment')) {
    return `What are your top predictions in this area for the next 6 months?`;
  }

  return sceneTitle;
};

export const QuickScenes = ({
  onSceneAdd,
  afterSceneAdd,
  series,
  variant,
  sceneIndex,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const eventSpeakers = series.settings.eventPresenters.filter(
    (ep) => ep.type === presenterTypes.SPEAKER
  );

  const isAdminPlan = series?.workspace?.workspacePlan === PLANS.admin.id;
  const isBusinessPlan = series?.workspace?.workspacePlan === PLANS.business.id;

  const selectedScenes =
    eventSpeakers.length === 0 ? noSpeakerScenes : speakerScenes;

  const isSoloEpisode = checkIsSoloEpisode(series);

  const isSoloPlan =
    series?.workspace?.workspacePlan === PLANS.solo.id || isSoloEpisode;

  const scenes = useMemo(() => {
    if (isSoloPlan) {
      return soloPlanScenes;
    }

    if (isAdminPlan) {
      return selectedScenes.concat(adminPlanScenes);
    }

    if (isBusinessPlan) {
      return selectedScenes.concat(businessPlanScenes);
    }

    return selectedScenes;
  }, [isAdminPlan, isBusinessPlan, selectedScenes, isSoloPlan]);

  return (
    <>
      <div className="flex gap-2 flex-wrap">
        {scenes.map((Scene) => (
          <Button
            key={Scene.templateKey}
            background={Button.background.NONE}
            padding={Button.padding.NONE}
            onClick={async () => {
              if (!Scene.templateKey || isLoading) {
                return;
              }
              setIsLoading(true);
              fetchSceneTemplateApi(Scene.templateKey).then(async (result) => {
                const updatedSeries = await onSceneAdd(
                  sceneIndex ? sceneIndex + 1 : undefined
                )(
                  result,
                  getInitialSceneTitle(
                    result.title,
                    series.settings.eventPresenters
                  )
                );
                setIsLoading(false);
                afterSceneAdd(updatedSeries);
              });
            }}
          >
            <QuickSceneBlock
              Scene={Scene}
              variant={variant}
              scenesLength={series.autoLaunchConfig.slides.length}
              isLoading={isLoading}
            />
          </Button>
        ))}
      </div>
    </>
  );
};
