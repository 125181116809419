import React, { useEffect, useRef, useState } from 'react';
import { makeRandomId } from '../../helper';
import { fromError, logerror } from '../../helper/contextualLogger';
import { swap } from '../../components/authoring/Menu/SlideTray';
import { TextInput } from '../../components/Input';
import { DraggableQuestion } from '../../components/series/settings/EventQuestions';
import { MagicHatIcon } from '../../components/icons/MagicHatIcon';
import { generateTextApi } from '../../helper/api';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import classNames from '../../helper/classNames';
import { episodeTypes } from 'zync-common/types';

const QUESTION_DRAG_DIRECTIONS = {
  UP: 'UP',
  DOWN: 'DOWN',
};

export const AddQuestions = ({
  questions,
  setQuestions,
  afterAddQuestion,
  afterReorder,
  afterDeleteQuestion,
  showSuggestQuestion = false,
  suggestQuestionPrompt = '',
  useTextArea = false,
  episodeType,
}) => {
  const [inputQuestion, setInputQuestion] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const draggableNodeRefs = useRef([]);
  const initialDraggableNodeRef = useRef(null);
  const parentRef = useRef(null);
  const textAreaRef = useRef(null);
  const dragPosition = useRef(null);
  const [nextIndexWhileDragging, setNextIndexWhileDragging] = useState(null);

  const handleAddQuestion = async () => {
    if (!inputQuestion.trim()) {
      return;
    }

    const newQuestion = {
      text: inputQuestion,
      id: makeRandomId(6),
      timestamp: Date.now(),
      asker: null,
      moderatorQuestion: true,
      AIGenerated: false,
      index: 0,
    };

    try {
      setInputQuestion('');
      setQuestions((questions) => [...questions, newQuestion]);
      if (afterAddQuestion) {
        afterAddQuestion(newQuestion);
      }
    } catch (error) {
      logerror(fromError(error));
    }
  };

  const handleDeleteQuestion = async (id) => {
    try {
      setQuestions(questions.filter((question) => question.id !== id));
      if (afterDeleteQuestion) {
        afterDeleteQuestion(id);
      }
    } catch (error) {
      logerror(fromError(error));
    }
  };

  const findQuestionIndices = (elementKey, event, drag) => {
    const height = drag.node.getBoundingClientRect().height;

    const distanceY = drag.y;

    const oldIndex = questions.findIndex((q) => q.id === elementKey);

    const gapBetweenNodes =
      parseInt(getComputedStyle(parentRef.current).gap) || 0;

    let newIndex = Math.min(
      oldIndex +
        Math.floor(Math.round(distanceY / (height + gapBetweenNodes / 2))),
      questions.length
    );

    if (newIndex < 0) {
      newIndex = 0;
    }

    return {
      oldIndex,
      newIndex,
    };
  };

  const onDrag = (elementKey) => async (event, drag) => {
    if (drag.lastX === 0) {
      return;
    }

    const { oldIndex, newIndex } = findQuestionIndices(elementKey, event, drag);

    switch (true) {
      case oldIndex > newIndex: {
        dragPosition.current = QUESTION_DRAG_DIRECTIONS.UP;

        break;
      }
      case oldIndex < newIndex: {
        dragPosition.current = QUESTION_DRAG_DIRECTIONS.DOWN;

        break;
      }
      default: {
        break;
      }
    }

    if (oldIndex !== newIndex) {
      setNextIndexWhileDragging(newIndex);
    } else {
      setNextIndexWhileDragging(null);
    }
  };

  const onDragEnd = (elementKey) => async (event, drag) => {
    if (drag.lastY === 0) {
      return;
    }

    const { oldIndex, newIndex } = findQuestionIndices(elementKey, event, drag);
    const newQuestions = swap(questions, oldIndex, newIndex);
    setQuestions(newQuestions);
    setNextIndexWhileDragging(null);
    if (afterReorder) {
      afterReorder(oldIndex, newIndex);
    }
  };

  const handleSuggestQuestion = async () => {
    setIsLoading(true);
    const { error, text } = await generateTextApi(suggestQuestionPrompt);
    setIsLoading(false);
    if (error) return;
    setInputQuestion(text?.replaceAll('"', ''));
    if (useTextArea) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (useTextArea) {
      const textArea = textAreaRef.current;
      const handleInput = () => {
        textArea.style.height = 'auto';
        textArea.style.height = `${textArea.scrollHeight}px`;
      };
      textArea.addEventListener('input', handleInput);
      return () => {
        textArea.removeEventListener('input', handleInput);
      };
    }
  }, [useTextArea]);

  return (
    <div
      className={classNames(
        'flex flex-grow flex-col gap-2 self-start max-w-full',
        useTextArea ? 'min-w-full' : 'min-w-0'
      )}
    >
      <div className="font-medium text-blue-dark text-sm">
        {episodeType === episodeTypes.solo
          ? 'What are some discussion points / questions for this episode?'
          : 'What are some discussion points for this episode?'}
      </div>
      {questions.length > 0 && (
        <div
          className="flex flex-col gap-2.5 w-full max-h-40 overflow-auto py-3 purple-scrollbar"
          ref={parentRef}
        >
          {questions.map((question, index) => (
            <DraggableQuestion
              question={question}
              key={question.id}
              nodeRef={
                draggableNodeRefs.current[index] || initialDraggableNodeRef
              }
              handleDeleteQuestion={handleDeleteQuestion}
              draggableNodeRefs={draggableNodeRefs}
              index={index}
              onStop={onDragEnd(question.id)}
              initialDraggableNodeRef={initialDraggableNodeRef}
              onDrag={onDrag(question.id)}
            >
              {dragPosition.current === QUESTION_DRAG_DIRECTIONS.UP &&
                nextIndexWhileDragging === index && (
                  <div className="absolute w-3 h-1 bg-purple top-0 left-0 -translate-y-2"></div>
                )}
              {dragPosition.current === QUESTION_DRAG_DIRECTIONS.DOWN &&
                nextIndexWhileDragging === index && (
                  <div className="absolute w-3 h-1 bg-purple bottom-0 left-0 translate-y-2"></div>
                )}
            </DraggableQuestion>
          ))}
        </div>
      )}
      {useTextArea ? (
        <textarea
          className="rounded-md border border-solid border-gray px-2 py-1 text-sm text-blue-gray outline-none focus:border-purple w-full hide-scrollbar resize-none"
          rows={1}
          value={inputQuestion}
          onChange={(event) => setInputQuestion(event.currentTarget.value)}
          maxLength={200}
          ref={textAreaRef}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleAddQuestion();
              textAreaRef.current.style.height = 'auto';
            }
          }}
        />
      ) : (
        <div className="w-[417px]">
          <TextInput
            value={inputQuestion}
            onChange={(event, value) => setInputQuestion(value)}
            onEnter={(event, value) => handleAddQuestion()}
            textLength={200}
          />
        </div>
      )}
      {showSuggestQuestion && (
        <button
          className="flex gap-1 items-center font-medium text-xs text-blue-light underline decoration-blue-light"
          onClick={handleSuggestQuestion}
          disabled={isLoading}
        >
          {isLoading ? <LoadingSpinner width="16px" /> : <MagicHatIcon />}
          Suggest Discussion Point
        </button>
      )}
    </div>
  );
};
