import { AvatarUploader } from './AvatarUploader';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateUserProfileApi } from '../../helper/api';
import { fromError, logerror } from '../../helper/contextualLogger';

export const updateUserProfile = async (userId, userProfile, cb) => {
  try {
    await updateUserProfileApi(userId, userProfile);
    cb(userProfile);
  } catch (error) {
    logerror(fromError(error));
  }
};

export const UserProfileForm = () => {
  const dispatch = useDispatch();

  const user = useSelector((_st) => _st.auth.user, shallowEqual);
  const { emailAddress } = user || {};

  const refreshUser = (updatedProfile) => {
    dispatch({
      type: 'SET_USER',
      user: {
        ...user,
        ...updatedProfile,
      },
    });
  };

  return (
    <div className="flex gap-6">
      <AvatarUploader />
      <div className="flex flex-col gap-6">
        <input
          type="text"
          maxLength={30}
          defaultValue={user.userName}
          onChange={(event) =>
            updateUserProfile(
              user.userId,
              {
                userName: event.currentTarget.value,
              },
              refreshUser
            )
          }
          className="w-full border border-[#EBEAED] px-3 py-2"
        />
        {emailAddress && (
          <div className="w-full border border-[#EBEAED] px-3 py-2 bg-gray max-w-[228px] break-words">
            {emailAddress}
          </div>
        )}
        <textarea
          rows={2}
          maxLength={50}
          defaultValue={user.bio}
          onChange={(event) =>
            updateUserProfile(
              user.userId,
              {
                bio: event.currentTarget.value.replaceAll('"', "'"),
              },
              refreshUser
            )
          }
          placeholder="Headline e.g. CEO Acme Inc."
          className="w-full border border-[#EBEAED] px-3 py-2"
        />
      </div>
    </div>
  );
};
