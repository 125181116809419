import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { fromError, logerror } from '../../helper/contextualLogger';
import { updateUserProfileApi, uploadUserImage } from '../../helper/api';
import { AttachmentIcon } from '../../components/icons/AttachmentIcon';
import { ImageUploader, readContents } from '../../components/ImageUploader';
import { Button } from '../../components/common/Button';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeBackground as imglyRemoveBackground } from '@imgly/background-removal';
import { PencilIcon } from '../../components/icons/PencilIcon';
import classNames from '../../helper/classNames';

export const getUserNoBgImageFromUrl = (userAvatarUrl) =>
  userAvatarUrl?.replace('/bg/', '/no_bg/');
export const AvatarUploader = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user, shallowEqual);

  const { avatarUrl, userId } = user;

  const [isSubmittingFile, setIsSubmittingFile] = useState(false);

  const handleChangeAvatar = async (avatarUrl) => {
    try {
      await updateUserProfileApi(userId, {
        avatarUrl,
      });

      dispatch({
        type: 'SET_USER',
        user: { ...user, avatarUrl },
      });
    } catch (error) {
      logerror(fromError(error));
    }
  };

  const handleSubmitUserImage = async (buffer, type, name) => {
    setIsSubmittingFile(true);

    try {
      const uploadResult = await uploadUserImage(
        userId + '/bg/' + name,
        buffer,
        type
      );

      if (uploadResult) {
        const newUrl =
          'https://storage.googleapis.com/zync-media/' +
          uploadResult?.result?.metadata?.name;

        await handleChangeAvatar(newUrl);
      }
    } catch (error) {
      logerror(fromError(error));
    }

    try {
      imglyRemoveBackground(buffer).then(async (blob) => {
        const noBgBuffer = await readContents(blob);

        await uploadUserImage(userId + '/no_bg/' + name, noBgBuffer, type);
      });
    } catch (error) {
      logerror(fromError(error));
    }

    setIsSubmittingFile(false);
  };

  const handleRemoveAvatar = async () => {
    await handleChangeAvatar(null);
  };

  return (
    <div className="">
      {avatarUrl && (
        <div
          className={classNames(
            'relative shrink-0',
            isSubmittingFile && 'opacity-75'
          )}
        >
          <img
            className="w-[108px] h-[108px] self-start object-contain"
            src={avatarUrl}
            alt="Your poster"
          />
          <button
            type="button"
            onClick={handleRemoveAvatar}
            className="absolute top-0 right-0 border border-red rounded-full w-5 h-5 flex items-center justify-center scale-80"
          >
            <span className="sr-only">Click to remove the uploaded logo</span>
            <div className="shrink-0 w-5 h-5  flex items-center justify-center bg-[#fa2a66] rounded-full">
              <FontAwesomeIcon icon="trash" size="xs" color="white" />
            </div>
          </button>
          {avatarUrl ? (
            <ImageUploader
              disabled={isSubmittingFile}
              label={
                <div className="w-5 h-5 bg-orange rounded-full grid place-content-center">
                  <PencilIcon className="stroke-white" width={14} height={14} />
                </div>
              }
              buttonBorder={Button.border.ROUNDED}
              buttonColor={Button.colors.PURPLE_LITE}
              buttonPadding={Button.padding.NONE}
              onImageSubmit={handleSubmitUserImage}
              appendTimeStampToFile={false}
              containerClassName="!absolute !top-0 !right-6"
            />
          ) : null}
        </div>
      )}
      {!!avatarUrl || (
        <div>
          <div className="w-40 -mt-1">
            <ImageUploader
              disabled={isSubmittingFile}
              loading={isSubmittingFile}
              label={
                <div className="flex gap-2 items-center justify-center">
                  <AttachmentIcon className="stroke-white" />
                  Upload Image
                </div>
              }
              buttonColor={Button.colors.PURPLE}
              onImageSubmit={handleSubmitUserImage}
              appendTimeStampToFile={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};
