import { NavbarUserDropdownMenu } from './NavbarUserDropdownMenu';
import React from 'react';
import classNames from '../../helper/classNames';
import { Button } from '../../components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../auth';
import {
  HomePageLogo,
  signout,
  Navbar,
  pageTitles,
  NavbarPlanInformation,
} from './NavbarComponents';

export const HomeNavbar = ({
  isAuthenticated,
  workspaceId,
  isTrial,
  plan,
  workspacePlanValidUntil,
  currentSubscriptionRenewalTime,
}) => {
  const auth = useSelector((_st) => _st.auth);
  const dispatch = useDispatch();
  if (!isAuthenticated) {
    return null;
  }
  return (
    <Navbar>
      <ul className="flex w-full justify-between items-center">
        <ul className="flex items-center">
          <li>
            <HomePageLogo workspaceId={workspaceId} />
          </li>
        </ul>

        <div className="flex gap-5">
          <NavbarPlanInformation
            workspaceId={workspaceId}
            isTrial={isTrial}
            plan={plan}
            trialUntil={workspacePlanValidUntil}
            workspacePlanValidUntil={workspacePlanValidUntil}
            currentSubscriptionRenewalTime={currentSubscriptionRenewalTime}
          />
          <li className="hidden md:block">
            <NavbarUserDropdownMenu pageTitle={pageTitles.home} />
          </li>
        </div>

        <li className="md:hidden shrink-0">
          <Button
            color={Button.colors.PURPLE_LITE}
            padding={Button.padding.SMALL}
            onClick={() => signout(auth, dispatch, logout)}
          >
            <span className="text-sm">Sign Out</span>
          </Button>
        </li>
      </ul>
    </Navbar>
  );
};

export const WorkspaceOption = ({
  children,
  isActive,
  handleSelectWorkspace,
}) => {
  return (
    <li onClick={handleSelectWorkspace} className="bg-white">
      <li
        className={classNames(
          'duration-100 relative py-2 bg-white border-b border-solid border-b-gray flex items-center justify-start px-5 hover:bg-purple hover:bg-opacity-5 active:bg-opacity-10 hover:text-purple cursor-pointer',
          isActive && 'bg-purple bg-opacity-5 text-purple'
        )}
      >
        {isActive && (
          <div className="absolute p-[1.5px] left-0 bg-purple h-full rounded-r-md"></div>
        )}

        {children}
      </li>
    </li>
  );
};

export const WorkspaceOptionRightMenu = ({
  children,
  isActive,
  handleSelectWorkspace,
}) => {
  return (
    <li onClick={handleSelectWorkspace} className="bg-white max-w-[240px]">
      <div
        className={classNames(
          'duration-100 text-sm relative py-2 bg-purple/5 border-b border-solid border-b-gray text-[#696F8C] font-medium flex items-center justify-start px-5 hover:bg-purple/15 active:bg-opacity-10 hover:text-purple cursor-pointer pl-9',
          isActive && 'bg-purple/10 !text-purple'
        )}
      >
        {isActive && (
          <div className="absolute p-[1px] left-0 bg-purple h-full rounded-r-md"></div>
        )}

        {children}
      </div>
    </li>
  );
};
