import React, { useState } from 'react';

/** A checkbox with optional label. TODO: use a non-standard checkbox to match Zync components spec. */
export const CheckBox = ({
  /** The optional checkbox label. Can be clicked to toggle the checkbox. */
  label,
  /** Whether the checkbox is checked. */
  value,
  /** Handles when the value of the checkbox changes (passes the boolean value). */
  onChange,
  /** Whether the checkbox is disabled (onChange will not be called when disabled). */
  disabled,
  id,
}) => {
  const [checkboxId] = useState(id ?? `${Math.floor(Math.random() * 100)}`);
  return (
    <div className="cursor-default flex flex-row gap-3 items-center w-min relative">
      <input
        type="checkbox"
        disabled={disabled}
        onChange={
          disabled ? undefined : (event) => onChange(event.target.checked)
        }
        checked={value}
        className="cursor-pointer w-5 h-5 border border-solid border-black m-0 text-purple form-checkbox rounded-sm focus:ring-0 focus:ring-white"
        id={checkboxId}
      />
      {label && (
        <label
          className={'grow text-blue-gray text-sm m-0 w-max cursor-pointer'}
          htmlFor={checkboxId}
        >
          {label}
        </label>
      )}
    </div>
  );
};
