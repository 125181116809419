import React from 'react';
import { TextArea } from '../../components/common/TextArea';

export const AddBlogPost = ({ newBlogPost, setNewBlogPost }) => {
  return (
    <div className="w-full h-[225px]">
      <TextArea
        placeholder="Paste the text from your recent LinkedIn Post, Blog or Newsletter Article"
        className="w-full !h-[80%]"
        value={newBlogPost}
        onChange={setNewBlogPost}
        label={
          <p className="text-center mb-4">
            Please paste content of entire Blog Post / LinkedIn Post /
            Newsletter
          </p>
        }
      />
    </div>
  );
};
