import { useCallback, useState } from 'react';
import { Modal, ModalTitle, ModalWindow } from '../../Modal';
import { Section, SettingsSwitch } from '../MeetingSettingsModal';
import { isEqual, uniqBy } from 'lodash';
import { Button } from '../../common/Button';
import { updateEpisodeSettingsApi } from '../../../helper/api';
import { useEpisodeSummary } from '../../../hooks/useEpisodeSummary';
import { TextInput } from '../../Input';
import { delay } from '../../../helper/exponentialFetchRetry';
import classNames from '../../../helper/classNames';
import { CheckMarkIcon } from '../../icons/CheckMarkIcon';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const HighlightSelection = ({
  highlight,
  updateSettings,
  highlightsSettings,
  workspaceId,
  updateKey = 'highlights',
  index,
}) => {
  const { title: highlightTitle, highlightId, shortTextData } = highlight || {};
  const title =
    highlightTitle || shortTextData?.thumbnailTitle || `Shorts ${index + 1}`;
  const highlightSettings = highlightsSettings[highlightId];
  const [checked, setChecked] = useState(!!!highlightSettings?.hidden);

  return (
    <div className="flex gap-[15px] items-center text-sm font-light">
      <button
        className={classNames(
          'h-4 w-4 flex items-center justify-center rounded',
          checked ? 'bg-purple' : 'border border-gray'
        )}
        onClick={() => {
          updateSettings(updateKey, {
            ...highlightsSettings,
            [highlightId]: {
              ...highlightsSettings[highlightId],
              hidden: checked,
            },
          });
          setChecked((prevState) => !prevState);
        }}
      >
        {checked && <CheckMarkIcon className="fill-white" />}
      </button>
      <Link
        className={classNames(
          'underline decoration-blue-dark',
          !checked && 'text-[#97A0AF]'
        )}
        to={`/${workspaceId}/feed/${highlightId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {title}
      </Link>
    </div>
  );
};

export const ManageEpisodeSettingsModal = ({
  series,
  localDispatch,
  handleClose,
  episodeSummary,
  refreshContentKit,
  highlights,
  workspace,
  shorts,
}) => {
  const [initialSettings, setInitialSettings] = useState(series?.settings);
  const [settings, setSettings] = useState(series?.settings);
  const isEdited = !isEqual(settings, initialSettings);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { workspaceId } = workspace || {};

  const updateSettings = useCallback(
    (field, value) => {
      if (!series) return;
      setSettings((prevSettings) => ({ ...prevSettings, [field]: value }));
      if (error) {
        setError(null);
      }
    },
    [error, series]
  );

  const { RichTextToolbar, RichTextEditor } = useEpisodeSummary({
    series,
    disabled: isLoading,
    episodeSummary,
    updateSettings,
  });

  const saveSettings = useCallback(async () => {
    setIsLoading(true);

    const result = await updateEpisodeSettingsApi(
      series.meetingSeriesId,
      settings
    );

    if (!result) {
      setError('There was an error saving settings, please try again.');
      setIsLoading(false);
      return;
    }
    await delay(5_000);
    await refreshContentKit();
    localDispatch({ type: 'REFRESH_SERIES', series: result });
    setInitialSettings(result.settings);
    setSettings(result.settings);
    setIsLoading(false);
  }, [localDispatch, refreshContentKit, series.meetingSeriesId, settings]);

  return (
    <ModalWindow
      size={Modal.size.xxl}
      onCancel={handleClose}
      onOutsideClick={handleClose}
      overflow="overflow-visible"
    >
      <ModalTitle>Settings</ModalTitle>
      <div className="px-6 my-6 md:px-8 md:my-8 h-[60vh] overflow-auto">
        <div className="flex flex-col gap-8">
          <Section title="Publish Episode Settings">
            <SettingsSwitch
              defaultChecked={series?.settings?.excludeEpisodeFromShow}
              label="Exclude Episode from Show"
              onChange={(newValue) =>
                updateSettings('excludeEpisodeFromShow', newValue)
              }
            />
          </Section>
          <Section title="Access Episode Settings">
            <SettingsSwitch
              defaultChecked={series?.settings?.mustRegisterToAccessEpisode}
              label="Guest must register to access episode"
              onChange={(newValue) =>
                updateSettings('mustRegisterToAccessEpisode', newValue)
              }
            />
          </Section>
          <Section title="Select clips that goes into your Highlights Feed and Episode Page">
            <div className="flex flex-col gap-3">
              {highlights &&
                highlights.map((h) => {
                  const { highlightId } = h;
                  const { highlights: highlightsSettings = {} } =
                    settings || {};

                  return (
                    <HighlightSelection
                      highlight={h}
                      highlightsSettings={highlightsSettings}
                      updateSettings={updateSettings}
                      key={`${highlightId}-checkbox`}
                      workspaceId={workspaceId}
                      updateKey="highlights"
                    />
                  );
                })}
              {shorts?.length
                ? uniqBy(shorts, (short) => short.highlightId)
                    .filter((s) => !!s?.thumbnailUrl)
                    .map((h, index) => {
                      const { highlightId } = h;
                      const { shorts: highlightsSettings = {} } =
                        settings || {};

                      return (
                        <HighlightSelection
                          highlight={h}
                          highlightsSettings={highlightsSettings}
                          updateSettings={updateSettings}
                          key={`${highlightId}-checkbox`}
                          workspaceId={workspaceId}
                          updateKey="shorts"
                          index={index}
                        />
                      );
                    })
                : null}
            </div>
          </Section>
          <Section title="Select clips that go into your Speaker Kit page">
            <div className="flex flex-col gap-3">
              {shorts?.length
                ? uniqBy(shorts, (short) => short.highlightId).map(
                    (h, index) => {
                      const { highlightId } = h;
                      const { speakerKitShorts: highlightsSettings = {} } =
                        settings || {};

                      return (
                        <HighlightSelection
                          highlight={h}
                          highlightsSettings={highlightsSettings}
                          updateSettings={updateSettings}
                          key={`${highlightId}-checkbox`}
                          workspaceId={workspaceId}
                          updateKey="speakerKitShorts"
                          index={index}
                        />
                      );
                    }
                  )
                : null}
            </div>
          </Section>
          <Section title="Episode Summary">
            <div className="flex justify-start border-b border-[#E6E8F0]">
              {RichTextToolbar}
            </div>
            <div className="h-[260px] overflow-auto p-4">
              <style>
                {`
              .public-DraftStyleDefault-ul {
                margin: 0; 
              } 
            `}
              </style>
              {RichTextEditor}
            </div>
          </Section>
          <Section title="Episode Video Override">
            <TextInput
              label="Add valid video url (hosted mp4, Youtube, etc.) to replace your episode video"
              defaultValue={series?.settings?.episodeVideoUrl}
              onBlur={(event, value) =>
                updateSettings('episodeVideoUrl', value)
              }
            />
          </Section>
          <div className="flex flex-col gap-2 w-full items-center sticky bottom-0 bg-white z-10 pt-8">
            <div className="flex gap-4 w-full justify-end">
              <Button
                padding={Button.padding.MEDIUM}
                state={isLoading && Button.states.DISABLED}
                onClick={handleClose}
                color={Button.colors.WHITE}
                size={Button.sizes.FIT}
              >
                <span className="text-base w-40">Cancel</span>
              </Button>
              <Button
                padding={Button.padding.MEDIUM}
                state={
                  isLoading
                    ? Button.states.LOADING
                    : isEdited
                    ? Button.states.DEFAULT
                    : Button.states.DISABLED
                }
                onClick={saveSettings}
                color={Button.colors.PURPLE}
                size={Button.sizes.FIT}
              >
                <span className="text-base w-40">Save</span>
              </Button>
            </div>
            {error && (
              <div className="text-red text-xs font-medium">{error}</div>
            )}
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};
