import { ImageUploader, readContents } from '../../components/ImageUploader';
import React, { useEffect, useState } from 'react';
import { Button } from '../../components/common/Button';
import { PersonInCircleIcon } from '../../components/icons/PersonInCircleIcon';
import {
  createNewSpeakerApi,
  updateSeriesApi,
  updateSpeakerApi,
  uploadEventPresenterImage,
} from '../../helper/api';
import { fromError, logerror } from '../../helper/contextualLogger';
import classNames from '../../helper/classNames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { presenterTypes } from '../../components/series/settings/EventPresenters';
import { cloneDeep } from 'lodash';
import { sceneTemplateKeyMap } from 'zync-common/types';
import { validate } from 'email-validator';
import { makeRandomId } from '../../helper';
import { DropdownSelect } from '../../components/common/Dropdown';
import { useSpeakersPage } from '../../hooks/useSpeakersPage';
import { notifyUser } from '../../components/authoring/hooks';
import { removeBackground as imglyRemoveBackground } from '@imgly/background-removal';
import { createEpisodeWithoutSpeakers } from './createEpisodeWithoutSpeakers';
import { createEpisodeWithSpeakers } from './createEpisodeWithSpeakers';
import { createNewPresenterSlide } from 'zync-common/meetingSeries/createNewPresenterSlide';

const BORDER_WIDTH_VARIANTS = {
  NONE: 'NONE',
  THIN: 'THIN',
};

const BORDER_WIDTH_VARIANTS_CLASSNAMES = {
  NONE: 'border-0',
  THIN: 'border',
};

const BORDER_COLOR_VARIANTS = {
  TRANSPARENT: 'TRANSPARENT',
  LIGHT_GRAY: 'LIGHT_GRAY',
};

const BORDER_COLOR_VARIANTS_CLASSNAMES = {
  TRANSPARENT: 'border-transparent',
  LIGHT_GRAY: 'border-[#EBEAED]',
};

const BORDER_RADIUS_VARIANTS = {
  NONE: 'NONE',
  ROUNDED: 'ROUNDED',
};

const BORDER_RADIUS_VARIANTS_CLASSNAMES = {
  NONE: 'rounded-none',
  ROUNDED: 'rounded',
};

const initialPresenter = {
  emailAddress: '',
  fullName: '',
  jobTitle: '',
  linkedInUrl: '',
  presenterPictureUrl: '',
  type: 'speaker',
};

const getDiscussionPointsFromSeries = (series) => {
  const slides = series.autoLaunchConfig.slides;

  return slides.reduce((questions, slide) => {
    if (
      slide.sceneTemplateKey === sceneTemplateKeyMap.scene_discussion_point ||
      slide.sceneTemplateKey ===
        sceneTemplateKeyMap.scene_discussion_point_no_speaker
    ) {
      return questions.concat({
        text: slide.slideName?.replace('Discuss: ', ''), // the slide name is a question
        id: makeRandomId(6),
        timestamp: Date.now(),
        asker: null,
        moderatorQuestion: true,
        AIGenerated: false,
        index: 0,
      });
    }

    return questions;
  }, []);
};
const replaceNoSpeakerScenes = (slidesToUpdate, newTemplate) => {
  const newSlides = newTemplate.slides;

  const oldWelcomePageIndex = slidesToUpdate.findIndex(
    (slide) =>
      slide.sceneTemplateKey === sceneTemplateKeyMap.scene_welcome_no_speaker
  );
  const oldQaSceneIndex = slidesToUpdate.findIndex(
    (slide) =>
      slide.sceneTemplateKey === sceneTemplateKeyMap['scene_q&a_no_speaker']
  );
  const oldThankYouSceneIndex = slidesToUpdate.findIndex(
    (slide) =>
      slide.sceneTemplateKey === sceneTemplateKeyMap.scene_thankyou_no_speaker
  );

  const newWelcomePageIndex = newSlides.findIndex(
    (slide) => slide.sceneTemplateKey === sceneTemplateKeyMap.scene_welcome_1x1
  );
  const newQaSceneIndex = newSlides.findIndex(
    (slide) => slide.sceneTemplateKey === sceneTemplateKeyMap['scene_q&a']
  );
  const newThankYouSceneIndex = newSlides.findIndex(
    (slide) => slide.sceneTemplateKey === sceneTemplateKeyMap.scene_thankyou
  );
  const newDiscussionPointsScenes = newSlides.filter(
    (slide) =>
      slide.sceneTemplateKey === sceneTemplateKeyMap.scene_discussion_point
  );
  const newOpenDiscussionSceneIndex = newSlides.findIndex(
    (slide) =>
      slide.sceneTemplateKey === sceneTemplateKeyMap.scene_open_discussion
  );

  const areFound = (...indices) => indices.every((index) => index !== -1);

  if (areFound(oldWelcomePageIndex, newWelcomePageIndex)) {
    slidesToUpdate.splice(
      oldWelcomePageIndex,
      1,
      newSlides[newWelcomePageIndex]
    );
  }

  if (areFound(oldQaSceneIndex, newQaSceneIndex)) {
    slidesToUpdate.splice(oldQaSceneIndex, 1, newSlides[newQaSceneIndex]);
  }

  if (areFound(oldThankYouSceneIndex, newThankYouSceneIndex)) {
    slidesToUpdate.splice(
      oldThankYouSceneIndex,
      1,
      newSlides[newThankYouSceneIndex]
    );
  }

  const discussionPointSceneIndices = findSlidesIndicesByKey(
    slidesToUpdate,
    sceneTemplateKeyMap.scene_discussion_point_no_speaker
  );

  discussionPointSceneIndices.forEach((sceneIndex, index) => {
    slidesToUpdate.splice(sceneIndex, 1, newDiscussionPointsScenes[index]);
  });

  if (areFound(newThankYouSceneIndex, newOpenDiscussionSceneIndex)) {
    slidesToUpdate.splice(
      newThankYouSceneIndex - 1,
      0,
      newSlides[newOpenDiscussionSceneIndex]
    );
  }
};

const findSlidesIndicesByKey = (slides, sceneTemplateKey) => {
  return slides.reduce((indices, slide, index) => {
    if (slide.sceneTemplateKey === sceneTemplateKey) {
      return indices.concat(index);
    }
    return indices;
  }, []);
};

const replaceSpeakerScenes = (slidesToUpdate, newTemplate) => {
  const newSlides = newTemplate.slides;

  const oldWelcomePageIndex = slidesToUpdate.findIndex(
    (slide) => slide.sceneTemplateKey === sceneTemplateKeyMap.scene_welcome_1x1
  );
  const oldQaSceneIndex = slidesToUpdate.findIndex(
    (slide) => slide.sceneTemplateKey === sceneTemplateKeyMap['scene_q&a']
  );
  const oldThankYouSceneIndex = slidesToUpdate.findIndex(
    (slide) => slide.sceneTemplateKey === sceneTemplateKeyMap.scene_thankyou
  );

  const oldOpenDiscussionSceneIndex = slidesToUpdate.findIndex(
    (slide) =>
      slide.sceneTemplateKey === sceneTemplateKeyMap.scene_open_discussion
  );

  const newWelcomePageIndex = newSlides.findIndex(
    (slide) =>
      slide.sceneTemplateKey === sceneTemplateKeyMap.scene_welcome_no_speaker
  );
  const newQaSceneIndex = newSlides.findIndex(
    (slide) =>
      slide.sceneTemplateKey === sceneTemplateKeyMap['scene_q&a_no_speaker']
  );
  const newThankYouSceneIndex = newSlides.findIndex(
    (slide) =>
      slide.sceneTemplateKey === sceneTemplateKeyMap.scene_thankyou_no_speaker
  );

  const newDiscussionPointsScenes = newSlides.filter(
    (slide) =>
      slide.sceneTemplateKey ===
      sceneTemplateKeyMap.scene_discussion_point_no_speaker
  );

  const areFound = (...indices) => indices.every((index) => index !== -1);

  if (areFound(oldWelcomePageIndex, newWelcomePageIndex)) {
    slidesToUpdate.splice(
      oldWelcomePageIndex,
      1,
      newSlides[newWelcomePageIndex]
    );
  }

  if (areFound(oldQaSceneIndex, newQaSceneIndex)) {
    slidesToUpdate.splice(oldQaSceneIndex, 1, newSlides[newQaSceneIndex]);
  }

  if (areFound(oldThankYouSceneIndex, newThankYouSceneIndex)) {
    slidesToUpdate.splice(
      oldThankYouSceneIndex,
      1,
      newSlides[newThankYouSceneIndex]
    );
  }

  const discussionPointSceneIndices = findSlidesIndicesByKey(
    slidesToUpdate,
    sceneTemplateKeyMap.scene_discussion_point
  );

  discussionPointSceneIndices.forEach((sceneIndex, index) => {
    slidesToUpdate.splice(sceneIndex, 1, newDiscussionPointsScenes[index]);
  });

  if (oldOpenDiscussionSceneIndex !== -1) {
    slidesToUpdate.splice(oldOpenDiscussionSceneIndex, 1);
  }
};

export const useSelectSpeakerDropdown = ({
  newMeetingSeriesId,
  setTemporaryEventPresenter,
  eventPresenters,
  setEventPresenters,
  series,
  localDispatch,
  template,
  onInvalidSpeakerAdd,
  onValidSpeakerAdd,
  isAddingSpeaker,
  setIsAddingSpeaker,
  workspaceId,
}) => {
  const [isSubmittingFile, setIsSubmittingFile] = useState(false);
  const [error, setError] = useState('');

  const [eventPresenter, setEventPresenter] = useState({
    emailAddress: '',
    fullName: '',
    jobTitle: '',
    linkedInUrl: '',
    presenterPictureUrl: '',
    type: 'speaker',
  });

  const handleChangeSpeaker = (field, value) => {
    setError('');

    setEventPresenter((prevEventPresenter) => {
      const newEventPresenter = {
        ...prevEventPresenter,
        [field]: value,
      };
      return newEventPresenter;
    });
    setTemporaryEventPresenter((prevTemporaryEventPresenter) => {
      const newEventPresenter = {
        ...prevTemporaryEventPresenter,
        [field]: value,
      };
      return newEventPresenter;
    });
  };

  const handleCancelAddingSpeaker = () => {
    setIsAddingSpeaker(false);
    setTemporaryEventPresenter(initialPresenter);
    setEventPresenter(initialPresenter);
  };

  const canAddSpeaker = Boolean(
    eventPresenter.emailAddress &&
      validate(eventPresenter.emailAddress) &&
      eventPresenter.fullName &&
      eventPresenter.presenterPictureUrl
  );

  const checkIsEmailAddressUsed = (emailAddress) => {
    return series?.settings?.eventPresenters.find(
      (ep) => ep.emailAddress === emailAddress
    );
  };
  const handleConfirmUpdatingSpeaker = async (emailAddress) => {
    if (emailAddress && checkIsEmailAddressUsed(emailAddress)) {
      return setError(
        'Email of this speaker was used already. Please use different email or delete other speaker'
      );
    }
    if (!canAddSpeaker) {
      setError('Please provide all speaker details, including profile picture');

      !validate(eventPresenter.emailAddress) &&
        setError('Email provided is incorrect. Please use valid email format');

      return;
    }

    setError('');

    const speakerHandler = eventPresenter._id
      ? updateSpeakerApi
      : createNewSpeakerApi;

    const newSpeaker = await speakerHandler(workspaceId, eventPresenter);

    // this path is executed in Plan tab context
    if (series && localDispatch) {
      const {
        settings: { eventPresenters: fetchedEventPresenters = [] },
      } = series || {};
      const eventHost = fetchedEventPresenters.find(
        (ep) => ep.type === presenterTypes.HOST
      );
      const eventSpeakers = fetchedEventPresenters.filter(
        (ep) => ep.type === presenterTypes.SPEAKER
      );
      const newEventPresenters = [
        eventHost,
        ...eventSpeakers,
        {
          ...eventPresenter,
          speaker: newSpeaker,
          _id: newSpeaker._id,
        },
      ];
      const presenterTemplate = template.slides?.find(
        (slide) => slide.sceneTemplateKey === sceneTemplateKeyMap.speaker_intro
      );
      const newPresenterSlide = createNewPresenterSlide(
        presenterTemplate,
        eventPresenter
      );
      const updatedSlides = cloneDeep(series?.autoLaunchConfig?.slides);
      const lastPresenterSlideIndex = updatedSlides.findLastIndex(
        (slide) => slide.sceneTemplateKey === sceneTemplateKeyMap.speaker_intro
      );
      updatedSlides.splice(
        lastPresenterSlideIndex === -1 ? 2 : lastPresenterSlideIndex + 1, // 2 is initial presenter scene destination index. 0 is Lobby, 1 is Topic Intro
        0,
        newPresenterSlide
      );

      const timelineStyle = series.workspace.settings.defaultTimelineStyle;

      const newTemplate = await createEpisodeWithSpeakers({
        title: series.title,
        eventSpeakers,
        eventHost,
        questions: getDiscussionPointsFromSeries(series),
        timelineStyle,
      });

      // we are adding the first presenter to the meeting, thus we were on the no speaker version before
      if (eventSpeakers.length === 0) {
        replaceNoSpeakerScenes(updatedSlides, newTemplate);
      }

      const result = await updateSeriesApi(newMeetingSeriesId, {
        ...series,
        autoLaunchConfig: {
          ...series.autoLaunchConfig,
          slides: updatedSlides,
        },
        settings: {
          eventPresenters: newEventPresenters,
        },
      });
      if (result?.error) return;
      localDispatch({ type: 'REFRESH_SERIES', series: result });
      refreshSpeakers();
    }

    try {
      setIsAddingSpeaker(false);
      setTemporaryEventPresenter(initialPresenter);
      setEventPresenter(initialPresenter);
      setEventPresenters((currentPresenters) =>
        currentPresenters.concat(newSpeaker)
      );
    } catch (error) {}
  };

  const handleUploadEventPresenterImage = async (buffer, type, name) => {
    setIsSubmittingFile(true);

    try {
      const uploadResult = await uploadEventPresenterImage(
        newMeetingSeriesId + '/presenter/' + name,
        buffer,
        type
      );

      if (uploadResult) {
        const newUrl =
          'https://storage.googleapis.com/zync-media/' +
          uploadResult?.result?.metadata?.name;

        handleChangeSpeaker('presenterPictureUrl', newUrl);

        imglyRemoveBackground(buffer).then(async (blob) => {
          const noBgBuffer = await readContents(blob);

          uploadEventPresenterImage(
            newMeetingSeriesId + '/presenter-no-bg/' + name,
            noBgBuffer,
            type
          );
        });
      }
    } catch (error) {
      logerror(fromError(error));
    }
    setIsSubmittingFile(false);
  };

  useEffect(() => {
    if (isAddingSpeaker) {
      onInvalidSpeakerAdd && onInvalidSpeakerAdd();
    }

    if (!isAddingSpeaker) {
      onValidSpeakerAdd && onValidSpeakerAdd();
    }
  }, [isAddingSpeaker, onInvalidSpeakerAdd, onValidSpeakerAdd]);

  const { speakers, refreshSpeakers } = useSpeakersPage(
    series?.workspaceId || series?.workspace.workspaceId
  );

  const handleRemoveSpeaker = async (emailAddress, fullName, jobTitle) => {
    if (series && localDispatch) {
      const {
        settings: { eventPresenters: fetchedEventPresenters = [] },
      } = series || {};
      const targetPresenter = fetchedEventPresenters.find(
        (ep) =>
          ep.type === presenterTypes.SPEAKER && ep.emailAddress === emailAddress
      );
      const eventSpeakers = fetchedEventPresenters.filter(
        (ep) => ep.type === presenterTypes.SPEAKER
      );

      const newEventPresenters = [
        fetchedEventPresenters.find((ep) => ep.type === presenterTypes.HOST),
        ...fetchedEventPresenters.filter(
          (ep) => ep.type === presenterTypes.SPEAKER
        ),
      ];

      const presenterToDeleteIndex = newEventPresenters.findIndex(
        (p) =>
          p.emailAddress === emailAddress &&
          p.fullName === fullName &&
          p.jobTitle === jobTitle
      );

      if (presenterToDeleteIndex !== -1) {
        newEventPresenters.splice(presenterToDeleteIndex, 1);
      }

      const deleteSlideIndex = series?.autoLaunchConfig?.slides.findIndex(
        (slide) => {
          if (sceneTemplateKeyMap.speaker_intro !== slide.sceneTemplateKey) {
            return false;
          }

          return slide?.slideName.includes(targetPresenter.fullName);
        }
      );
      const updatedSlides = cloneDeep(series?.autoLaunchConfig?.slides);

      if (deleteSlideIndex !== -1) {
        updatedSlides.splice(deleteSlideIndex, 1);
      }

      const timelineStyle = series.workspace.settings.defaultTimelineStyle;

      const newTemplate = await createEpisodeWithoutSpeakers({
        title: series.title,
        eventHost: {},
        questions: getDiscussionPointsFromSeries(series),
        timelineStyle,
      });

      // we are moving to no speaker variant. Before removing a speaker we had only 1
      if (eventSpeakers.length === 1) {
        replaceSpeakerScenes(updatedSlides, newTemplate);
      }

      const result = await updateSeriesApi(newMeetingSeriesId, {
        ...series,
        autoLaunchConfig: {
          ...series.autoLaunchConfig,
          slides: updatedSlides,
        },
        settings: {
          eventPresenters: newEventPresenters,
        },
        removedSpeakerId: emailAddress,
      });
      if (result?.error) return;
      localDispatch({ type: 'REFRESH_SERIES', series: result });
    }
    setEventPresenters((ep) =>
      ep.filter((p) => p.emailAddress !== emailAddress)
    );
  };

  const checkIsSpeakerAdded = (eventSpeakerId) =>
    !!eventPresenters.find((ep) => ep._id === eventSpeakerId);

  return {
    isAddingSpeaker,
    setIsAddingSpeaker,

    handleChangeSpeaker,
    handleUploadEventPresenterImage,
    isSubmittingFile,
    eventPresenter,
    setEventPresenter,

    handleConfirmUpdatingSpeaker,
    handleCancelAddingSpeaker,
    checkIsSpeakerAdded,
    canAddSpeaker,
    handleRemoveSpeaker,

    error,

    speakers,
  };
};

const SPEAKERS_COUNT_LIMIT = 6;

export const SelectSpeakerDropdown = ({
  eventPresenters,
  setEventPresenters,
  setTemporaryEventPresenter,
  newMeetingSeriesId,
  series,
  localDispatch,
  template,
  borderWidth = BORDER_WIDTH_VARIANTS.NONE,
  borderColor = BORDER_COLOR_VARIANTS.TRANSPARENT,
  borderRadius = BORDER_RADIUS_VARIANTS.NONE,
  onInvalidSpeakerAdd,
  onValidSpeakerAdd,
  isAddingSpeaker,
  setIsAddingSpeaker,
  workspaceId,
  disabled = false,
}) => {
  const {
    handleUploadEventPresenterImage,
    isSubmittingFile,
    handleChangeSpeaker,
    eventPresenter,
    setEventPresenter,
    handleCancelAddingSpeaker,
    handleConfirmUpdatingSpeaker,
    handleRemoveSpeaker,
    error,
    speakers,
    checkIsSpeakerAdded,
  } = useSelectSpeakerDropdown({
    newMeetingSeriesId,
    setTemporaryEventPresenter,
    eventPresenters,
    setEventPresenters,
    series,
    localDispatch,
    template,
    onInvalidSpeakerAdd,
    onValidSpeakerAdd,
    isAddingSpeaker,
    setIsAddingSpeaker,
    workspaceId,
  });

  const presenterPictureUrl = eventPresenter.presenterPictureUrl;

  return (
    <div
      className={`flex flex-grow flex-col gap-2 self-start min-w-0 ${
        disabled && 'pointer-events-none'
      }`}
    >
      {!isAddingSpeaker ? (
        <div>
          {eventPresenters.length >= SPEAKERS_COUNT_LIMIT ? null : (
            <DropdownSelect
              backgroundColor={DropdownSelect.backgroundColor.WHITE}
              borderColor={DropdownSelect.borderColor.GRAY}
              borderSize={DropdownSelect.borderSize.ONE}
              selectedKey={undefined}
              emptyOptionsMessage="Select speaker"
              angleColor="fill-[#97A0AF]"
              options={[
                {
                  key: 'addSpeaker',
                  title: (
                    <div className="h-[24px] flex gap-3 items-center">
                      <p>+ Add Speaker</p>
                    </div>
                  ),
                },
              ].concat(
                ...speakers.map((speaker) => ({
                  key: speaker._id,
                  title: (
                    <div
                      className={classNames(
                        'h-[40px] flex gap-3 items-center',
                        checkIsSpeakerAdded(speaker._id) &&
                          'opacity-50 grayscale'
                      )}
                    >
                      {speaker.presenterPictureUrl ? (
                        <img
                          width="36px"
                          height="36px"
                          className="object-cover rounded-full w-[36px] h-[36px]"
                          src={speaker.presenterPictureUrl}
                          alt=""
                        />
                      ) : (
                        <div className="w-[40px] h-[40px] bg-gray rounded-full" />
                      )}
                      <p>{speaker.fullName}</p>
                    </div>
                  ),
                }))
              )}
              onSelect={(value) => {
                const speakerCrm = speakers.find(
                  (speaker) => speaker._id === value
                );

                switch (true) {
                  case value === 'addSpeaker': {
                    // when special addSpeaker button was selected

                    return setIsAddingSpeaker(true);
                  }
                  case !!speakerCrm: {
                    if (checkIsSpeakerAdded(speakerCrm._id)) {
                      return;
                    }
                    // when a predefined speaker was selected
                    setTemporaryEventPresenter(speakerCrm);
                    setEventPresenter(speakerCrm);

                    return setIsAddingSpeaker(true);
                  }
                  default: {
                    // nothing to do
                  }
                }
              }}
            />
          )}
          {eventPresenters.length >= SPEAKERS_COUNT_LIMIT ? (
            <p className="text-orange-light font-medium text-sm mt-2">
              You cannot add more than {SPEAKERS_COUNT_LIMIT} speakers.
            </p>
          ) : null}
        </div>
      ) : (
        <>
          <h2 className="text-blue-dark font-semibold">Who is your speaker?</h2>
          <div className="flex items-start gap-8">
            <ImageUploader
              buttonPadding={Button.padding.NONE}
              onImageSubmit={handleUploadEventPresenterImage}
              onFileSizeExceeded={() =>
                notifyUser('Please use file smaller than 5 MB')
              }
              label={
                <div>
                  {presenterPictureUrl ? (
                    <div className="grid justify-items-center place-content-center justify-center w-[108px] h-[108px] bg-white">
                      <img
                        src={presenterPictureUrl}
                        alt=""
                        width={108}
                        height={108}
                        className="object-contain"
                        style={{
                          height: 108,
                          width: 108,
                        }}
                      />
                    </div>
                  ) : (
                    <div className="bg-[#E9E9EE] grid justify-items-center place-content-center justify-center w-[108px] h-[108px]">
                      <PersonInCircleIcon
                        width={70}
                        height={70}
                        color="rgba(0,0,0,0.1)"
                      />
                      <span className="block text-xxs font-medium text-blue-gray">
                        Upload Image
                      </span>
                    </div>
                  )}
                </div>
              }
              loading={isSubmittingFile}
            />
            <div className="flex flex-col gap-4 w-full">
              <input
                className={classNames(
                  'w-full px-3 py-2',
                  BORDER_WIDTH_VARIANTS_CLASSNAMES[borderWidth],
                  BORDER_COLOR_VARIANTS_CLASSNAMES[borderColor],
                  BORDER_RADIUS_VARIANTS_CLASSNAMES[borderRadius]
                )}
                type="text"
                placeholder="Speaker name"
                value={eventPresenter?.name || eventPresenter?.fullName}
                onChange={(event) => {
                  handleChangeSpeaker('fullName', event.currentTarget.value);
                  handleChangeSpeaker('name', event.currentTarget.value);
                }}
                maxLength={24}
              />
              <textarea
                className={classNames(
                  'w-full px-3 py-2',
                  BORDER_WIDTH_VARIANTS_CLASSNAMES[borderWidth],
                  BORDER_COLOR_VARIANTS_CLASSNAMES[borderColor],
                  BORDER_RADIUS_VARIANTS_CLASSNAMES[borderRadius]
                )}
                rows={2}
                placeholder="Headline e.g. CEO Acme Inc."
                value={eventPresenter.jobTitle}
                onChange={(event) =>
                  handleChangeSpeaker('jobTitle', event.currentTarget.value)
                }
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
                maxLength={50}
              />
              <input
                className={classNames(
                  'w-full px-3 py-2',
                  BORDER_WIDTH_VARIANTS_CLASSNAMES[borderWidth],
                  BORDER_COLOR_VARIANTS_CLASSNAMES[borderColor],
                  BORDER_RADIUS_VARIANTS_CLASSNAMES[borderRadius]
                )}
                type="email"
                placeholder="Email address"
                value={eventPresenter.emailAddress}
                onChange={(event) => {
                  handleChangeSpeaker(
                    'emailAddress',
                    event.currentTarget.value
                  );
                }}
              />
              <input
                className={classNames(
                  'w-full px-3 py-2',
                  BORDER_WIDTH_VARIANTS_CLASSNAMES[borderWidth],
                  BORDER_COLOR_VARIANTS_CLASSNAMES[borderColor],
                  BORDER_RADIUS_VARIANTS_CLASSNAMES[borderRadius]
                )}
                type="text"
                placeholder="Linkedin URL"
                value={eventPresenter.linkedInUrl}
                onChange={(event) => {
                  handleChangeSpeaker('linkedInUrl', event.currentTarget.value);
                }}
              />
              <div className="flex gap-2">
                <Button
                  color={Button.colors.WHITE}
                  onClick={handleCancelAddingSpeaker}
                >
                  <span className="text-sm">Cancel</span>
                </Button>
                <Button
                  color={Button.colors.PURPLE}
                  onClick={() =>
                    handleConfirmUpdatingSpeaker(eventPresenter.emailAddress)
                  }
                >
                  <span className="text-sm">Add Speaker</span>
                </Button>
              </div>
              {error && (
                <span className="text-red text-xs font-medium">{error}</span>
              )}
            </div>
          </div>
        </>
      )}
      {eventPresenters.length ? (
        <ul className="flex gap-2 mt-4">
          {eventPresenters.map((eventPresenter, i) => (
            <li
              className="flex-[1_0_0] max-w-[90px] aspect-square relative"
              key={eventPresenter.emailAddress + i}
            >
              <button
                type="button"
                onClick={() =>
                  handleRemoveSpeaker(
                    eventPresenter.emailAddress,
                    eventPresenter.fullName,
                    eventPresenter.jobTitle
                  )
                }
                className="absolute -top-1 -right-1 border border-red rounded-full w-3 h-3 flex items-center justify-center scale-80"
              >
                <span className="sr-only">Click to remove the speaker</span>
                <div className="shrink-0 w-3 h-3  flex items-center justify-center bg-[#fa2a66] rounded-full">
                  <div className="scale-50">
                    <FontAwesomeIcon icon="trash" size="xs" color="white" />
                  </div>
                </div>
              </button>
              <div className="bg-white">
                <img
                  alt=""
                  src={eventPresenter.presenterPictureUrl}
                  className="object-cover aspect-square w-[90px] h-[90px] border border-gray/50 rounded-md"
                />
                <p className="text-xxs font-semibold text-blue-dark line-clamp-1">
                  {eventPresenter.fullName}
                </p>
              </div>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

SelectSpeakerDropdown.borderWidth = BORDER_WIDTH_VARIANTS;
SelectSpeakerDropdown.borderColor = BORDER_COLOR_VARIANTS;
SelectSpeakerDropdown.borderRadius = BORDER_RADIUS_VARIANTS;
