import { useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  fetchBlocksApi,
  fetchSeriesApi,
  updateSeriesApi,
  addGoogleSlidesToMeetingSeriesApi,
  reorderScenesApi,
  updateBlockSettingsApi,
  deleteBlockInstanceApi,
  addBlocksApi,
  deleteSceneApi,
  updateSceneSettingsApi,
  addScenesApi,
  fetchSceneTemplatesApi,
  fetchDefaultSceneBackdropsApi,
  uploadBlockImage,
} from '../../helper/api';
import { makeRandomId } from '../../helper';
import { useActiveElement } from '../../hooks/useActiveElement';
import MeetingConfigStackManager from './MeetingConfigStackManager';
import { MATA, AuthoringToolContext } from './localStateAndContext';
import { useKeyboardShortcuts } from 'use-keyboard-shortcuts';
import { defaultColorSelectOptions } from '../Input/ColorSelectInput';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash/fp';
import { fromError, logerror } from '../../helper/contextualLogger';
import { UserSettingsContext } from './userSettingsContext';
import { updateSceneIdForUrl } from './util';
import {
  AuthoringModalsContext,
  AUTHORING_MODALS_ACTIONS,
} from './modalStateAndContext';
import useImageColor from 'use-image-color';
import { convertToDynamicValue } from 'zync-common/helper/overrideDynamicValue';

export const notifyUser = (message, position, autoClose = 5000) => {
  toast(message, {
    position: position || toast.POSITION.BOTTOM_CENTER,
    autoClose,
    type: toast.TYPE.INFO,
    toastId: 'userMessage',
  });
};

/* Iterate over block settings and convert them back to dynamic value if there is a match in valueMap (brandkit) */
export const convertBlockSettings = (settings, valueMap, blockId) => {
  let result = {};

  for (const key in settings) {
    result[key] = convertToDynamicValue(settings[key], valueMap);
  }

  if (blockId === 'logo') {
    result.imageUrl = '{{logoUrl}}';
  }

  return result;
};

/* An utility function to lower case selected properties before these are saved to DB. */
const lowercaseCaseSensitiveProperties = (blockSettings) => {
  const newSettings = { ...blockSettings };

  // meeting controllers can only assign lower case roles, therefore we must ensure roles submitted in client are also lowercase
  if (newSettings.role) {
    newSettings.role = newSettings.role.toLowerCase();
  }

  return newSettings;
};

export const useAuthoringTool = () => {
  const {
    reducer: [stateTd, dispatchTd],
    meetingConfigStackManager,
  } = useContext(AuthoringToolContext);

  const { meetingSeriesId } = useParams();

  const initializeState = useCallback(async () => {
    const [blocks, series, sceneTemplates, defaultBackdrops] =
      await Promise.all([
        fetchBlocksApi(),
        fetchSeriesApi(meetingSeriesId),
        fetchSceneTemplatesApi(),
        fetchDefaultSceneBackdropsApi(),
      ]);
    if (!series) {
      logerror({
        meetingSeriesId,
        message:
          'Meeting series is null, cannot initialize authoring tool. meetingSeriesId:' +
          meetingSeriesId,
      });
      return;
    }

    dispatchTd({
      type: MATA.INITIALIZE_STATE_FROM_EXTERNAL_DATA,
      payload: {
        blocks,
        series,
        sceneTemplates,
        defaultBackdrops,
      },
    });
  }, [meetingSeriesId, dispatchTd]);

  const isAdvancedAuthoringModeEnabled =
    stateTd.currentSeries?.settings?.allowAdvancedAuthoring;

  const updateAuthoringMode = useCallback(
    async (authoringMode) => {
      try {
        dispatchTd({
          type: MATA.UPDATE_AUTHORING_MODE,
          payload: { authoringMode },
        });
        await updateSeriesApi(meetingSeriesId, { authoringMode });
      } catch (err) {
        logerror('Error updating authoring mode.', err);
      }
    },
    [dispatchTd, meetingSeriesId]
  );

  const refreshSceneTemplates = useCallback(async () => {
    const sceneTemplates = await fetchSceneTemplatesApi();
    dispatchTd({
      type: MATA.REFRESH_SCENE_TEMPLATES,
      payload: { sceneTemplates },
    });
  }, [dispatchTd]);

  const updateSeriesTitle = useCallback(
    async (newTitle) => {
      try {
        dispatchTd({
          type: 'UPDATE_SERIES_TITLE',
          payload: { title: newTitle },
        });
        await updateSeriesApi(meetingSeriesId, { title: newTitle });
      } catch (err) {
        logerror({
          ...fromError(err),
          message: 'Error updating series title.',
        });
      }
    },
    [meetingSeriesId, dispatchTd]
  );

  const getBlockById = useCallback(
    (blockId) =>
      stateTd.blocks
        ? stateTd.blocks.find((b) => b.blockId === blockId) ?? null
        : null,
    [stateTd.blocks]
  );

  const addSlideBlock = useCallback(
    async (block, settings = {}) => {
      const { sceneId } = stateTd.selectedSlide ?? {};
      if (!sceneId) {
        logerror({
          message: 'Cannot add block to scene. No scene selected.',
        });
        return false;
      }

      const { blockId, details } = block;
      const { defaultPosition = {}, defaultSize = {} } = details || {};
      const position = {
        ...(defaultPosition.top !== undefined
          ? { top: defaultPosition.top }
          : {}),
        ...(defaultPosition.left !== undefined
          ? { left: defaultPosition.left }
          : {}),
        ...(defaultSize.width !== undefined
          ? { width: defaultSize.width }
          : {}),
        ...(defaultSize.height !== undefined
          ? { height: defaultSize.height }
          : {}),
      };

      const newBlock = {
        blockId,
        blockInstanceId: `${blockId}-${makeRandomId(8)}`,
        settings: { ...block.settings, ...position, ...settings },
      };

      dispatchTd({
        type: MATA.ADD_SLIDE_BLOCK,
        payload: newBlock,
      });

      const result = await addBlocksApi(meetingSeriesId, sceneId, [newBlock]);

      if (result !== true) {
        logerror({
          message: `Error adding new block: ${result}.`,
        });

        return false;
      } else {
        return newBlock;
      }
    },
    [dispatchTd, meetingSeriesId, stateTd.selectedSlide]
  );

  const addDebugBlock = useCallback(async () => {
    const randomColor =
      defaultColorSelectOptions[
        Math.floor(Math.random() * defaultColorSelectOptions.length)
      ];
    const selectedSlide = stateTd.selectedSlide;
    const { slideBlocks } = selectedSlide.slideConfig;
    const options = slideBlocks
      .map((block, index) => `* ${index}: ${block.blockId}`)
      .join('\n');
    let selectedIndex = window.prompt(
      `To which block index would you like to add a debug action?\n${options}`
    );
    const blockInstance =
      slideBlocks[Number(selectedIndex)] ?? slideBlocks[slideBlocks.length - 1];
    const debugBlock = stateTd.blocks.find(
      (block) => block.blockId === 'debug-action'
    );
    return await addSlideBlock(debugBlock, {
      buttonColor: randomColor,
      blockInstanceId: blockInstance?.blockInstanceId ?? '',
    });
  }, [addSlideBlock, stateTd.blocks, stateTd.selectedSlide]);

  window.addDebugBlock = addDebugBlock;

  // Insert the new scene(s) into the current array of scenes in the state and return it.
  // THIS FUNCTION ALWAYS ACCEPTS AN ARRAY OF SCENES, EVEN IF INSERTING ONLY ONE.
  const addAndReturnNewScenesToCurrentScenesState = useCallback(
    ({ scene, index }) => {
      const { currentSeries } = stateTd;
      const { autoLaunchConfig } = currentSeries;
      const scenes =
        index === null || index === undefined
          ? [...autoLaunchConfig.slides, ...scene]
          : [
              ...autoLaunchConfig.slides.slice(0, index),
              ...scene,
              ...autoLaunchConfig.slides.slice(index),
            ];
      return { scenes };
    },
    [stateTd]
  );

  // Get the index of where we want to insert the scene(s) based on if we want to
  // insert the scene(s) after the current selected scene or not.
  const getTargetIndexToInsertNewScenes = useCallback(
    (afterCurrent) => {
      const { slides: existingScenes } = stateTd.currentSeries.autoLaunchConfig;
      const selectedScene = existingScenes.find(
        (s) => s.sceneId === stateTd.selectedSlide?.sceneId
      );
      const targetIndex =
        afterCurrent && selectedScene
          ? existingScenes.indexOf(selectedScene) + 1
          : existingScenes.length;
      return targetIndex;
    },
    [stateTd]
  );

  /** Add `images` as new slides. If `titles` is specified, `titles[k]` will be the title of the slide with `images[k]`. */
  const addImagesAsTemplateSlides = useCallback(
    async (images, titles = null) => {
      const {
        currentSeries: {
          autoLaunchConfig: { slides: currentScenes },
        },
      } = stateTd;
      const scenes = images.map((image, index) => ({
        sceneId: makeRandomId(8),
        slideUrl: image,
        slideName: titles?.[index] ?? `Slide ${index + 1}`,
        sceneDescription: '',
        slideConfig: {
          slideBlocks: [],
        },
        index: currentScenes.length + index,
        tags: [],
        visibility: 'public',
        sceneTemplateKey: '',
      }));

      const { scenes: newScenes } = addAndReturnNewScenesToCurrentScenesState({
        scene: scenes,
      });

      dispatchTd({
        type: MATA.ADD_SCENE,
        payload: { newScenes, trackScenes: scenes },
      });

      const result = await Promise.all([
        addScenesApi(meetingSeriesId, scenes),
        reorderScenesApi(
          meetingSeriesId,
          newScenes.map((scene) => scene.sceneId)
        ),
      ]);

      const isSuccess =
        result === true || result.every((bool) => bool === true);

      if (isSuccess) {
        return true;
      } else {
        logerror({
          message: `Error adding new slide: ${result}.`,
        });

        return false;
      }
    },
    [
      dispatchTd,
      meetingSeriesId,
      addAndReturnNewScenesToCurrentScenesState,
      stateTd,
    ]
  );

  const addNewSceneFromTemplate = useCallback(
    async (sceneTemplate, afterCurrent = false) => {
      const targetIndex = getTargetIndexToInsertNewScenes(afterCurrent);
      const newScene = {
        sceneId: makeRandomId(8),
        slideUrl: sceneTemplate.settings.slideUrl,
        slideName: sceneTemplate.title,
        sceneDescription: sceneTemplate.description ?? '',
        slideBackgroundColor: sceneTemplate.slideBackgroundColor,
        slideConfig: {
          slideBlocks: sceneTemplate.blocks.map((block) => ({
            ...block,
            blockInstanceId: `${block.blockId}-${makeRandomId(8)}`,
            settings: convertBlockSettings(
              block.settings,
              stateTd.currentSeries?.workspace?.brandKit,
              block.blockId
            ),
          })),
        },
        index: targetIndex,
        tags: sceneTemplate?.tags || [],
        visibility: sceneTemplate?.visibility || 'public',
        sceneTemplateKey: sceneTemplate?.sceneTemplateKey || '',
        sceneTemplateId: sceneTemplate.sceneTemplateId,
      };

      const { scenes: newScenes } = addAndReturnNewScenesToCurrentScenesState({
        scene: [newScene],
        index: targetIndex,
      });

      dispatchTd({
        type: MATA.ADD_SCENE,
        payload: { newScenes, trackScenes: [newScene] },
      });

      const result = await Promise.all([
        addScenesApi(meetingSeriesId, [newScene], targetIndex),
        reorderScenesApi(
          meetingSeriesId,
          newScenes.map((scene) => scene.sceneId)
        ),
      ]);

      const isSuccess =
        result === true || result.every((bool) => bool === true);

      if (isSuccess) {
        return true;
      } else {
        logerror({
          message: `Error adding new slide: ${result}.`,
        });

        return false;
      }
    },
    [
      dispatchTd,
      meetingSeriesId,
      addAndReturnNewScenesToCurrentScenesState,
      getTargetIndexToInsertNewScenes,
      stateTd,
    ]
  );

  const deleteTemplateScene = useCallback(
    async (sceneId) => {
      const { currentSeries, selectedSlide } = stateTd;
      const {
        autoLaunchConfig: { slides },
      } = currentSeries;
      const sceneIdToRemove = sceneId ?? selectedSlide?.sceneId;
      const removeSceneIndex = slides.findIndex(
        (slide) => slide.sceneId === sceneIdToRemove
      );

      if (removeSceneIndex === -1) return;

      const newScenes = slides.filter(
        (slide) => slide.sceneId !== sceneIdToRemove
      );

      const nextSelectedSceneIndex = Math.min(
        removeSceneIndex,
        newScenes.length - 1
      );

      dispatchTd({
        type: MATA.REMOVE_SCENE,
        payload: {
          newScenes,
          sceneId: sceneIdToRemove,
          nextSelectedSceneIndex,
        },
      });

      await Promise.all([
        deleteSceneApi(meetingSeriesId, sceneIdToRemove),
        reorderScenesApi(
          meetingSeriesId,
          newScenes.map((scene) => scene.sceneId)
        ),
      ]);
    },
    [dispatchTd, meetingSeriesId, stateTd]
  );

  const openTemplateSlide = useCallback(
    (index) => {
      const sceneId =
        stateTd.currentSeries.autoLaunchConfig.slides[index]?.sceneId;

      if (sceneId) {
        updateSceneIdForUrl(sceneId);
      }

      dispatchTd({ type: MATA.OPEN_SLIDE, payload: index });
    },
    [stateTd, dispatchTd]
  );

  const openSlideSettings = useCallback(
    () => dispatchTd({ type: MATA.OPEN_CURRENT_SLIDE_SETTINGS }),
    [dispatchTd]
  );

  const updateTemplateSlide = useCallback(
    async (value, key) => {
      if (!stateTd.selectedSlide) {
        logerror({
          message: 'Cannot update scene. No scene is selected.',
          meetingSeriesId,
        });
        return false;
      }
      const settings = value.constructor === Object ? value : { [key]: value };
      const { sceneId } = stateTd.selectedSlide;

      if (key === 'slideUrl') {
        settings['slideBackgroundColor'] = null;
      }

      dispatchTd({
        type: MATA.UPDATE_SCENE_SETTINGS,
        payload: { sceneId, settings },
      });

      const result = await updateSceneSettingsApi(
        meetingSeriesId,
        sceneId,
        settings
      );
      if (result !== true) {
        logerror({
          message: `Error updating slide settings: ${result}`,
        });
        return false;
      } else {
        return true;
      }
    },
    [dispatchTd, meetingSeriesId, stateTd]
  );

  const closeTemplateSlide = useCallback(() => {
    dispatchTd({ type: MATA.CLOSE_SLIDE });
  }, [dispatchTd]);

  const selectSlideBlock = useCallback(
    (blockInstanceId) => {
      dispatchTd({
        type: MATA.SET_SLIDE_BLOCK,
        payload: blockInstanceId ?? null,
      });
    },
    [dispatchTd]
  );

  const setActiveMenu = useCallback(
    (menu) => dispatchTd({ type: MATA.SET_ACTIVE_MENU, payload: { menu } }),
    [dispatchTd]
  );

  const setSceneLibraryOpen = useCallback(
    (open) => dispatchTd({ type: MATA.SET_SCENE_LIBRARY_OPEN, payload: open }),
    [dispatchTd]
  );

  const reorderTemplateSlides = useCallback(
    async (newOrderedScenes) => {
      dispatchTd({
        type: MATA.UPDATE_TEMPLATE_SLIDES_ORDER,
        payload: newOrderedScenes,
      });

      const result = await reorderScenesApi(
        meetingSeriesId,
        newOrderedScenes.map((scene) => scene.sceneId)
      );
      if (result !== true) {
        logerror({
          message: `Error updating slide order: ${result}`,
        });
        return false;
      } else {
        return true;
      }
    },
    [dispatchTd, meetingSeriesId]
  );

  const updateSlideBlock = useCallback(
    async (
      value,
      key,
      blockInstanceId = null,
      blockId = null,
      persist = true,
      /* overrides default block update  */
      optimisticUpdate
    ) => {
      const settingsUpdate =
        value && value.constructor === Object ? value : { [key]: value };
      const { sceneId } = stateTd.selectedSlide;
      const block = stateTd.selectedSlideBlock;
      blockInstanceId = blockInstanceId ?? block.blockInstanceId;
      blockId = blockId ?? block.blockId;

      const newSettings = lowercaseCaseSensitiveProperties(settingsUpdate);

      if (optimisticUpdate) {
        optimisticUpdate();
      } else {
        dispatchTd({
          type: MATA.UPDATE_BLOCK_SETTINGS,
          payload: { settings: newSettings, blockInstanceId },
        });
      }

      if (persist) {
        const updateResult = await updateBlockSettingsApi(
          meetingSeriesId,
          sceneId,
          blockInstanceId,
          blockId,
          newSettings
        );

        if (updateResult !== true) {
          logerror({
            message: `Error updating block settings on ${blockInstanceId}: ${updateResult}. New settings: ${JSON.stringify(
              newSettings
            )}`,
            meetingSeriesId,
            sceneId,
          });
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    [meetingSeriesId, stateTd, dispatchTd]
  );

  const copySelectedBlock = useCallback(() => {
    const sourceBlock = stateTd.selectedSlideBlock;
    if (!sourceBlock) {
      logerror({
        message: 'Cannot copy block. No block selected.',
      });
      return false;
    }

    const { blockId } = sourceBlock;

    const dimensions = {
      top: sourceBlock.settings.top,
      left: sourceBlock.settings.left,
      width: sourceBlock.settings.width,
      height: sourceBlock.settings.height,
    };

    const copiedBlock = {
      blockId,
      settings: convertBlockSettings(
        {
          ...sourceBlock.settings,
          ...dimensions,
        },
        stateTd.currentSeries.workspace.brandKit
      ),
    };

    dispatchTd({
      type: MATA.COPY_BLOCK,
      payload: copiedBlock,
    });

    return copiedBlock;
  }, [
    dispatchTd,
    stateTd.selectedSlideBlock,
    stateTd.currentSeries.workspace.brandKit,
  ]);

  const pasteBlock = useCallback(
    async (blockObj = null) => {
      const { copiedBlock } = stateTd;
      const obj = blockObj || copiedBlock;
      if (obj) {
        const block = getBlockById(obj.blockId);
        await addSlideBlock(block, obj.settings);
      } else {
        logerror({
          message: 'Cannot paste block, no block has been copied.',
        });
      }
    },
    [stateTd, addSlideBlock, getBlockById]
  );

  const copySelectedScene = useCallback(
    (selectedSlide = undefined) => {
      const currentScene = selectedSlide || stateTd.selectedSlide;

      if (!currentScene) {
        logerror({
          message: 'Cannot copy scene, no scene is selected.',
        });
        return;
      }

      const template = {
        sceneTemplateId: makeRandomId(8),
        title: currentScene.slideName,
        description: currentScene.sceneDescription ?? '',
        slideBackgroundColor: currentScene.slideBackgroundColor || null,
        thumbnailUrl: null,
        settings: {
          slideUrl: currentScene.slideUrl,
        },
        sceneTemplateKey: currentScene.sceneTemplateKey,
        blocks: currentScene.slideConfig.slideBlocks.map((block) => ({
          blockId: block.blockId,
          settings: convertBlockSettings(
            { ...block.settings },
            stateTd.currentSeries.workspace.brandKit
          ),
        })),
      };

      dispatchTd({ type: MATA.COPY_SCENE, payload: template });

      return template;
    },
    [
      dispatchTd,
      stateTd.selectedSlide,
      stateTd.currentSeries.workspace.brandKit,
    ]
  );

  const pasteScene = useCallback(
    async (sceneObj = null) => {
      const { copiedScene } = stateTd;
      return await addNewSceneFromTemplate(sceneObj ?? copiedScene, true);
    },
    [stateTd, addNewSceneFromTemplate]
  );

  const removeSelectedSlideBlock = useCallback(
    async (removedBlock = null) => {
      const { sceneId } = stateTd.selectedSlide;
      const { blockInstanceId } =
        removedBlock || stateTd.selectedSlideBlock || {};
      const block = stateTd.selectedSlide.slideConfig.slideBlocks.find(
        (block) => block.blockInstanceId === blockInstanceId
      );
      if (block) {
        dispatchTd({
          type: MATA.REMOVE_SELECTED_SLIDE_BLOCK,
          payload: { blockInstanceId },
        });

        const result = await deleteBlockInstanceApi(
          meetingSeriesId,
          sceneId,
          blockInstanceId
        );
        if (result !== true) {
          logerror({
            message: `Failed to remove block ${blockInstanceId} from slide on server.`,
          });

          return false;
        } else {
          return true;
        }
      } else {
        logerror({
          message: `Could not find block ${blockInstanceId} to remove.`,
          meetingSeriesId,
        });
      }
    },
    [
      dispatchTd,
      meetingSeriesId,
      stateTd.selectedSlide,
      stateTd.selectedSlideBlock,
    ]
  );

  const updateSlideBlockDimensions = useCallback(
    ({ blockInstanceId, top, left, height, width }, persist = true) => {
      const editedBlock = stateTd.selectedSlide.slideConfig.slideBlocks.find(
        (block) => block.blockInstanceId === blockInstanceId
      );

      if (editedBlock) {
        const previousSettings = editedBlock.settings;

        updateSlideBlock(
          {
            width: width !== undefined ? width : previousSettings.width,
            height: height !== undefined ? height : previousSettings.height,
            top: top !== undefined ? top : previousSettings.top,
            left: left !== undefined ? left : previousSettings.left,
          },
          null,
          blockInstanceId,
          editedBlock.blockId,
          persist
        );
      }
    },
    [stateTd.selectedSlide, updateSlideBlock]
  );

  const importGoogleSlides = useCallback(
    async (presentationId) => {
      try {
        const slidesImport = await addGoogleSlidesToMeetingSeriesApi(
          meetingSeriesId,
          presentationId
        );
        dispatchTd({
          type: MATA.ADD_BACKDROP_IMPORT,
          payload: slidesImport,
        });
        return true;
      } catch (error) {
        logerror({
          ...fromError(error),
          message: 'Error importing from google slides',
        });
        return false;
      }
    },
    [dispatchTd, meetingSeriesId]
  );

  const setCurrentBackdropTab = useCallback(
    (newTab) => {
      dispatchTd({
        type: MATA.SELECT_BACKDROP_TAB,
        payload: newTab,
      });
    },
    [dispatchTd]
  );

  const canRedo = meetingConfigStackManager?.canRedo();
  const canUndo = meetingConfigStackManager?.canUndo();

  const isUpdatingState = stateTd.status.isUpdatingState;

  const handleCommand = useCallback(
    async (commandType) => {
      if (isUpdatingState) {
        return;
      }

      if (
        (commandType === MeetingConfigStackManager.COMMANDS.REDO &&
          !meetingConfigStackManager.canRedo()) ||
        (commandType === MeetingConfigStackManager.COMMANDS.UNDO &&
          !meetingConfigStackManager.canUndo())
      ) {
        return;
      }

      dispatchTd({
        type: MATA.START_UPDATE_STATE,
      });

      try {
        meetingConfigStackManager[commandType](); // calls redo or undo, depending on commandType provided

        const currentSnapshot = meetingConfigStackManager.restore();

        await updateSeriesApi(
          stateTd.currentSeries.meetingSeriesId,
          currentSnapshot.currentSeries
        );

        dispatchTd({
          type: MATA.REFRESH_STATE_FROM_SNAPSHOT,
          payload: currentSnapshot,
        });

        setTimeout(() => {
          dispatchTd({
            type: MATA.FINISH_UPDATE_STATE,
          });

          /*
            React Draggable library adds left and top properties to dragged elements when resizing by the left and top edge.
            When undoing or redoing, we want to clean up this effect to maintain the correct position of the draggable element.
          */
          const cleanupReactDraggableStyles = () => {
            const draggedElementClassName = 'react-draggable-dragged'; // React Draggable class applied to all dragged elements

            const draggedElements = document.querySelectorAll(
              `.${draggedElementClassName}`
            );

            draggedElements.forEach((element) => {
              element.style.left = null;
              element.style.top = null;
              element.classList.remove(draggedElementClassName);
            });
          };

          cleanupReactDraggableStyles();
        }, 0);
      } catch (e) {
        notifyUser(`Could not perform ${commandType}. Please try again.`);
        dispatchTd({
          type: MATA.FINISH_UPDATE_STATE,
        });
      }
    },
    [
      meetingConfigStackManager,
      dispatchTd,
      isUpdatingState,
      stateTd.currentSeries.meetingSeriesId,
    ]
  );

  const { colors } = useImageColor(stateTd.selectedSlide?.slideUrl, {
    cors: true,
    colors: 2,
  });

  /* Auto color is calculated dynamically based on color palette from image*/
  const selectedSlideBackgroundAutoColor = colors?.[0];

  const handleCopy = useCallback(
    async ({ notify = true } = {}) => {
      const allowAdvancedAuthoring =
        stateTd.currentSeries.settings.allowAdvancedAuthoring;

      if (stateTd.selectedSlideBlock && allowAdvancedAuthoring) {
        const blockObj = copySelectedBlock();
        await copyToClipboard(blockObj);
        const block = getBlockById(stateTd.selectedSlideBlock.blockId);
        notify &&
          notifyUser(`${block.details.title} block copied to clipboard.`);
      } else if (stateTd.selectedSlide) {
        const sceneObj = copySelectedScene();
        await copyToClipboard(sceneObj);
        notify && notifyUser(`Scene ${sceneObj.title} copied.`);
      }
    },
    [
      getBlockById,
      copySelectedScene,
      copySelectedBlock,
      stateTd.currentSeries.settings.allowAdvancedAuthoring,
      stateTd.selectedSlide,
      stateTd.selectedSlideBlock,
    ]
  );

  const handleUpdateSlideBlocks = useCallback(() => {
    dispatchTd({ type: MATA.UPDATE_SCENE_BLOCKS, payload: [] });
  }, [dispatchTd]);

  window.stateTd = stateTd;

  return useMemo(
    () => ({
      ...stateTd,
      stateTd,
      dispatchTd,
      initializeState,
      updateSeriesTitle,
      addNewSceneFromTemplate,
      openTemplateSlide,
      openSlideSettings,
      setActiveMenu,
      setSceneLibraryOpen,
      updateTemplateSlide,
      closeTemplateSlide,
      deleteTemplateScene,
      selectSlideBlock,
      addSlideBlock,
      addDebugBlock,
      copySelectedBlock,
      copySelectedScene,
      pasteBlock,
      pasteScene,
      updateSlideBlock,
      getBlockById,
      removeSelectedSlideBlock,
      updateSlideBlockDimensions,
      importGoogleSlides,
      addImagesAsTemplateSlides,
      reorderTemplateSlides,
      refreshSceneTemplates,
      meetingSeriesId,
      setCurrentBackdropTab,
      canRedo,
      canUndo,
      handleCommand,
      updateAuthoringMode,
      selectedSlideBackgroundAutoColor,
      handleCopy,
      handleUpdateSlideBlocks,
      isAdvancedAuthoringModeEnabled,
    }),
    [
      stateTd,
      dispatchTd,
      initializeState,
      updateSeriesTitle,
      addNewSceneFromTemplate,
      openTemplateSlide,
      openSlideSettings,
      setActiveMenu,
      setSceneLibraryOpen,
      updateTemplateSlide,
      closeTemplateSlide,
      deleteTemplateScene,
      selectSlideBlock,
      addSlideBlock,
      addDebugBlock,
      copySelectedBlock,
      copySelectedScene,
      pasteBlock,
      pasteScene,
      updateSlideBlock,
      getBlockById,
      removeSelectedSlideBlock,
      updateSlideBlockDimensions,
      importGoogleSlides,
      addImagesAsTemplateSlides,
      reorderTemplateSlides,
      refreshSceneTemplates,
      meetingSeriesId,
      setCurrentBackdropTab,
      canRedo,
      canUndo,
      handleCommand,
      updateAuthoringMode,
      selectedSlideBackgroundAutoColor,
      handleCopy,
      handleUpdateSlideBlocks,
      isAdvancedAuthoringModeEnabled,
    ]
  );
};

export const copyToClipboard = async (obj) => {
  try {
    const str = JSON.stringify(obj, null, '  ');
    await navigator.clipboard.writeText(str);
  } catch (err) {
    logerror({
      ...fromError(err),
      message: 'error copying to clipboard',
    });
  }
};

export const getFromClipboard = async () => {
  let result = null;

  try {
    const str = await navigator.clipboard.readText();

    if (str) {
      try {
        result = JSON.parse(str);
      } catch (error) {
        // in this case clipboard content is most probably not a JSON, but simple text
        result = str;
      }

      return result;
    }
    const obj = str ? JSON.parse(str) : null;

    return obj;
  } catch (err) {
    logerror({
      ...fromError(err),
      message: 'error reading value from clipboard',
    });
    return null;
  }
};

export const getImageFromClipboard = (pasteEvent) => {
  const clipboardItems = pasteEvent.clipboardData.items;

  const items = [].slice.call(clipboardItems).filter(function (item) {
    return item.type.indexOf('image') !== -1;
  });

  if (items.length) {
    const item = items[0];

    return item.getAsFile();
  }
};

const checkIsNotEditingText = (activeElement) => {
  const isEditable =
    (activeElement.nodeType === 1 &&
      activeElement.contentEditable === 'true') ||
    activeElement.classList.contains('public-DraftEditor-content');

  if (isEditable) {
    return false;
  }

  const isTextInput =
    activeElement.nodeType === 1 &&
    (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA');

  if (isTextInput) {
    return !/^(?:text|email|number|search|tel|url|password)$/i.test(
      activeElement.type
    );
  }

  return true;
};

export const useAuthoringKeyboardShortcuts = () => {
  const { isDeleteBlockHidden } = useContext(UserSettingsContext);

  const {
    addSlideBlock,
    pasteBlock,
    pasteScene,
    getBlockById,
    copiedBlock,
    copiedScene,
    selectedSlideBlock,
    blocks,
    meetingSeriesId,
    handleCommand,
    removeSelectedSlideBlock,
    handleCopy,
    currentSeries: {
      settings: { allowAdvancedAuthoring },
    },
  } = useAuthoringTool();

  const activeElement = useActiveElement();
  const isEnabled = checkIsNotEditingText(activeElement);

  const handlePaste = useCallback(
    async (event) => {
      if (!isEnabled) {
        return;
      }

      const image = getImageFromClipboard(event.nativeEvent);

      if (image && allowAdvancedAuthoring) {
        const fileReader = new FileReader();

        fileReader.onloadend = async () => {
          const imageBlock = cloneDeep(
            blocks.find((block) => block.blockId === 'image')
          );

          notifyUser(`Pasting ${imageBlock.details.title} - ${image.name}`);

          // we use timestamp to support cases when we try to upload image of the same file name,
          // such as images from clipboard and screen capture features
          const timeStamp = new Date().toISOString();

          const newImageUrl = await uploadBlockImage(
            meetingSeriesId + '/' + image.name + timeStamp,
            fileReader.result,
            image.type
          );

          if (imageBlock) {
            imageBlock.settings.imageUrl = newImageUrl;
          }

          await addSlideBlock(imageBlock);

          notifyUser(`${imageBlock.details.title} Block created.`);
        };

        fileReader.readAsArrayBuffer(image);
      } else if (copiedScene) {
        pasteScene();
        notifyUser(
          `Scene ${copiedScene.title} pasted after the current scene.`
        );
      } else if (copiedBlock && allowAdvancedAuthoring) {
        pasteBlock();
        const block = getBlockById(copiedBlock.blockId);
        notifyUser(`${block.details.title} block pasted with overlap.`);
      } else {
        const clipboard = await getFromClipboard();
        if (clipboard) {
          if (clipboard.sceneTemplateId) {
            const template = {
              sceneTemplateId: String(clipboard.sceneTemplateId),
              slideBackgroundColor: clipboard.slideBackgroundColor || null,
              title: String(clipboard.title),
              description: String(clipboard.description),
              thumbnailUrl: String(clipboard.thumbnailUrl) ?? null,
              settings: {
                slideUrl: String(clipboard.settings?.slideUrl) ?? null,
              },
              blocks: (clipboard.blocks ?? []).map((block) => ({
                blockId: String(block.blockId),
                settings: { ...block.settings },
              })),
            };
            await pasteScene(template);
          } else if (clipboard.blockId && allowAdvancedAuthoring) {
            await pasteBlock(clipboard);
          }
        }
      }
    },
    [
      addSlideBlock,
      blocks,
      getBlockById,
      copiedBlock,
      copiedScene,
      pasteBlock,
      pasteScene,
      meetingSeriesId,
      isEnabled,
      allowAdvancedAuthoring,
    ]
  );

  const [showDeleteBlock, setShowDeleteBlock] = useState(false);
  const [showDeleteScene, setShowDeleteScene] = useState(false);
  const handleDelete = useCallback(() => {
    if (selectedSlideBlock && allowAdvancedAuthoring) {
      isDeleteBlockHidden
        ? removeSelectedSlideBlock(selectedSlideBlock)
        : setShowDeleteBlock(true);
    }
  }, [
    allowAdvancedAuthoring,
    isDeleteBlockHidden,
    removeSelectedSlideBlock,
    selectedSlideBlock,
    setShowDeleteBlock,
  ]);

  useKeyboardShortcuts(
    [
      {
        keys: ['ctrl', 'c'],
        onEvent: handleCopy,
      },
      {
        keys: ['ctrl', 'z'],
        onEvent: () => handleCommand(MeetingConfigStackManager.COMMANDS.UNDO),
      },
      {
        keys: ['ctrl', 'shift', 'z'],
        onEvent: () => handleCommand(MeetingConfigStackManager.COMMANDS.REDO),
      },
      { keys: ['Delete'], onEvent: handleDelete },
      { keys: ['Backspace'], onEvent: handleDelete },
    ],
    isEnabled,
    [handleCopy, handlePaste, handleCommand, handleDelete]
  );

  const showModal = showDeleteBlock
    ? 'block'
    : showDeleteScene
    ? 'scene'
    : null;
  const setShowModal = useCallback(
    (modal) => {
      setShowDeleteScene(modal === 'scene' ? 'scene' : null);
      setShowDeleteBlock(modal === 'block' ? 'block' : null);
    },
    [setShowDeleteScene, setShowDeleteBlock]
  );

  return {
    showModal,
    setShowModal,
    handlePaste,
  };
};

export const useAuthoringModals = () => {
  const [authoringModalsState, authoringModalsDispatch] = useContext(
    AuthoringModalsContext
  );

  const showDeleteSceneConfirmationModal = useCallback(
    (sceneId) => {
      const { deleteSceneConfirmationModalOpen } = authoringModalsState;
      if (deleteSceneConfirmationModalOpen) return;
      authoringModalsDispatch({
        type: AUTHORING_MODALS_ACTIONS.SHOW_DELETE_SCENE_CONFIRMATION_MODAL,
        data: { sceneId },
      });
    },
    [authoringModalsState, authoringModalsDispatch]
  );

  const hideDeleteSceneConfirmationModal = useCallback(() => {
    const { deleteSceneConfirmationModalOpen } = authoringModalsState;
    if (!deleteSceneConfirmationModalOpen) return;
    authoringModalsDispatch({
      type: AUTHORING_MODALS_ACTIONS.HIDE_DELETE_SCENE_CONFIRMATION_MODAL,
    });
  }, [authoringModalsState, authoringModalsDispatch]);

  return {
    ...authoringModalsState,
    authoringModalsDispatch,
    showDeleteSceneConfirmationModal,
    hideDeleteSceneConfirmationModal,
  };
};
