import { Link, useHistory } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { getTimezoneAbbreviation } from '../../helper/rsvp';
import { ClockIcon } from '../../components/icons/ClockIcon';
import { LinkIcon } from '../../components/icons/LinkIcon';
import { EventLink } from '../NewPortal';
import { getTextFromRichText } from 'zync-common/helper/richText';
import { convertUTCToTimezone } from 'zync-common/helper/timezone';
import {
  OPENING_DIRECTIONS,
  ThreeDotMenuTrigger,
} from '../../components/common/ThreeDotMenuTrigger';
import { createNewSeriesApi } from '../../helper/api';
import mixpanel from 'mixpanel-browser';
import { getMeetingSeriesProps } from 'zync-common/tracking';
import { fromError, logerror } from '../../helper/contextualLogger';
import {
  createNewMeetingTitle,
  prepareAutolaunchConfig,
  sanitizeScheduledEvent,
} from '../../components/MeetingCard';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { usePosterSlide } from '../../components/series/settings/usePosterSlide';
import { ScenePreview } from '../../components/authoring/Menu/ScenePreview';
import { getSlideTemplateKey } from './CreateNewEventModal';
import { useElementSize } from '../../hooks/useElementSize';
import { isMobile } from '../../helper';
import { useIsMinutesBefore } from '../../hooks/useIsMinutesBefore';

const sortByType = (eventPresenters) => {
  return eventPresenters.slice().sort((a) => {
    if (a.type === 'host') return 1; // hosts are on the far side
    if (a.type === 'speaker') return -1; // speakers are on the far left
    return 0;
  });
};

const LivePosterSlide = ({ series, posterSlideWidth }) => {
  const eventPresentersExcludingHostCount =
    series?.settings?.eventPresenters?.length - 1 || 0;
  const { posterSlide } = usePosterSlide(
    series,
    undefined,
    false,
    getSlideTemplateKey(eventPresentersExcludingHostCount)
  );

  return (
    <ScenePreview
      scene={posterSlide}
      width={posterSlideWidth ? posterSlideWidth : 144}
      height={posterSlideWidth ? (posterSlideWidth / 16) * 9 : (144 / 16) * 9}
      brandKit={series?.workspace?.brandKit}
    />
  );
};
export const useDuplicateEvent = ({ series }) => {
  const history = useHistory();

  const [isUpdating, setIsUpdating] = useState(false);
  const emailAddress = useSelector((_st) => _st.auth.user?.emailAddress);

  const handleDuplicateEvent = async () => {
    if (isUpdating) {
      return;
    }

    setIsUpdating(true);

    const workspaceId = series?.workspace?.workspaceId;

    const { description, title, visibility, scheduledEvent } = series;

    const eventTitle = createNewMeetingTitle(title);

    try {
      const newSeries = await createNewSeriesApi(
        workspaceId,
        emailAddress,
        eventTitle,
        description,
        visibility,
        prepareAutolaunchConfig(series),
        sanitizeScheduledEvent(scheduledEvent),
        undefined,
        undefined,
        undefined,
        series.AIGenerationStatuses,
        series.eventLandingPageTaskStatuses,
        series.eventRegistrationSocialMediaPosts
      );

      history.push(`/e/${newSeries.meetingSeriesId}/details`);

      return mixpanel.track('Portal - Series Created', {
        ...getMeetingSeriesProps(newSeries),
      });
    } catch (error) {
      setIsUpdating(false);
      logerror(fromError(error));
    }
  };

  return { handleDuplicateEvent, isUpdating };
};

const MS_BEFORE_EVENT_TO_SHOW_REHEARSAL = 900000;

export const isNotDefaultPoster = (posterUrl) =>
  posterUrl !==
  'https://storage.googleapis.com/zync-media/assets/static/default_rsvp_poster.png';
export const EventCard = ({
  series,
  completed,
  showPoster = true,
  showLinks = true,
  showImages = true,
  setShowDesktopOnlyModal,
}) => {
  const { scheduledEvent, settings, meetingSeriesUrl, meetingSeriesId } =
    series || {};
  const { startDate, endDate, timezone } = scheduledEvent || {};
  const { width: containerWidth, ref: containerRef } = useElementSize();
  const timezoneAbbreviation = useMemo(
    () => getTimezoneAbbreviation(timezone || 'Pacific Standard Time'),
    [timezone]
  );

  const convertedScheduledEvent = useMemo(
    () => ({
      startTime:
        startDate && timezone
          ? convertUTCToTimezone(startDate, timezone, 'time')
          : null,
      endTime:
        endDate && timezone
          ? convertUTCToTimezone(endDate, timezone, 'time')
          : null,
      date:
        startDate && timezone
          ? convertUTCToTimezone(startDate, timezone, 'day-month')
          : null,
    }),
    [endDate, startDate, timezone]
  );

  const { startTime, endTime, date } = convertedScheduledEvent || {};

  const { isBefore } = useIsMinutesBefore({
    date: startDate,
    msBefore: MS_BEFORE_EVENT_TO_SHOW_REHEARSAL,
  });

  const isStartingIn15Minutes = !isBefore;

  const { handleDuplicateEvent, isUpdating } = useDuplicateEvent({
    series,
  });

  const threeDotMenuItems = [
    {
      key: 1,
      text: (
        <div className="w-40 flex gap-2">
          <span className={`text-sm ${isUpdating ? 'opacity-70' : ''}`}>
            Duplicate Event
          </span>{' '}
          {isUpdating && <LoadingSpinner width="20px" />}
        </div>
      ),
      action: () => (isUpdating ? undefined : handleDuplicateEvent()),
    },
  ];

  return (
    <article
      className={`flex md:gap-6 gap-2 p-4 border border-[#E6E8F0] relative md:items-center rounded-2xl md:flex-row flex-col ${
        completed ? 'bg-purple/5' : 'bg-white'
      }`}
    >
      <div className="absolute top-0 right-0 md:block hidden">
        <ThreeDotMenuTrigger
          items={threeDotMenuItems}
          openDirection={OPENING_DIRECTIONS.BOTTOM_TO_TOP}
        />
      </div>
      {showPoster && (
        <div
          className="md:w-[144px] md:h-[81px] cursor-pointer w-full rounded-xl overflow-hidden"
          ref={containerRef}
        >
          <EventLink
            event={series}
            setShowDesktopOnlyModal={setShowDesktopOnlyModal}
          >
            {!completed &&
            series.settings.posterImageUrl &&
            isNotDefaultPoster(series.settings.posterImageUrl) ? (
              <img
                src={series.settings.posterImageUrl}
                alt=""
                className="w-full h-full object-contain"
              />
            ) : completed && series.settings.eventPosterImage ? (
              <img
                src={series.settings.eventPosterImage}
                alt=""
                className="w-full h-full object-contain"
              />
            ) : (
              <LivePosterSlide
                series={series}
                posterSlideWidth={containerWidth}
              />
            )}
          </EventLink>
        </div>
      )}
      <div className="flex-[10_0_0%]">
        <EventLink
          event={series}
          setShowDesktopOnlyModal={setShowDesktopOnlyModal}
        >
          <h2 className="text-sm font-medium mb-2 cursor-pointer">
            {getTextFromRichText(series.title)}
          </h2>
        </EventLink>

        <div className="mb-4">
          <p className="text-blue-gray text-xs font-medium flex items-center gap-2">
            <ClockIcon className="fill-purple" />
            <>
              {startTime && endTime && timezone
                ? `${startTime} to ${endTime} ${date} ${timezoneAbbreviation}`
                : 'Time not set'}
            </>
          </p>
        </div>

        {showLinks && (
          <div className="flex gap-6">
            {completed ? (
              !isMobile ? (
                <Link
                  to={`/e/${meetingSeriesId}/details/publish`}
                  className="flex gap-1.5 text-blue-light text-xs font-medium underline"
                >
                  <LinkIcon /> Content Kit
                </Link>
              ) : (
                <p className="text-xs text-blue font-medium">
                  Access Content Kit from desktop
                </p>
              )
            ) : (
              <>
                <a
                  href={`${meetingSeriesUrl}/register`}
                  target="_blank"
                  rel="noreferrer"
                  className="flex gap-1.5 text-blue-light text-xs font-medium underline"
                >
                  <LinkIcon /> Registration Page
                </a>

                {isStartingIn15Minutes ? (
                  <Link
                    to={`/e/${meetingSeriesId}/live`}
                    className="flex gap-1.5 text-blue-light text-xs font-medium underline"
                  >
                    <LinkIcon /> Launch Episode
                  </Link>
                ) : (
                  <Link
                    to={`/e/${meetingSeriesId}/live?preview`}
                    className="flex gap-1.5 text-blue-light text-xs font-medium underline"
                  >
                    <LinkIcon /> Launch Rehearsal
                  </Link>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div
        className={`flex-[8_0_0%] flex flex-wrap mb-auto gap-y-2 ${
          showImages ? 'gap-x-4' : 'gap-x-7'
        }`}
      >
        {sortByType(settings.eventPresenters).map((eventPresenter, index) => (
          <div
            key={`${eventPresenter.fullName}-${meetingSeriesId}-${index}`}
            className="flex gap-1.5 items-center"
          >
            {showImages && (
              <div className="w-[36px] h-[36px] rounded-full overflow-hidden">
                <img
                  alt=""
                  className="h-full object-cover"
                  src={
                    eventPresenter.user?.avatarUrl &&
                    !eventPresenter.user?.avatarUrl.includes('undefined')
                      ? eventPresenter.user?.avatarUrl
                      : eventPresenter.presenterPictureUrl
                  }
                />
              </div>
            )}
            <p className="text-sm text-light-gray">
              {eventPresenter.user?.userName || eventPresenter.fullName}
            </p>
          </div>
        ))}
      </div>
    </article>
  );
};
