import { createNewQuestionSlide } from './useMeetingCreator';
import { episodeBuilder } from '../../helper/episodeBuilder';
import { episodeTypes } from 'zync-common/types';

export const createSoloInterviewEpisode = async ({
  title,
  eventSpeakers,
  questions,
  episodeType,
}) => {
  const { init, build } = episodeBuilder({
    title,
    slides: [
      {
        sceneTemplateKey: 'scene_lobby',
      },
      {
        sceneTemplateKey: 'solo_internal_studio_intro',
      },
      {
        sceneTemplateKey: 'solo_internal_setup_camera',
      },
      ...questions.map((questionSlide) => ({
        sceneTemplateKey: 'scene_solo_segment_5',
        mapper: (scene) => createNewQuestionSlide(scene, questionSlide),
      })),
      {
        sceneTemplateKey: 'solo_internal_specific_questions',
      },
    ],
    templateKey: 'meeting_speaker_interview_1x1',
    options: {
      eventPresenters: [...eventSpeakers],
      episodeType: episodeType || episodeTypes.solo,
    },
  });

  await init();

  return build();
};
