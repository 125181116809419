import React from 'react';
import { CONTENT_KIT_STATES } from './PublishMenus';
import { Menu } from './PublishV2';
import { makeRandomId } from '../../../helper';
import Masonry from 'react-masonry-css';
import './MenuStyles.css';

export const EventNotCompletedView = ({ menus }) => {
  return (
    <div className="flex flex-col justify-center items-center py-[60px]">
      <div className="text-blue-dark text-2xl font-semibold text-center mb-4">
        Session Not Yet Completed
      </div>
      <div className="text-blue-gray text-sm font-medium text-center mb-[30px]">
        You will see your content kit here once you complete this session
      </div>
      <a
        href={'https://app.zync.ai/e/w6SsxSB6/contentkit'}
        target="_blank"
        rel="noopener noreferrer"
        className="py-4 px-6 bg-purple shadow-800 rounded mb-10"
      >
        <div className="text-white text-center text-sm font-medium">
          Sample Content Kit
        </div>
      </a>
      <Masonry
        breakpointCols={2}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {menus.map((m) => (
          <Menu
            menu={m}
            key={`menu-${makeRandomId(6)}`}
            contentKitState={CONTENT_KIT_STATES.incomplete}
          />
        ))}
      </Masonry>
    </div>
  );
};
