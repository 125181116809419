import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';

const UnlockPotential = React.memo(() => (
  <p className="text-blue-gray font-normal">
    Unlock the true potential of Zync by upgrading to one of our premium plans.
  </p>
));

const ContactYourAdmin = React.memo(({ billingAdminEmail }) => (
  <p className="text-blue-gray font-normal">
    Contact <span className="text-purple">{billingAdminEmail}</span> to upgrade.
  </p>
));

const UpgradeFreePlan = React.memo(({ planName, maxAttendees }) => (
  <p className="text-blue-dark font-semibold mb-0.5">
    Your Zync <span className="text-purple">{planName} plan</span> only supports{' '}
    {maxAttendees} users. One or more users are unable to join the meeting.
  </p>
));

const FeatureNotSupported = React.memo(({ planName, featureName }) => (
  <p className="text-blue-dark font-semibold mb-0.5">
    Your current <span className="text-purple">{planName} plan</span> does not
    support {featureName}
  </p>
));

const MeetingEndsIn = React.memo(
  ({
    /* Time to end to be displayed. Provide in format mm:ss */
    timeToEnd,

    /* Whether to run time to end countdown ticking each second */
    showDynamicCountdown,
  }) => {
    const [timeToEndDisplayed, setTimeToEndDisplayed] = useState(timeToEnd);

    useEffect(() => {
      const runCountdown = () => {
        const interval = setInterval(() => {
          setTimeToEndDisplayed((prevTimeToEndDisplayed) => {
            const timeParsed = moment(prevTimeToEndDisplayed, 'mm:ss');
            const seconds = timeParsed.seconds();
            const minutes = timeParsed.minutes();

            /* if there are minutes or seconds left, we count till 00:00 */
            const shouldCount = Boolean(seconds || minutes);

            if (shouldCount) {
              return timeParsed.subtract(1, 'seconds').format('mm:ss');
            }

            clearInterval(interval);

            return prevTimeToEndDisplayed;
          });
        }, 1000);

        return () => {
          clearInterval(interval);
        };
      };

      if (showDynamicCountdown) {
        runCountdown();
      }
    }, [showDynamicCountdown]);

    return (
      <span className="text-blue-dark font-semibold mb-0.5">
        This meeting will end in{' '}
        <span className="text-purple">{timeToEndDisplayed}</span>.
      </span>
    );
  }
);

const YourMeetingIsEnding = React.memo(
  ({ planName, maxMinutes, timeToEnd, showDynamicCountdown }) => (
    <p className="text-blue-dark font-semibold mb-0.5">
      <MeetingEndsIn
        timeToEnd={timeToEnd}
        showDynamicCountdown={showDynamicCountdown}
      />{' '}
      Zync's {planName} plan only allows for{' '}
      <span className="text-purple">{maxMinutes}:00 minute</span> meetings.
    </p>
  )
);

const MessagePartials = {
  UnlockPotential,
  ContactYourAdmin,
  UpgradeFreePlan,
  FeatureNotSupported,
  MeetingEndsIn,
  YourMeetingIsEnding,
};

export default MessagePartials;
