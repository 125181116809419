import React from 'react';
import {
  enforcementMessagesReceivers,
  planEnforcementTypes,
  PLANS,
} from 'zync-common/zyncCustomerPlans';

import {
  MeetingFullSuperAdmin,
  MeetingFullModerator,
  RecordingUnsupportedSuperAdmin,
  RecordingUnsupportedModerator,
  StreamingUnsupportedSuperAdmin,
  StreamingUnsupportedModerator,
  MeetingEndingShortlySuperAdmin,
  MeetingEndingShortlyModerator,
  MeetingEndingShortlyGuest,
  MeetingFullAdmin,
  RecordingUnsupportedAdmin,
  StreamingUnsupportedAdmin,
  MeetingEndingShortlyAdmin,
} from './Messages';

/* determines a valid message to display, depending of enforcement type and user receiver */
export const PlanEnforcementMessagesMap = {
  [planEnforcementTypes.MEETING_FULL]: {
    [enforcementMessagesReceivers.SUPER_ADMIN]: MeetingFullSuperAdmin,
    [enforcementMessagesReceivers.ADMIN]: MeetingFullAdmin,
    [enforcementMessagesReceivers.MODERATOR]: MeetingFullModerator,
  },
  [planEnforcementTypes.RECORDING_NOT_SUPPORTED]: {
    [enforcementMessagesReceivers.SUPER_ADMIN]: RecordingUnsupportedSuperAdmin,
    [enforcementMessagesReceivers.ADMIN]: RecordingUnsupportedAdmin,
    [enforcementMessagesReceivers.MODERATOR]: RecordingUnsupportedModerator,
  },
  [planEnforcementTypes.STREAMING_NOT_SUPPORTED]: {
    [enforcementMessagesReceivers.SUPER_ADMIN]: StreamingUnsupportedSuperAdmin,
    [enforcementMessagesReceivers.ADMIN]: StreamingUnsupportedAdmin,
    [enforcementMessagesReceivers.MODERATOR]: StreamingUnsupportedModerator,
  },
  [planEnforcementTypes.TIME_EXPIRING]: {
    [enforcementMessagesReceivers.SUPER_ADMIN]: MeetingEndingShortlySuperAdmin,
    [enforcementMessagesReceivers.ADMIN]: MeetingEndingShortlyAdmin,
    [enforcementMessagesReceivers.MODERATOR]: MeetingEndingShortlyModerator,
    [enforcementMessagesReceivers.GUEST]: MeetingEndingShortlyGuest,
  },
};

/* main enforcement message component to render */
const PlanEnforcementMessage = ({
  isSubscriptionSetup,
  currentMeetingPlan,
  enforcementType,
  userType,
  billingAdminEmail,
  isSubscriptionActive,
  meetingCountdownProperties = {},
}) => {
  const plan = PLANS[currentMeetingPlan];
  const enforcement = planEnforcementTypes[enforcementType];

  if (!plan || !enforcement || !userType) {
    return null;
  }

  const MessageToRender = PlanEnforcementMessagesMap[enforcement][userType];

  if (!MessageToRender) {
    return null;
  }

  return (
    <MessageToRender
      plan={plan}
      isSubscriptionSetup={isSubscriptionSetup}
      billingAdminEmail={billingAdminEmail}
      meetingCountdownProperties={meetingCountdownProperties}
      isSubscriptionActive={isSubscriptionActive}
    />
  );
};

export default PlanEnforcementMessage;
