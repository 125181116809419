import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { baseButtonStyles, typography } from '../theme/baseStyles';
import { useElementSize } from '../hooks/useElementSize';
import { ScenePreviewCarousel } from './authoring/Menu/ScenePreviewCarousel';
import { useRichTextEditor } from '../hooks/useRichTextEditor';
import mixpanel from 'mixpanel-browser';
import { getMeetingSeriesProps } from 'zync-common/tracking';
import { createNewSeriesApi } from '../helper/api';
import { useSelector } from 'react-redux';
import { convertBlockSettings } from './authoring/hooks';
import { fromError, logerror } from '../helper/contextualLogger';
import { Button } from './common/Button';
import { useClickOutside } from '../helper/useClickOutside';
import { fromRawContentState } from './richText/helpers';

/** A card showing a preview of the meeting, which navigates to the meeting when clicked. */

export const sanitizeScheduledEvent = (scheduledEvent) => {
  const result = {};

  for (let key in scheduledEvent) {
    if (Array.isArray(scheduledEvent[key])) {
      result[key] = [];
    } else {
      result[key] = null;
    }
  }

  return result;
};

export const prepareAutolaunchConfig = (series) => {
  const newSlides = series.autoLaunchConfig.slides.map((slide) => {
    return {
      ...slide,
      slideConfig: {
        ...slide.slideConfig,
        slideBlocks: slide.slideConfig.slideBlocks.map((block) => ({
          ...block,
          settings: convertBlockSettings(
            { ...block.settings },
            series.workspace.brandKit,
            block.blockId
          ),
        })),
      },
    };
  });

  const settings = {
    ...series.settings,
    eventPresenters: series.settings.eventPresenters.map((ep) => ({
      ...ep,
      invited: false,
    })),
  };

  return {
    ...series.autoLaunchConfig,
    slides: newSlides,
    settings,
    autoRecordOnStart: series.autoRecordOnStart,
  };
};

export const createNewMeetingTitle = (title) => {
  const COPY_PREFIX = '[COPY]';
  try {
    return `${COPY_PREFIX} ${fromRawContentState(title).getPlainText()}`;
  } catch (e) {
    return `${COPY_PREFIX} ${title}`;
  }
};

export const MeetingCard = ({ meetingSeries }) => {
  const history = useHistory();

  const emailAddress = useSelector((_st) => _st.auth.user?.emailAddress);
  const contentRef = useRef(null);
  const menuRef = useRef(null);
  const {
    meetingSeriesId,
    description,
    title,
    autoLaunchConfig,
    visibility,
    scheduledEvent,
  } = meetingSeries;
  const { textValue } = useRichTextEditor({ initialValue: title });
  const slides = autoLaunchConfig?.slides ?? [];
  const scene = slides[0];
  const thumbnailSrc = scene?.slideUrl ?? autoLaunchConfig.templateImageUrl;
  const { width: cardWidth, ref: cardRef } = useElementSize();

  const brandKit = meetingSeries?.workspace?.brandKit;
  const workspaceId = meetingSeries?.workspace?.workspaceId;

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleOpenContextMenu = (event) => {
    event.preventDefault();
    setIsContextMenuOpen(true);
  };
  const handleCloseContextMenu = () => {
    setIsContextMenuOpen(false);
  };

  const handleDuplicateEvent = async () => {
    setIsUpdating(true);

    const eventTitle = createNewMeetingTitle(title);

    try {
      const series = await createNewSeriesApi(
        workspaceId,
        emailAddress,
        eventTitle,
        description,
        visibility,
        prepareAutolaunchConfig(meetingSeries),
        sanitizeScheduledEvent(scheduledEvent)
      );

      history.push(`/e/${series.meetingSeriesId}`);

      return mixpanel.track('Portal - Series Created', {
        ...getMeetingSeriesProps(series),
      });
    } catch (error) {
      setIsUpdating(false);
      logerror(fromError(error));
    }
  };

  useClickOutside(menuRef, handleCloseContextMenu);

  return (
    <div
      className="relative"
      ref={cardRef}
      onContextMenu={handleOpenContextMenu}
      tabIndex={0}
    >
      {isContextMenuOpen && (
        <div
          className="absolute bg-black/20 z-10 grid place-content-center inset-0"
          ref={menuRef}
        >
          <div className="p-0 w-[150px] bg-white rounded-md shadow-md">
            <Button
              onClick={handleDuplicateEvent}
              state={isUpdating && Button.states.LOADING}
              color={Button.colors.WHITE_LITE}
              size={Button.sizes.FULL}
              padding={Button.padding.NONE}
            >
              <span className="text-sm flex text-center justify-center gap-2 items-center text-blue-gray hover:text-blue-light hover:bg-blue-light/5 px-4 py-2 w-full rounded-md disabled:opacity-50 disabled:bg-white disabled:text-blue-gray">
                Duplicate
              </span>
            </Button>
          </div>
        </div>
      )}
      <CardLink to={`/e/${meetingSeriesId}`}>
        <div ref={contentRef}>
          {!scene && <Thumbnail src={thumbnailSrc} />}
          {scene && cardWidth && (
            <ScenePreviewCarousel
              scenes={slides}
              width={cardWidth}
              height={(cardWidth * 9) / 16}
              activeOnHover
              intervalMs={3000}
              brandKit={brandKit}
            />
          )}
          <ContentWrapper>
            <HiddenWrapper>
              <CardTitle>{textValue}</CardTitle>
            </HiddenWrapper>
            <AnimatedWrapper>
              <CardTitle>{textValue}</CardTitle>
              <GoToMeetingButton>
                <FontAwesomeIcon
                  icon={['fas', 'external-link-alt']}
                  size="lg"
                />
                Go to meeting
              </GoToMeetingButton>
            </AnimatedWrapper>
          </ContentWrapper>
        </div>
      </CardLink>
    </div>
  );
};

const CardLink = styled(Link)`
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  box-shadow: ${({ theme }) => theme.shadows['400']};
  text-decoration: none;
  transition: filter 300ms;
  overflow: hidden;

  :hover {
    filter: drop-shadow(0px 4px 8px rgba(16, 24, 64, 0.08));
  }
`;

const Thumbnail = styled.div`
  aspect-ratio: 16 / 9;
  background-image: url(${({ src }) => src});
  background-size: cover;
  border-radius: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px 0 0;
`;

const ContentWrapper = styled.div`
  position: relative;
  background-color: white;
`;

const HiddenWrapper = styled.div`
  padding: 10px;
  visibility: hidden;
`;

const AnimatedWrapper = styled.div`
  padding: 10px;
  position: absolute;
  top: 0;
  box-sizing: border-box;
  width: 100%;
`;

const CardTitle = styled.h4`
  ${typography.h4};
  color: ${({ theme }) => theme.palette.text.paragraph};
  overflow-wrap: break-word;
`;

const GoToMeetingButton = styled.button`
  ${baseButtonStyles};
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.fontSize.small}px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  gap: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(5)}px;
  display: none; /* we will make it visible on hover */
`;
