import React, { useState, useRef } from 'react';
import { updateSeriesApi } from '../../../helper/api';
import { TextInput } from '../../Input';
import { shallowEqual, useSelector } from 'react-redux';
import { makeRandomId } from '../../../helper';
import { fromError, logerror } from '../../../helper/contextualLogger';
import { SixDotsIcon } from '../../icons/SixDotsIcon';
import Draggable from 'react-draggable';
import { swap } from '../../authoring/Menu/SlideTray';

export const DraggableQuestion = ({
  question,
  onStop,
  handleDeleteQuestion,
  nodeRef,
  draggableNodeRefs,
  index,
  initialDraggableNodeRef,
  onDrag,
  children,
}) => {
  const position = useRef({ x: 0, y: 0 });

  return (
    <Draggable
      axis="y"
      position={position.current}
      handle=".handle"
      nodeRef={nodeRef}
      onStop={onStop}
      onDrag={onDrag}
    >
      <div ref={(ref) => (draggableNodeRefs.current[index] = { current: ref })}>
        <div
          className="flex w-fit max-w-full border border-gray rounded p-2.5 text-sm text-blue-gray gap-2.5 relative"
          ref={initialDraggableNodeRef}
        >
          {children}
          <button className="w-2 handle">
            <SixDotsIcon />
          </button>
          <div className="max-w-full overflow-hidden break-words">
            {question.text}
          </div>
          <button
            className="h-2.5 w-2.5 flex justify-center items-center"
            onClick={() => handleDeleteQuestion(question.id)}
          >
            ×
          </button>
        </div>
      </div>
    </Draggable>
  );
};

export const EventQuestions = ({ series, localDispatch }) => {
  const { eventQuestions = [], meetingSeriesId } = series || {};
  const [inputQuestion, setInputQuestion] = useState('');
  const { user } = useSelector((_st) => _st.auth, shallowEqual);
  const [displayedQuestions, setDisplayedQuestions] = useState(eventQuestions);
  const draggableNodeRefs = useRef([]);
  const initialDraggableNodeRef = useRef(null);

  const handleAddQuestion = async () => {
    if (!inputQuestion.trim()) {
      return;
    }
    const newEventQuestions = [
      {
        text: inputQuestion,
        id: makeRandomId(6),
        timestamp: Date.now(),
        asker: user.userName,
        moderatorQuestion: true,
        AIGenerated: false,
        index: 0,
      },
      ...eventQuestions.map((q) => {
        return {
          ...q,
          index: q.index + 1,
        };
      }),
    ];
    try {
      const result = await updateSeriesApi(meetingSeriesId, {
        eventQuestions: newEventQuestions,
      });
      localDispatch({ type: 'REFRESH_SERIES', series: result });
      setInputQuestion('');
      setDisplayedQuestions(result.eventQuestions);
    } catch (error) {
      logerror(fromError(error));
    }
  };

  const handleDeleteQuestion = async (id) => {
    try {
      const result = await updateSeriesApi(meetingSeriesId, {
        eventQuestions: eventQuestions
          .filter((q) => q.id !== id)
          .map((q, index) => ({ ...q, index })),
      });
      localDispatch({ type: 'REFRESH_SERIES', series: result });
      setDisplayedQuestions(result.eventQuestions);
    } catch (error) {
      logerror(fromError(error));
    }
  };

  const findQuestionIndices = (elementKey, event, drag) => {
    const height = drag.node.getBoundingClientRect().height;

    const distanceY = drag.y;

    const oldIndex = displayedQuestions.findIndex((q) => q.id === elementKey);

    const gapBetweenNodes =
      parseInt(getComputedStyle(drag.node.parentElement).gap) || 0;

    let newIndex = Math.min(
      oldIndex +
        Math.floor(Math.round(distanceY / (height + gapBetweenNodes / 2))),
      displayedQuestions.length
    );

    return {
      oldIndex,
      newIndex,
    };
  };

  const onDragEnd = (elementKey) => async (event, drag) => {
    if (drag.lastY === 0) {
      return;
    }

    const { oldIndex, newIndex } = findQuestionIndices(elementKey, event, drag);
    const newQuestions = swap(displayedQuestions, oldIndex, newIndex);
    setDisplayedQuestions(newQuestions);
    const result = await updateSeriesApi(meetingSeriesId, {
      eventQuestions: newQuestions,
    });
    localDispatch({ type: 'REFRESH_SERIES', series: result });
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="font-medium text-blue-dark text-sm">Add Questions</div>
      {displayedQuestions.length > 0 && (
        <div className="flex flex-col gap-8">
          {displayedQuestions.map((question, index) => (
            <DraggableQuestion
              question={question}
              key={question.id}
              nodeRef={
                draggableNodeRefs.current[index] || initialDraggableNodeRef
              }
              handleDeleteQuestion={handleDeleteQuestion}
              draggableNodeRefs={draggableNodeRefs}
              index={index}
              onStop={onDragEnd(question.id)}
              initialDraggableNodeRef={initialDraggableNodeRef}
            />
          ))}
        </div>
      )}
      <div className="w-[417px]">
        <TextInput
          value={inputQuestion}
          onChange={(event, value) => setInputQuestion(value)}
          onEnter={(event, value) => handleAddQuestion()}
          textLength={200}
        />
      </div>
    </div>
  );
};
