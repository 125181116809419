import React from 'react';

export const TwitterNoCircleIcon = ({
  width = '20px',
  height = '20px',
  className = 'fill-purple',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path d="M18.3083 5.38518C17.8783 5.95881 17.3585 6.45918 16.769 6.86699C16.769 7.01684 16.769 7.16668 16.769 7.32485C16.7737 10.046 15.6867 12.6553 13.7518 14.5677C11.8168 16.4801 9.19574 17.5356 6.47611 17.4977C4.90383 17.503 3.35169 17.1439 1.94127 16.4488C1.86522 16.4156 1.81617 16.3403 1.81645 16.2573V16.1657C1.81645 16.0462 1.91331 15.9493 2.0328 15.9493C3.57831 15.8984 5.06868 15.3625 6.29305 14.4175C4.89415 14.3894 3.6355 13.5607 3.05626 12.2864C3.027 12.2168 3.03611 12.137 3.08029 12.0758C3.12448 12.0146 3.19736 11.9808 3.2726 11.9867C3.69775 12.0295 4.12716 11.9899 4.53736 11.8702C2.99309 11.5496 1.83274 10.2676 1.66668 8.69844C1.66078 8.62316 1.69451 8.55025 1.75569 8.50605C1.81687 8.46184 1.89666 8.45272 1.96623 8.48199C2.38064 8.66486 2.828 8.76117 3.28092 8.76504C1.92777 7.8769 1.3433 6.18763 1.85806 4.6526C1.91119 4.50344 2.03885 4.39325 2.19412 4.36253C2.34939 4.3318 2.50936 4.38507 2.61525 4.50276C4.44124 6.44616 6.95018 7.60422 9.61306 7.73277C9.54485 7.46059 9.5113 7.18088 9.51321 6.90029C9.53811 5.429 10.4485 4.11834 11.8181 3.58192C13.1877 3.04549 14.7455 3.38947 15.7621 4.45281C16.4552 4.32079 17.1252 4.088 17.7508 3.76186C17.7966 3.73324 17.8548 3.73324 17.9006 3.76186C17.9292 3.80771 17.9292 3.86585 17.9006 3.9117C17.5975 4.60566 17.0856 5.1877 16.4361 5.57665C17.0049 5.5107 17.5636 5.37654 18.1003 5.17706C18.1455 5.1463 18.2049 5.1463 18.2501 5.17706C18.2879 5.19439 18.3162 5.22751 18.3274 5.26761C18.3387 5.30771 18.3317 5.35072 18.3083 5.38518Z" />
  </svg>
);
