import React from 'react';
import { Link } from 'react-router-dom';

import classNames from '../../helper/classNames';
import { MenuLeftArrow } from '../../components/icons/MenuLeftArrow';
import { getHomeUrl } from '../../helper';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';
import { useAuthoringTool } from '../../components/authoring/hooks';
import { useRichTextEditor } from '../../hooks/useRichTextEditor';
import { PLANS } from 'zync-common/zyncCustomerPlans';
import moment from 'moment';
import { getWorkspaceIdFromUrl } from '../../hooks/useTemplateCreation';

const logoImage = getStaticAssetUrl('logo_text.png');

/**
 * Pages that implement the Navbar.
 */
export const pageTitles = {
  home: 'Home',
  meeting: 'Meeting',
  authoring: 'Authoring',
  highlights: 'Highlights',
  workspaceDetails: 'Workspace Details',
  guestQuestions: 'Guest Questions',
};

export const Navbar = ({ children }) => {
  return (
    <nav
      className={classNames(
        'w-full bg-white shadow-xl flex justify-center sticky top-0 z-50'
      )}
    >
      <ul className="flex items-center justify-between bg-white w-11/12 h-16">
        <li className="w-full">{children}</li>
      </ul>
    </nav>
  );
};

export const PreviousPage = ({ currentPage, previousRoute = '/' }) => {
  const {
    currentSeries: { title },
  } = useAuthoringTool();
  const { textValue } = useRichTextEditor({ initialValue: title });

  const workspaceId = getWorkspaceIdFromUrl();

  if (previousRoute === '/' && workspaceId) {
    previousRoute += `?workspaceId=${workspaceId}`;
  }

  const pageTitlesToPreviousPage = {
    [pageTitles.home]: pageTitles.home,
    [pageTitles.meeting]: pageTitles.home,
    [pageTitles.workspaceDetails]: pageTitles.home,
    [pageTitles.authoring]: textValue,
    [pageTitles.highlights]: pageTitles.meeting,
    [pageTitles.guestQuestions]: 'Back',
  };

  return (
    <Link
      to={previousRoute}
      className="flex items-center gap-3 my-3 cursor-pointer"
    >
      <MenuLeftArrow width="20" height="14" color="#00000099" />
      <span className="font-medium text-lg text-black text-opacity-60 capitalize">
        {pageTitlesToPreviousPage[currentPage]}
      </span>
    </Link>
  );
};

export const HomePageLogo = ({ workspaceId }) => {
  return (
    <div className="w-20">
      <Link to={`/?workspaceId=${workspaceId}`} className="">
        <img className="cursor-pointer" src={logoImage} alt="icon" />
      </Link>
    </div>
  );
};

export const signout = (auth, dispatch, logout, returnToHomePage = true) => {
  const { authenticated, user } = auth;
  const { registered } = user || {};

  if (authenticated && registered) {
    const signout = (dispatch) => {
      logout();
      dispatch({
        type: 'LOGOUT',
      });
      if (returnToHomePage) window.location.href = getHomeUrl();
    };
    signout(dispatch);
  }
};

export const NavbarPlanInformation = ({
  workspaceId,
  isTrial,
  plan,
  workspacePlanValidUntil,
  currentSubscriptionRenewalTime,
}) => {
  const redirectLink = `/workspace/billing?workspaceId=${workspaceId}`;
  return (
    <>
      {!isTrial && plan === PLANS.free.id && (
        <Link
          to={`/workspace/billing?workspaceId=${workspaceId}`}
          className="py-2 w-[130px] bg-purple text-white font-medium text-sm rounded justify-center md:flex hidden"
        >
          Upgrade
        </Link>
      )}
      {isTrial && plan === PLANS.solo.id && (
        <Link
          to={`/workspace/billing?workspaceId=${workspaceId}`}
          className="py-2 w-[130px] bg-purple text-white font-medium text-sm rounded justify-center md:flex hidden"
        >
          Upgrade
        </Link>
      )}
      {plan === PLANS.pilot.id && (
        <Link
          to={redirectLink}
          className="text-blue-dark font-medium flex justify-center items-center text-xs cursor-pointer underline decoration-blue-dark"
        >
          Pilot Plan: expires{' '}
          {moment(workspacePlanValidUntil).format('MMM D, yyyy')}
        </Link>
      )}
      {plan === PLANS.admin.id && (
        <Link
          to={redirectLink}
          className="text-blue-dark font-medium flex justify-center items-center text-xs cursor-pointer underline decoration-blue-dark"
        >
          Admin Plan: expires{' '}
          {moment(workspacePlanValidUntil).format('MMM D, yyyy')}
        </Link>
      )}
      {!isTrial &&
        (plan === PLANS.starter.id || plan === PLANS.starter_annual.id) && (
          <Link
            to={redirectLink}
            className="text-blue-dark font-medium flex justify-center items-center text-xs cursor-pointer underline decoration-blue-dark"
          >
            Starter Plan renews on{' '}
            {moment(new Date(currentSubscriptionRenewalTime)).format(
              'MMM D, yyyy'
            )}
          </Link>
        )}
      {isTrial && workspacePlanValidUntil && (
        <Link
          to={redirectLink}
          className="text-blue-dark font-medium flex justify-center items-center text-xs cursor-pointer underline decoration-blue-dark"
        >
          Solo Trial expires{' '}
          {moment(workspacePlanValidUntil).format('MMM D, yyyy')}
        </Link>
      )}
      {!isTrial &&
        (plan === PLANS.business.id || plan === PLANS.business_annual.id) && (
          <Link
            to={redirectLink}
            className="text-blue-dark font-medium flex justify-center items-center text-xs cursor-pointer underline decoration-blue-dark"
          >
            Business Plan: expires{' '}
            {moment(workspacePlanValidUntil).format('MMM D, yyyy')}
          </Link>
        )}
    </>
  );
};
