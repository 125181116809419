import React, {
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { BlockRenderer } from '../blocks';
import useImageColor from 'use-image-color';
import { isMobile } from '../helper';
import { isMeetingController } from '../helper/roles';
import { SLIDE_CONTAINER_ID, fromZyncBottomsup } from '../helper/constants';
import { constrainRectangle, standardScreenSize } from '../helper/css';
import { useElementSize } from '../hooks/useElementSize';
import { useLiveTab } from '../hooks/useLiveTab';
import { useSlideSize } from '../hooks/useSlideSize';
import { useBlockTemplates } from '../hooks/useBlockTemplates';
import mixpanel from 'mixpanel-browser';
import { enrichBlockPropertiesForAuthoring } from './authoring/util';
import { MeetingRegistrationCTA } from './MeetingRegistrationCTA';
import classNames from '../helper/classNames';
import { LoadingSpinner } from './LoadingSpinner';
import { DoubleAngleIcon } from './icons/DoubleAngleIcon';
import { useSidebarSceneSplit } from '../hooks/useSideBarSceneSplit';
import { useSelector } from 'react-redux';
import { useMeeting } from '../hooks/useMeeting';
import { isEqual } from 'lodash/fp';
import { INVISIBLE_IMAGE_SRC } from '../helper/image';
import { overrideDynamicValue } from 'zync-common/helper/overrideDynamicValue';
import { getUserAudioElementsAndPlay } from '../pages/Meeting';
import { Modal, ModalBody, ModalWindow } from './Modal';
import { Button } from './common/Button';
import { logerror } from '../helper/contextualLogger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWindowSize } from '../helper/windowSizeChangeHandler';
export const SlideContainerRefContext = React.createContext(null);

const PREVIOUS = 'previous';
const NEXT = 'next';

/* enriches with a block object with isPreviousScene property  */
const markAsPreviousSceneBlock = (blocks) =>
  blocks
    ? blocks.map((block) => ({
        ...block,
        isFromPreviousScene: true,
      }))
    : [];

/** Returns a block object that has properties necessary for display in a live meeting. */
const enrichBlockPropertiesForLive = (
  blockInstance,
  blocksState,
  sceneDimensions,
  blockTemplate
) => {
  if (!blocksState || !blockTemplate || !sceneDimensions) {
    return null;
  }

  const positioned = enrichBlockPropertiesForAuthoring(
    blockInstance,
    sceneDimensions,
    null,
    blockTemplate
  );

  const state = blocksState[blockInstance.blockInstanceId] ?? null;

  return {
    ...blockTemplate,
    ...state,
    ...positioned,
  };
};

/** Use all necessary information to render blocks for the given scene. */
const useSceneBlocks = (scene, previousScene) => {
  const userId = useSelector((_st) => _st.auth.user?.userId);
  const meetingSeriesId = useSelector(
    (state) => state.meetingState?.meetingSeriesId
  );

  const blocksState = useSelector(
    (state) => state.meetingState?.blocks || {},
    isEqual
  );

  const blockTemplates = useBlockTemplates();

  const isInitialized = useRef(false);

  const blockTemplatesMap = useMemo(
    () =>
      (blockTemplates ?? []).reduce(
        (map, block) => ({ ...map, [block.blockId]: block }),
        {}
      ),
    [blockTemplates]
  );

  const slideSize = useSlideSize();

  const currentSceneBlocks = scene.slideConfig?.slideBlocks;
  const previousSceneBlocks = useMemo(
    () =>
      previousScene && isInitialized.current
        ? markAsPreviousSceneBlock(previousScene.slideConfig?.slideBlocks)
        : [],
    [previousScene]
  );

  const sceneBlocks = useMemo(
    () => [...currentSceneBlocks, ...previousSceneBlocks],
    [currentSceneBlocks, previousSceneBlocks]
  );

  const blocks = useMemo(
    () =>
      !blockTemplates
        ? []
        : (sceneBlocks ?? [])
            .map((sceneBlock) =>
              enrichBlockPropertiesForLive(
                sceneBlock,
                blocksState,
                slideSize,
                blockTemplatesMap[sceneBlock.blockId]
              )
            )
            .filter((block) => block),
    [blocksState, sceneBlocks, slideSize, blockTemplatesMap, blockTemplates]
  );

  const areNoBlocksRendered = !blocks.length;
  const areAllBlocksFromPreviousScene =
    !!blocks.length && blocks.every((block) => block?.isFromPreviousScene);

  useEffect(() => {
    if (
      isInitialized.current &&
      (areNoBlocksRendered || areAllBlocksFromPreviousScene)
    ) {
      logerror({
        message: `User has no blocks rendered. Reason: ${
          areAllBlocksFromPreviousScene
            ? '#There are no current blocks from selected scene'
            : ''
        } ${
          areNoBlocksRendered
            ? '#No present and previous scene blocks were found'
            : ''
        }`,
        userId,
        meetingSeriesId,
      });
    }
  }, [
    areAllBlocksFromPreviousScene,
    areNoBlocksRendered,
    meetingSeriesId,
    userId,
  ]);

  useEffect(() => {
    if (sceneBlocks.length) {
      isInitialized.current = true;
    }
  }, [sceneBlocks.length]);

  return blocks;
};

const BlockHider = ({
  children,
  blockId,
  shouldHide,
  animationDelay = undefined,
  hideAfter = 1_400,
}) => {
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    let timeout;

    if (shouldHide) {
      timeout = setTimeout(() => {
        setIsHidden(true);
      }, hideAfter);
    } else {
      setIsHidden(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [shouldHide, hideAfter]);

  return (
    <div
      className={classNames(
        blockId === 'reactions' && 'z-50 relative',
        shouldHide
          ? 'animate-[hide_300ms_1400ms_forwards] select-none'
          : 'animate-[fadeIn_1400ms_1000ms_both]',
        isHidden && 'hidden'
      )}
      style={{
        animationDelay,
      }}
    >
      {isHidden ? null : children}
    </div>
  );
};

const isBlockEqual = (prevProps, nextProps) =>
  isEqual(prevProps.block, nextProps.block);

const SceneBlock = React.memo(({ block, animationDelay, accentColor }) => {
  return (
    <BlockHider
      shouldHide={block.isFromPreviousScene}
      blockId={block.blockId}
      animationDelay={animationDelay}
    >
      <BlockRenderer
        code={block.code}
        block={block}
        accentColor={accentColor}
      />
    </BlockHider>
  );
}, isBlockEqual);

const SceneBlocks = React.memo(
  ({ scene, previousScene, appearanceDelay, accentColor }) => {
    const blocks = useSceneBlocks(scene, previousScene);

    return blocks.map((block) => (
      <SceneBlock
        key={block.blockInstanceId}
        block={block}
        animationDelay={appearanceDelay}
        accentColor={accentColor}
      />
    ));
  }
);

SceneBlocks.displayName = 'SceneBlocks';

export const MAIN_SLIDE_CONTAINER = 'main-slide-container';

const Notes = ({ text, liveTabWidth, handleCloseNotes }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const { width } = useWindowSize();

  const textContainerRef = useRef(null);
  const textSpanRef = useRef(null);

  const checkIsOverflowing = useCallback(() => {
    if (!width) {
      return;
    }
    setIsOverflowing(
      textContainerRef.current?.getBoundingClientRect().height <
        textSpanRef.current?.getBoundingClientRect().height
    );
  }, [width]);

  useEffect(() => {
    checkIsOverflowing();
  }, [liveTabWidth, checkIsOverflowing]);

  useEffect(() => {
    if (!isExpanded) {
      checkIsOverflowing();
    }
  }, [isExpanded, checkIsOverflowing, text]);

  useEffect(() => {
    setIsExpanded(false);
  }, [checkIsOverflowing, text]);

  if (!text) {
    return null;
  }

  return (
    <div
      className={classNames(
        'flex gap-2 absolute z-[9999] bg-white top-0 right-0 p-2 opacity-90'
      )}
      style={{
        width: `calc(100% - ${liveTabWidth}px)`,
        background: 'rgba(255, 255, 255, 0.7)',
        borderRadius: '0 0 8px 8px',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(5px)',
        '-webkit-backdrop-filter': 'blur(5px)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
      }}
    >
      <div className="absolute inset-0 -z-10" />
      <div className="w-full">
        <div>
          <p
            ref={textContainerRef}
            className={classNames(
              'text-base',
              isExpanded ? '' : 'line-clamp-2'
            )}
          >
            <p className="float-right inline-block bg-white text-medium rounded-md text-xxs text-[#696F8C] p-0.5 w-fit">
              <FontAwesomeIcon color="#696F8C" icon="eye" /> Only visible to you{' '}
            </p>
            <span ref={textSpanRef} className="font-medium">
              {text}
            </span>
          </p>
        </div>
        {isOverflowing ? (
          <button
            onClick={() => setIsExpanded((wasExpanded) => !wasExpanded)}
            className="text-purple text-base"
          >
            {isExpanded ? 'see less' : 'see more'}
          </button>
        ) : null}
      </div>
      <div>
        <button
          onClick={handleCloseNotes}
          className="bg-purple fill-white text-white w-6 h-6 rounded-full"
        >
          &times;
        </button>
      </div>
    </div>
  );
};
export const Slide = ({
  slides,
  startSlide,
  userId,
  children,
  mobileOptionsMenu,
  isSwitchingScenes,
  brandKit,
  screenshareComponent,
  timelineOn,
  handleCloseNotes,
  areNotesOpen,
  moveLeft,
  moveRight,
}) => {
  const { users } = useMeeting();

  const [lastSceneSwitchButtonClicked, setLastSceneSwitchButtonClicked] =
    useState(null);

  const currentSceneIndex = useSelector(
    (state) => state.meetingState.state?.slides?.currentSlideIndex
  );

  const previousSceneIndex = useSelector(
    (state) => state.meetingState.state?.slides?.previousSceneIndex
  );

  const screenshareScene = screenshareComponent();

  const [
    isUserGestureActivationPromptOpen,
    setIsUserGestureActivationPromptOpen,
  ] = useState(false);

  const scene = slides[currentSceneIndex];
  const previousScene =
    previousSceneIndex !== currentSceneIndex
      ? slides[previousSceneIndex]
      : null;

  const currentSceneTemplateId = slides[currentSceneIndex]?.sceneTemplateId;
  const previousSceneTemplateId = slides[previousSceneIndex]?.sceneTemplateId;

  const currentUser = users[userId];
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const isMeetingRegistrationCTA =
    urlSearchParams.get('newUserOnboardingFlow') === 'true' &&
    !fromZyncBottomsup;
  const templateKey = urlSearchParams.get('templateKey');
  const campaignId = urlSearchParams.get('campaign_id');
  const [showSceneTransitionAnimation, setShowSceneTransitionAnimation] =
    useState(false);
  const isFirstRender = useRef(true);
  const registeredUser = useSelector((st) => st.auth);

  const isSimilarScene =
    currentSceneTemplateId &&
    previousSceneTemplateId &&
    currentSceneTemplateId === previousSceneTemplateId;

  useEffect(() => {
    let animationTimer = null;
    let stopAnimationTimer = null;
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (isSimilarScene) return;
      if (previousSceneIndex === currentSceneIndex) return;
      previousSceneIndex > currentSceneIndex
        ? setLastSceneSwitchButtonClicked(PREVIOUS)
        : setLastSceneSwitchButtonClicked(NEXT);
      animationTimer = setTimeout(() => {
        setShowSceneTransitionAnimation(true);
      }, 500);
      stopAnimationTimer = setTimeout(() => {
        setShowSceneTransitionAnimation(false);
        setLastSceneSwitchButtonClicked(null);
      }, 1400);
    }

    return () => {
      clearTimeout(animationTimer);
      clearTimeout(stopAnimationTimer);
    };
  }, [previousSceneIndex, currentSceneIndex, isSimilarScene]);

  /** Element reference for the slide container. */
  const slideContainerRef = useRef(null);

  const meetingController = useMemo(
    () => isMeetingController(currentUser),
    [currentUser]
  );

  const { colors } = useImageColor(scene.slideUrl, { cors: true, colors: 2 });

  const backgroundColor = overrideDynamicValue(
    scene.slideBackgroundColor || (colors || [])[0] || 'none',
    brandKit
  );

  const previousSceneBackgroundColor = overrideDynamicValue(
    previousScene?.slideBackgroundColor,
    brandKit
  );

  const isNavigationArrowShowing =
    !timelineOn &&
    !isMobile &&
    meetingController &&
    (Boolean(slides[currentSceneIndex + 1]) ||
      Boolean(slides[currentSceneIndex - 1]));

  // calculate the aspect ratio of the page.
  // the available width and height retained for displaying the current slide.
  // excludes elements such as the toolbar and bottom menu.
  const {
    width: measuredWidth,
    height: measuredHeight,
    ref: pageContainerRef,
  } = useElementSize();

  const { liveTabWidth, isLiveTabActive } = useLiveTab();

  // aspect ratio-constrained width and height within the slide display area.
  const constrained = constrainRectangle(measuredWidth, measuredHeight, 16 / 9);

  const calculatedDimensions = {
    width: screenshareScene ? window.screen.width : constrained.width,
    height: screenshareScene ? window.screen.height : constrained.height,
  };

  // scale vs the standard resolution (see helper/css.js).
  const scale = calculatedDimensions.width / standardScreenSize.width;

  const isWidthRatioForWideScreen =
    window.innerWidth / window.innerHeight > 2.2;

  const availableWidthToUse = Math.min(
    measuredWidth - liveTabWidth - standardScreenSize.width * scale, // available space to use to push the scene
    liveTabWidth // we do not push the screen more than enough
  );

  // offset of the constrained rectangle within the full slide display area.
  const offset = {
    top: (measuredHeight - calculatedDimensions.height) / 2,
    left:
      (measuredWidth -
        calculatedDimensions.width +
        (isWidthRatioForWideScreen ? availableWidthToUse : 0)) /
      2,
  };

  const {
    leftSlideMargin,
    transformScale,
    xOffset,
    newSlideHeight,
    newSlideWidth,
  } = useSidebarSceneSplit(
    scale,
    isNavigationArrowShowing && !screenshareScene,
    liveTabWidth,
    isLiveTabActive
  );

  const previousSlide = slides[currentSceneIndex - 1];
  const nextSlide = slides[currentSceneIndex + 1];

  const atLeastOneUserSpeaking =
    document.querySelectorAll('.user-audio').length > 0;

  useEffect(() => {
    if (!atLeastOneUserSpeaking) {
      return;
    }

    getUserAudioElementsAndPlay({
      onSuccess: () => setIsUserGestureActivationPromptOpen(false),
      onError: () => setIsUserGestureActivationPromptOpen(true),
    });
  }, [atLeastOneUserSpeaking]);

  const isHeaderActive = !!document.getElementById('banner-header');
  return (
    <SlideContainerRefContext.Provider value={slideContainerRef}>
      <div
        id={MAIN_SLIDE_CONTAINER}
        className="overflow-hidden absolute top-0 left-0 right-0 bottom-0 bg-gray/50"
      >
        {isUserGestureActivationPromptOpen && (
          <ModalWindow size={Modal.size.md} showCancel={false}>
            <ModalBody>
              <p>
                This event has video, audio and interactive elements that you
                can participate in. After you join, other users will not hear or
                see you.
              </p>
              <div className="mt-4 h-12 flex justify-end items-center">
                <Button
                  color={Button.colors.PURPLE}
                  padding={Button.padding.SMALL}
                  type="button"
                  onClick={() =>
                    getUserAudioElementsAndPlay({
                      onSuccess: () =>
                        setIsUserGestureActivationPromptOpen(false),
                      onError: () =>
                        setIsUserGestureActivationPromptOpen(false),
                    })
                  }
                >
                  <span className="text-base">OK</span>
                </Button>
              </div>
            </ModalBody>
          </ModalWindow>
        )}
        {isNavigationArrowShowing && (
          <>
            <div
              className="absolute shadow-[0_4px_4px_4px_rgba(0,0,0,0.1)] flex justify-center items-center transition-left z-50 will-change-left"
              style={{
                left: liveTabWidth,
                backgroundColor,
                width: `${leftSlideMargin}px`,
                height: `${measuredHeight}px`,
              }}
            >
              {Boolean(previousSlide) && !screenshareScene && (
                <>
                  <button
                    className="peer z-10 h-full w-full flex justify-center items-center"
                    onClick={moveLeft}
                  >
                    {isSwitchingScenes ? (
                      <LoadingSpinner width="31px" />
                    ) : (
                      <DoubleAngleIcon className="fill-white rotate-180" />
                    )}
                  </button>
                  <img
                    className="object-cover object-right absolute top-1/2 -left-96 z-0 rounded-r-lg transition-all peer-hover:left-0 duration-300 scale-75 peer-hover:scale-100 -translate-y-1/2"
                    src={
                      previousSceneBackgroundColor
                        ? INVISIBLE_IMAGE_SRC
                        : previousSlide.slideUrl
                    }
                    alt={
                      previousSceneBackgroundColor
                        ? ''
                        : 'previous scene backdrop'
                    }
                    crossOrigin="anonymous"
                    style={{
                      width: newSlideWidth || '100%',
                      height: newSlideHeight || '100%',
                      backgroundColor: previousSceneBackgroundColor,
                    }}
                  />
                </>
              )}
            </div>
            <div
              className="absolute shadow-[0_-4px_4px_4px_rgba(0,0,0,0.1)] flex justify-center items-center z-[49]"
              style={{
                left: `${
                  leftSlideMargin +
                  liveTabWidth +
                  calculatedDimensions.width * transformScale
                }px`,
                backgroundColor,
                width: `${leftSlideMargin}px`,
                height: `${measuredHeight}px`,
              }}
            >
              {Boolean(nextSlide) && !screenshareScene && (
                <>
                  <button
                    className="peer z-10 h-full w-full flex justify-center items-center"
                    onClick={moveRight}
                  >
                    {isSwitchingScenes ? (
                      <LoadingSpinner width="31px" />
                    ) : (
                      <DoubleAngleIcon className="fill-white" />
                    )}
                  </button>
                  <img
                    className="object-cover object-left absolute top-1/2 -right-96 z-0 rounded-l-lg transition-all peer-hover:right-0 duration-300 scale-75 peer-hover:scale-100 -translate-y-1/2"
                    src={
                      nextSlide?.slideBackgroundColor
                        ? INVISIBLE_IMAGE_SRC
                        : nextSlide.slideUrl
                    }
                    alt={
                      nextSlide?.slideBackgroundColor
                        ? ''
                        : 'next scene backdrop'
                    }
                    crossOrigin="anonymous"
                    style={{
                      width: newSlideWidth || '100%',
                      height: newSlideHeight || '100%',
                      backgroundColor: nextSlide?.slideBackgroundColor,
                    }}
                  />
                </>
              )}
            </div>
          </>
        )}
        <div
          ref={pageContainerRef}
          className={classNames(
            'absolute top-0 right-0 overflow-hidden z-0',
            isMobile
              ? 'bottom-0 left-0'
              : registeredUser?.recordingUser
              ? 'bottom-[0px] left-0'
              : 'bottom-[70px] left-0'
          )}
          style={{
            top: isHeaderActive ? '48px' : undefined,
            //   marginLeft: isHeaderActive ? 'auto' : undefined,
            //   width: isLiveTabActive
            //    ? `calc(100% - ${liveTabWidth}px)`
            //   : undefined,
          }}
        >
          {areNotesOpen && scene.hostNote && !registeredUser?.recordingUser ? (
            <Notes
              text={scene.hostNote}
              liveTabWidth={liveTabWidth}
              handleCloseNotes={handleCloseNotes}
            />
          ) : null}
          <div
            className="will-change-transform transition-transform w-full h-full"
            style={{
              transform: `scale(${transformScale}) translateX(${xOffset}px)`,
            }}
          >
            {screenshareScene || (
              <>
                {mobileOptionsMenu}
                <div
                  id={SLIDE_CONTAINER_ID}
                  ref={slideContainerRef}
                  className="overflow-hidden origin-top-left absolute"
                  style={{
                    width: standardScreenSize.width,
                    height: standardScreenSize.height,
                    transform: `scale(${scale})`,
                    top: offset.top,
                    left: offset.left,
                    backgroundColor,
                  }}
                >
                  <canvas
                    id="canvas"
                    hidden
                    className="absolute w-full h-full"
                  />

                  <div className="w-full h-full absolute top-0 left-0">
                    <SceneBlocks
                      scene={scene}
                      previousScene={previousScene}
                      appearanceDelay="0ms"
                      accentColor={brandKit?.accentColor}
                    />
                  </div>
                  {scene.slideUrl && (
                    <img
                      id="scene-background"
                      crossOrigin="anonymous"
                      src={
                        scene.slideBackgroundColor
                          ? INVISIBLE_IMAGE_SRC
                          : scene.slideUrl
                      }
                      alt=""
                      className={classNames(
                        'relative object-cover object-center w-full h-full',
                        lastSceneSwitchButtonClicked !== null
                          ? 'hidden'
                          : 'block'
                      )}
                      style={{
                        backgroundColor: scene.slideBackgroundColor,
                        zIndex: -9999,
                      }}
                    />
                  )}

                  {previousScene?.slideUrl && (
                    <img
                      crossOrigin="anonymous"
                      src={
                        previousSceneBackgroundColor
                          ? INVISIBLE_IMAGE_SRC
                          : previousScene.slideUrl
                      }
                      alt=""
                      className={classNames(
                        '-z-20 relative  object-cover object-center w-full h-full',
                        lastSceneSwitchButtonClicked !== null
                          ? 'block'
                          : 'hidden'
                      )}
                      style={{
                        backgroundColor: previousSceneBackgroundColor,
                      }}
                    />
                  )}

                  {lastSceneSwitchButtonClicked !== null && (
                    <img
                      src={
                        scene.slideBackgroundColor
                          ? INVISIBLE_IMAGE_SRC
                          : scene.slideUrl
                      }
                      alt=""
                      className={classNames(
                        'absolute transition-transform ease-in-out duration-[900ms] object-cover object-center rounded-r-lg w-full h-full top-0 z-10 will-change-left will-change-transform',
                        lastSceneSwitchButtonClicked === PREVIOUS
                          ? '-left-full'
                          : 'left-full',
                        showSceneTransitionAnimation
                          ? lastSceneSwitchButtonClicked === PREVIOUS
                            ? 'translate-x-full'
                            : '-translate-x-full'
                          : ''
                      )}
                      crossOrigin="anonymous"
                      key="scene-background-animation"
                      style={{
                        backgroundColor: scene.slideBackgroundColor,
                        zIndex: -9999,
                      }}
                    />
                  )}

                  {children}
                </div>
              </>
            )}
          </div>
        </div>
        {isMeetingRegistrationCTA &&
          mixpanel.track('QuickStart Live Meeting - New Scene Clicked', {
            templateKey,
            campaignId,
            nextSceneIndex: currentSceneIndex,
          }) && (
            <MeetingRegistrationCTA
              currentSceneIndex={currentSceneIndex}
              templateKey={templateKey}
              campaignId={campaignId}
              slides={slides}
            />
          )}
      </div>
    </SlideContainerRefContext.Provider>
  );
};
