const getContrastColor = (hexColor) => {
  if (!hexColor) {
    return hexColor;
  }
  // Convert hex color value to RGB values
  const red = parseInt(hexColor.substring(1, 3), 16);
  const green = parseInt(hexColor.substring(3, 5), 16);
  const blue = parseInt(hexColor.substring(5, 7), 16);

  // Calculate relative luminance of the color
  const luminance = (0.2126 * red + 0.7152 * green + 0.0722 * blue) / 255;

  // Choose white or black font color based on luminance
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

module.exports = {
  getContrastColor,
};
