import { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { sendEvent } from '../helper/api';
import { isMeetingController } from '../helper/roles';
import { useStartLoading } from './useStartLoading';
import { isEqual } from 'lodash/fp';
import { overrideDynamicSceneAndBlockSettings } from 'zync-common/helper/overrideDynamicValue';

export const useLiveMeeting = ({ series, userId }) => {
  const meetingId = useSelector((state) => state.meetingState.meetingId);

  const meetingConfig = useSelector(
    (state) => state.meetingState.meetingConfig,
    shallowEqual
  );

  const users = useSelector(
    (state) => state.meetingState.state?.users,
    isEqual
  );

  const invisibleUsers = useSelector(
    (state) => state.meetingState.state?.invisibleUsers,
    shallowEqual
  );

  const eventPresenters = useMemo(() => {
    return series?.settings?.eventPresenters ?? [];
  }, [series]);

  const speakers = useMemo(() => {
    return eventPresenters.filter((ep) => ep.type === 'speaker') || [];
  }, [eventPresenters]);

  const { slides: allSlides } = useMemo(
    () => overrideDynamicSceneAndBlockSettings(series, meetingConfig, speakers),
    [meetingConfig, series, speakers]
  );

  const currentSceneIndex = useSelector(
    (state) => state.meetingState.state?.slides?.currentSlideIndex
  );

  const { isLoading: isSwitchingScenes, startLoading } = useStartLoading(
    null,
    currentSceneIndex,
    undefined,
    'Slide Change'
  );

  const user = (users || {})[userId] || (invisibleUsers || {})[userId];

  const meetingController = useMemo(() => {
    return isMeetingController(user);
  }, [user]);

  const liveTab = useSelector((st) => st.clientDetails.liveTab);

  const dispatch = useDispatch();

  const startSlide = useCallback(
    (slideIndex) => {
      if (isSwitchingScenes || slideIndex === currentSceneIndex) return;
      startLoading();
      if (slideIndex >= 0 && slideIndex < allSlides.length) {
        return sendEvent(userId, meetingId, {
          type: 'START_SLIDE',
          userId,
          data: { slideIndex },
        });
      }
    },
    [
      currentSceneIndex,
      isSwitchingScenes,
      startLoading,
      allSlides.length,
      userId,
      meetingId,
    ]
  );

  // Open Scenes Menu by default for moderators if the feature flag is set.
  const showTimeline = meetingController && series?.settings?.timelineOn;
  useEffect(() => {
    if (showTimeline) {
      dispatch({ type: 'SELECT_LIVE_TAB', tab: 'timeline' });
    }
  }, [dispatch, showTimeline]);

  return {
    meetingController,
    liveTab,
    allSlides,
    user,
    startSlide,
    isSwitchingScenes,
  };
};
