import { fetchSceneTemplateApi } from './api';
import { SeriesVisibility } from './visibility';
import { episodeTypes } from 'zync-common/types';

export const episodeBuilder = ({
  title,
  slides,
  scheduledEvent,
  templateKey,
  options: {
    eventPresenters,
    autoRecordOnStart = true,
    allowAdvancedAuthoring = true,
    guestsStartWithAudioOff = true,
    allowJoinWithoutMedia = true,
    isEmailMandatory = true,
    moderatorsCanScreenShareOnly = true,
    timelineOn = true,
    includeEventQuestions = true,
    episodeType = episodeTypes.default,
  },
}) => {
  const fetchedTemplates = new Map();

  const sceneTemplateIds = slides.map((slide) => slide.sceneTemplateKey);

  const init = async () => {
    const promises = sceneTemplateIds.map((sceneTemplateId) =>
      fetchSceneTemplateApi(sceneTemplateId)
    );

    const sceneTemplates = await Promise.all(promises);

    sceneTemplates.forEach((sceneTemplate) => {
      fetchedTemplates.set(sceneTemplate.sceneTemplateKey, {
        ...sceneTemplate,
        slideConfig: { slideBlocks: sceneTemplate.blocks },
      });
    });

    return fetchedTemplates;
  };

  const defaultMapper = (scene) => ({
    ...scene,
    slideName: scene.title,
  });
  const createSlides = () => {
    return slides.map(({ sceneTemplateKey, mapper }) => {
      if (!fetchedTemplates.has(sceneTemplateKey)) {
        throw new Error(
          `sceneTemplateKey: ${sceneTemplateKey} provided was not found in sceneTemplateIds collection. Please update the slides so that sceneTemplateKey matches sceneTemplateIds`
        );
      }
      return mapper
        ? mapper(fetchedTemplates.get(sceneTemplateKey))
        : defaultMapper(fetchedTemplates.get(sceneTemplateKey));
    });
  };

  const build = () => {
    return {
      autoRecordOnStart,
      description: '',
      title,
      slides: createSlides(),
      scheduledEvent,
      templateKey,
      settings: {
        eventPresenters: eventPresenters.map((eventPresenter) => ({
          ...eventPresenter,
          speaker: eventPresenter._id,
        })),
        allowAdvancedAuthoring,
        guestsStartWithAudioOff,
        allowJoinWithoutMedia,
        isEmailMandatory,
        moderatorsCanScreenShareOnly,
        timelineOn,
        includeEventQuestions,
        episodeType,
      },
      visibility: SeriesVisibility.RSVP,
    };
  };

  return {
    init,
    createSlides,
    build,
  };
};

/*
const eventPresenters = [
  {
    emailAddress: 'emailAddress',
    fullName: 'fullName',
    name: 'test1',
    jobTitle: '',
    linkedInUrl: '',
    presenterPictureUrl: '',
    type: presenterTypes.HOST,
  },
  {
    emailAddress: 'emailAddress',
    fullName: 'fullName',
    name: 'test2',
    jobTitle: '',
    linkedInUrl: '',
    presenterPictureUrl: '',
    type: presenterTypes.HOST,
  },
];
*/

/** Example Usage
 *
 *
const { init, build } = episodeBuilder({
  title: 'title',
  slides: [
    {
      sceneTemplateKey: 'scene_welcome_1x1',
      mapper: (slide) => slide,
    },
    {
      sceneTemplateKey: 'scene_speaker_intro',
      mapper: (scene) => createNewPresenterSlide(scene, eventPresenters[0], 1),
    },
    {
      sceneTemplateKey: 'scene_discussion_point',
      mapper: (scene) =>
        createNewQuestionSlide(scene, {
          text: 'What is your favorite  book?',
          id: makeRandomId(6),
          timestamp: Date.now(),
          asker: 'asker',
          moderatorQuestion: true,
          AIGenerated: false,
          index: 0,
        }),
    },
    { sceneTemplateKey: 'scene_q&a', mapper: (scene, object) => scene },
    {
      sceneTemplateKey: 'scene_opinionpoll_host',
      mapper: (scene, object) => scene,
    },
    {
      sceneTemplateKey: 'scene_wordcloud_host',
      mapper: (scene, object) => scene,
    },
    { sceneTemplateKey: 'scene_thankyou', mapper: (scene, object) => scene },
  ],
  scheduledEvent: { startDate: '', endDate: '', timezone: '' },
  options: {
    eventPresenters,
  },
});

 await init() // just to fetch all templates we requested

 build() // creates a new meeting series object, that we can pass to existing creation APIs
 */
