import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteSeriesApi } from '../../helper/api';
import mixpanel from 'mixpanel-browser';
import { getMeetingSeriesProps } from 'zync-common/tracking';
import {
  ModalWindow,
  Modal,
  ModalBody,
  ModalTitle,
  ModalButtonsGroup,
  ModalButton,
} from '../../components/Modal';
import { Button } from '../../components/common/Button';

export const DeleteMeetingModal = ({ isOpen, handleConfirm, handleCancel }) => {
  return (
    isOpen && (
      <ModalWindow showCancel={false} size={Modal.size.md}>
        <ModalTitle>
          <FontAwesomeIcon icon="trash" color="#DB2929" size="lg" /> Delete the
          episode?
        </ModalTitle>
        <ModalBody>
          <p className="text-blue-gray">
            Are you sure you want to delete this episode? <br />
            Note: this action cannot be undone.
          </p>
        </ModalBody>
        <ModalButtonsGroup>
          <ModalButton
            color={ModalButton.color.WHITE}
            handleOnClick={handleCancel}
            buttonText={'Cancel'}
          />
          <ModalButton
            color={ModalButton.color.RED}
            handleOnClick={handleConfirm}
            buttonText={'Delete Episode'}
          />
        </ModalButtonsGroup>
      </ModalWindow>
    )
  );
};

export const DeleteMeeting = ({ meetingSeriesId, series, localDispatch }) => {
  const [isDeleteMeetingModalOpen, setIsDeleteMeetingModalOpen] =
    useState(false);

  const deleteMeetingSeries = async () => {
    if (!meetingSeriesId) return;
    await deleteSeriesApi(meetingSeriesId);
    mixpanel.track('Series - Settings Series Deleted', {
      ...getMeetingSeriesProps(series),
    });
    localDispatch({ type: 'MEETING_SERIES_DELETED' });
  };

  return (
    <div className="w-full relative">
      <Button
        onClick={() => setIsDeleteMeetingModalOpen(true)}
        color={Button.colors.PINK}
      >
        <span className="text-sm font-medium">Delete Event</span>
      </Button>

      <DeleteMeetingModal
        isOpen={isDeleteMeetingModalOpen}
        handleConfirm={deleteMeetingSeries}
        handleCancel={() => setIsDeleteMeetingModalOpen(false)}
      />
    </div>
  );
};
