import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { absolutePosition } from '../../../helper/css';
import { useIntervalIndex } from '../../../hooks/useIntervalIndex';
import { SlideImageContainer } from './ScenePreview';
import { ScenePreview } from './ScenePreview';

const defaultScenePreviewCarouselInterval = 3000;

/** A scene preview carousel that runs through the given scenes in a slideshow, based on ScenePreview component. */
export const ScenePreviewCarousel = ({
  /** The array of scenes. */
  scenes,
  /** Default scene shown when not active. Defaults to the first scene in `scenes`. */
  defaultSceneIndex = 0,
  /** Width of the scene preview. */
  width,
  /** Height of the scene preview. */
  height,
  /**
   * Whether the carousel is active. Moves back to the first slide when not active, otherwise rotates through.
   * If left undefined, uses activeOnHover to determine active state.
   * If activeOnHover is undefined, the carousel is always active.
   */
  active: activeProp,
  /** Whether to play the carousel when the mouse is hovering over it. */
  activeOnHover,
  /** The interval between scenes when active, in milliseconds. */
  intervalMs = defaultScenePreviewCarouselInterval,
  brandKit,
}) => {
  if (activeOnHover === undefined && activeProp === undefined) {
    activeProp = true;
  }

  const [activeHover, setActiveHover] = useState(false);
  const active =
    scenes.length > 1 ? (activeOnHover ? activeHover : activeProp) : false;
  const interval = useIntervalIndex(
    intervalMs ?? defaultScenePreviewCarouselInterval,
    active,
    1
  );
  const index = interval % scenes.length;

  const scenePassed = useCallback(
    (sceneIndex) => sceneIndex === (index - 1 + scenes.length) % scenes.length,
    [scenes, index]
  );
  const sceneIsNext = useCallback(
    (sceneIndex) => sceneIndex === (index + 1) % scenes.length,
    [scenes, index]
  );

  // active x-offsets for visible scenes (or null if not to be shown).
  const sceneOffsets = useMemo(
    () =>
      active &&
      scenes.map((scene, sceneIndex) =>
        sceneIndex === index
          ? 0
          : scenePassed(sceneIndex)
          ? -width
          : sceneIsNext(sceneIndex)
          ? width
          : null
      ),
    [scenes, index, scenePassed, sceneIsNext, width, active]
  );

  // x-offsets for visible scenes when transitioning to inactive (null if not to be shown).
  const inactiveSceneOffsets = useMemo(
    () =>
      !active &&
      scenes.map((scene, sceneIndex) =>
        sceneIndex === index && sceneIndex !== defaultSceneIndex ? width : null
      ),
    [scenes, defaultSceneIndex, active, index, width]
  );

  return (
    <ScenePreviewCarouselContainer
      width={width}
      height={height}
      onMouseEnter={() => activeOnHover && setActiveHover(true)}
      onMouseLeave={() => activeOnHover && setActiveHover(false)}
    >
      {scenes
        .map((scene, sceneIndex) => ({ scene, sceneIndex }))
        .filter(
          ({ scene, sceneIndex }) =>
            (active && sceneOffsets[sceneIndex] !== null) ||
            (!active && inactiveSceneOffsets[sceneIndex] !== null)
        )
        .map(({ scene, sceneIndex }) => (
          <ScenePreview
            key={scene.sceneId}
            scene={scene}
            width={width}
            height={height}
            style={{
              transform: `translateX(${
                (active ? sceneOffsets : inactiveSceneOffsets)[sceneIndex]
              }px)`,
              zIndex: sceneIndex === index ? 2 : 1,
            }}
            brandKit={brandKit}
          />
        ))}
      {scenes[defaultSceneIndex] && (
        <ScenePreview
          key="default-scene"
          scene={scenes[defaultSceneIndex]}
          width={width}
          height={height}
          style={{
            transform: `translateX(${active ? -width : 0}px)`,
            zIndex: 3,
          }}
          brandKit={brandKit}
        />
      )}
      <ScenePreviewProgressContainer isActive={active}>
        {active ? (
          <>
            Scene {index + 1} of {scenes.length}
          </>
        ) : (
          <>
            {scenes.length} scene{scenes.length === 1 ? '' : 's'}
          </>
        )}
      </ScenePreviewProgressContainer>
    </ScenePreviewCarouselContainer>
  );
};

const ScenePreviewProgressContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transition: all 200ms ease;
  background: ${({ isActive }) =>
    isActive ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.2)'};
  color: ${({ isActive }) => (isActive ? 'white' : 'rgba(255, 255, 255, 0.8)')};
  font-size: 12px;
  padding: 4px 6px;
  border-top-left-radius: 8px;
  z-index: 6;
`;

const ScenePreviewCarouselContainer = styled.div`
  position: relative;
  ${({ width, height }) => absolutePosition({ width, height })}
  overflow: hidden;

  > ${() => SlideImageContainer} {
    transition: transform 400ms ease;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
