import React from 'react';

import {
  PreviousPage,
  pageTitles,
  Navbar,
  NavbarPlanInformation,
} from './NavbarComponents';
import { NavbarUserDropdownMenu } from './NavbarUserDropdownMenu';
import { usePortal } from '../../hooks/usePortal';

export const MeetingNavbar = ({
  isAuthenticated,
  workspaceId,
  isTrial,
  plan,
}) => {
  const { workspacePlanValidUntil, currentSubscriptionRenewalTime } =
    usePortal();

  return (
    <Navbar>
      <div className="md:min-w-[1000px] flex justify-between items-center">
        <PreviousPage currentPage={pageTitles.meeting} />
        <ul className="flex gap-5">
          <NavbarPlanInformation
            workspaceId={workspaceId}
            isTrial={isTrial}
            plan={plan}
            trialUntil={workspacePlanValidUntil}
            workspacePlanValidUntil={workspacePlanValidUntil}
            currentSubscriptionRenewalTime={currentSubscriptionRenewalTime}
          />
          {isAuthenticated && (
            <li className="hidden md:block">
              <NavbarUserDropdownMenu pageTitle={pageTitles.meeting} />
            </li>
          )}
        </ul>
      </div>
    </Navbar>
  );
};
