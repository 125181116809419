import React from 'react';
import { Cards } from '../Cards';

import MessagePartials from './MessagePartials';

const MeetingEndingShortlyAdmin = ({
  billingAdminEmail,
  plan,
  isSubscriptionSetup,
  meetingCountdownProperties,
  isSubscriptionActive,
}) => {
  const { timeToEnd, showDynamicCountdown } = meetingCountdownProperties;
  const { name, maxMinutes } = plan;

  return (
    <div className="flex justify-between items-center">
      <div className="flex-grow w-full px-5">
        <MessagePartials.YourMeetingIsEnding
          planName={name}
          timeToEnd={timeToEnd}
          maxMinutes={maxMinutes}
          showDynamicCountdown={showDynamicCountdown}
        />
        {isSubscriptionSetup ? (
          <MessagePartials.UnlockPotential />
        ) : (
          <MessagePartials.ContactYourAdmin
            billingAdminEmail={billingAdminEmail}
          />
        )}
      </div>
      {isSubscriptionSetup && (
        <Cards plan={plan} isSubscriptionActive={isSubscriptionActive} />
      )}
    </div>
  );
};

export default MeetingEndingShortlyAdmin;
