const zyncCustomerEngagementStages = {
  COMPLETED_SESSION: { stage: 'COMPLETED_SESSION', level: 1 },
  ACCESSED_CONTENT_KIT: { stage: 'ACCESSED_CONTENT_KIT', level: 2 },
  PUBLISHED_SOCIAL: { stage: 'PUBLISHED_SOCIAL', level: 3 },
  CREATED_SESSION: { stage: 'CREATED_SESSION', level: 4 },
  CONNECTED_YOUTUBE: { stage: 'CONNECTED_YOUTUBE', level: 5 },
  COMPLETED_3X_SESSIONS: { stage: 'COMPLETED_3X_SESSIONS', level: 6 },
  CONNECTED_LINKEDIN: { stage: 'CONNECTED_LINKEDIN', level: 7 },
};

module.exports = zyncCustomerEngagementStages;
